import React from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';

import * as S from './Textarea.styled';

interface TextareaProps {
  id: string;
  className?: string;
  required?: boolean;
  placeholder?: string;
  label: string;
  register: UseFormRegisterReturn<string>;
  labelHidden?: boolean;
  value?: string;
  maxLength?: number;
  error?: string;
  errorHidden?: boolean;
}

const Textarea = ({
  id,
  value,
  label,
  className,
  placeholder,
  labelHidden = false,
  register,
  required,
  maxLength,
  error,
  errorHidden,
}: TextareaProps) => {
  return (
    <S.Wrapper>
      {label && (
        <S.Label htmlFor={id} hidden={labelHidden}>
          {label}
        </S.Label>
      )}
      <S.TextAreaWrapper className={className} id="texst">
        <S.TextArea
          id={id}
          {...register}
          maxLength={maxLength}
          placeholder={placeholder}
          aria-invalid={error ? 'true' : 'false'}
          aria-required={required ? 'true' : 'false'}
        />
        {maxLength && (
          <S.MaxLengthArea>
            ({value ? value.length : 0}/{maxLength}자)
          </S.MaxLengthArea>
        )}
        {!errorHidden && error && <S.ErrorMessage>{error}</S.ErrorMessage>}
      </S.TextAreaWrapper>
    </S.Wrapper>
  );
};

export default Textarea;
