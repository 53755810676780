import React, { useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Breadcrumb } from 'components/layout/local';
import LocalHeader from 'components/layout/local/LocalHeader.component';

import { useModal, useCheckList, useFilterParams, useUpdateEffect } from 'hooks';

import { PATH } from 'constants/path';
import { localTabList, supportTableColumnList } from 'assets/static/support';
import { ManagementQueriesModel, SupportingListModel } from 'types/support';
import { useCloseSupport, useDeleteProduct, useGetSupportList } from 'service/support';

import SupportTable from '../table/SupportTable.component';
import SupportFilter from '../filter/SupportFilter.component';
import SupportManagement from '../management/SupportManagement.component';

import * as S from './SupportContainer.styled';
import CloseModal from '../modal/CloseModal.component';

export type PMHomeProps = {
  className?: string;
};

function SupportContainer({ className }: PMHomeProps) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { checkList, handleCheck, handleAllCheck, handleResetCheck } = useCheckList();

  const { filters } = useFilterParams<ManagementQueriesModel>(
    'type',
    'page',
    'title',
    'cityId',
    'areaId',
    'regionId',
    'closeDate',
    'periodType',
    'orderBy',
  );

  const { data: supportListData } = useGetSupportList(filters, {
    enabled: !!searchParams.get('type'),
  });

  const CheckProps = () => ({
    isDone: true,
    pageInfo: supportListData?.pageInfo,
    data: supportListData?.supportingList,
    dataNumArr:
      supportListData?.supportingList?.map((data: SupportingListModel) => data.supportingId) ?? [],
  });

  const { mutate: closePost } = useCloseSupport();
  const { mutate: deletePost } = useDeleteProduct();

  const handleClickDelete = useCallback(
    (closeModal: () => void) => () => {
      deletePost(checkList);
      handleResetCheck();
      closeModal();
    },
    [checkList, deletePost, handleResetCheck],
  );

  const handleClickClose = useCallback(
    (closeModal: () => void) => () => {
      closePost(checkList);
      handleResetCheck();
      closeModal();
    },
    [checkList, closePost, handleResetCheck],
  );

  const { openModal } = useModal();

  const handleSupportClose = useCallback(() => {
    openModal(
      <CloseModal
        confirmString="이동"
        title="마감으로 이동하시겠어요?"
        desc="이동 버튼을 누르시면 해당 공고가 마감된 공고로 이동됩니다."
        onClick={handleClickClose}
      />,
    );
  }, [handleClickClose, openModal]);

  const handleSupportDelete = useCallback(() => {
    openModal(
      <CloseModal
        confirmString="삭제"
        title="공고를 삭제하시겠어요?"
        desc="공고를 삭제하면 리스트에서 사라지며, 다시 복구할 수 없습니다."
        onClick={handleClickDelete}
      />,
    );
  }, [handleClickDelete, openModal]);

  const handleRouteAddPage = () => navigate(PATH.supportCreate);

  const [filterStatus, setFilterStatus] = useState<{
    closeDate?: { column: string; order: string };
    view?: { column: string; order: string };
  }>({});
  const [closeDateCnt, setCloseDateCnt] = useState(0);
  const [viewCnt, setViewCnt] = useState(0);

  const handleClickClickableTh = useCallback(
    (name?: string) => () => {
      if (name === 'closeDate') {
        if (closeDateCnt === 0) {
          setCloseDateCnt((prev) => prev + 1);
          setFilterStatus((prev) => {
            return { ...prev, closeDate: { column: 'closeDate', order: 'DESC' } };
          });
        } else if (closeDateCnt === 1) {
          setCloseDateCnt((prev) => prev + 1);
          setFilterStatus((prev) => {
            return { ...prev, closeDate: { column: 'closeDate', order: 'ASC' } };
          });
        } else if (closeDateCnt === 2) {
          setCloseDateCnt(0);
          const newFilterStatus = { ...filterStatus };
          delete newFilterStatus.closeDate;
          setFilterStatus(newFilterStatus);
        }
      } else if (name === 'view') {
        if (viewCnt === 0) {
          setViewCnt((prev) => prev + 1);
          setFilterStatus((prev) => {
            return { ...prev, view: { column: 'view', order: 'DESC' } };
          });
        } else if (viewCnt === 1) {
          setViewCnt((prev) => prev + 1);
          setFilterStatus((prev) => {
            return { ...prev, view: { column: 'view', order: 'ASC' } };
          });
        } else if (viewCnt === 2) {
          setViewCnt(0);
          const newFilterStatus = { ...filterStatus };
          delete newFilterStatus.view;
          setFilterStatus(newFilterStatus);
        }
      }
    },
    [closeDateCnt, filterStatus, viewCnt],
  );

  const [localNavTabLabel, setLocalNavTabLabel] = useState(
    searchParams.get('type') ?? 'processing',
  );

  const handleTab = useCallback(
    (label: string) => () => {
      handleResetCheck();
      setLocalNavTabLabel(label);
    },
    [handleResetCheck],
  );

  useUpdateEffect(() => {
    if (closeDateCnt !== 0 || viewCnt !== 0) {
      const jsonData = JSON.stringify(Object.values(filterStatus));
      setSearchParams({
        ...Object.fromEntries(searchParams),
        orderBy: jsonData,
      });
    } else if (closeDateCnt === 0 && viewCnt === 0) {
      searchParams.delete('orderBy');
      setSearchParams(searchParams);
    }
  }, [filterStatus, closeDateCnt, searchParams, setSearchParams, viewCnt]);

  return (
    <>
      <S.RootStyle className={className}>
        <LocalHeader
          title="지원사업 관리"
          tabs={localTabList}
          label={localNavTabLabel}
          handleTab={handleTab}
          addBtnRender={() => (
            <Breadcrumb.AddBtn
              label="새 공고 추가"
              handleClick={handleRouteAddPage}
              aria-label="새 공고 추가 페이지로 이동"
            />
          )}
        />

        <SupportFilter
          setViewCnt={setViewCnt}
          setCloseDateCnt={setCloseDateCnt}
          localNavTabLabel={localNavTabLabel}
        />

        {checkList.length > 0 && (
          <SupportManagement
            selectedSupports={checkList}
            onClose={handleSupportClose}
            onDelete={handleSupportDelete}
          />
        )}
        <SupportTable
          isDone
          viewCnt={viewCnt}
          checkList={checkList}
          closeDateCnt={closeDateCnt}
          dataArr={CheckProps().data}
          pageInfo={CheckProps().pageInfo}
          columnArr={supportTableColumnList}
          dataNumArr={CheckProps().dataNumArr}
          handleCheck={handleCheck}
          handleAllCheck={handleAllCheck}
          handleClickClickableTh={handleClickClickableTh}
        />
      </S.RootStyle>
    </>
  );
}

export default SupportContainer;
