/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { PATH } from 'constants/path';
import { useAuth } from 'context/AuthProvider';
import useCommonList from 'hooks/useCommonList';

import { Toast } from 'components/common';
import { Header, Footer } from 'components/layout/index';
import Modal from 'components/common/modal/Modal.component';

import * as S from './BaseLayout.styled';
import { isEmpty } from 'lodash-es';

interface BaseLayoutProps {
  className?: string;
}

const BaseLayout = ({ className }: BaseLayoutProps) => {
  useCommonList();
  const location = useLocation();
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollRef.current) return;
    scrollRef.current.scrollIntoView({
      behavior: 'auto',
      block: 'start',
      inline: 'nearest',
    });
  }, [location.pathname]);

  const { user, initializing } = useAuth();

  if (initializing) return null;

  return !isEmpty(user) ? (
    <div ref={scrollRef}>
      <Header />
      <S.Main className={className}>
        <Toast />
        <Outlet />
        <Modal />
      </S.Main>
      <Footer />
    </div>
  ) : (
    <Navigate to={PATH.login} state={{ from: location }} />
  );
};

export default BaseLayout;
