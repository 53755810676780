import React from 'react';

import LocalNavTab from './navigation/LocalNavTab.component';
import Breadcrumb from './breadcrumb/Breadcrumb.component';
import * as S from './LocalHeader.styled';

interface LocalHeaderProps {
  title: string;
  label: string;
  tabs: { label: string; key: string }[];
  handleTab: (label: string) => () => void;
  addBtnRender?: () => JSX.Element;
}

function LocalHeader({ title, addBtnRender, handleTab, tabs, label }: LocalHeaderProps) {
  return (
    <S.RootStyle>
      <Breadcrumb title={title} addBtnRender={addBtnRender} />
      <LocalNavTab handleTab={handleTab} tabs={tabs} label={label} />
    </S.RootStyle>
  );
}

export default LocalHeader;
