import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const SectionContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    width: 100%;
    padding: 40px 0px 50px 0px;
    border-bottom: 1px solid ${theme.colors.gray20};
  `}
`;

export const SectionLabel = styled.label`
  ${({ theme }) => css`
    ${theme.fonts.medium14};
    color: ${theme.colors.gray50};
  `}
`;

export const FormSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 50px;
`;
