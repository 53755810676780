import React from 'react';

import { Icon } from 'components/icon';

import * as S from './NavigationGuardModal.styled';

interface DeleteModalProps {
  title?: string;
  desc: string;
  confirmString: string;
  onCancel: () => void;
  onConfirm: () => void;
}

function NavigationGuardModal({
  title,
  desc,
  confirmString,
  onConfirm,
  onCancel,
}: DeleteModalProps) {
  return (
    <S.RootStyle role="dialog" aria-live="assertive" aria-modal="true">
      <S.Container>
        <S.CloseBtn onClick={onCancel}>
          <Icon name="close" />
        </S.CloseBtn>
        <h1>{title}</h1>
        <S.Description>{desc}</S.Description>
        <S.BtnGroup>
          <S.Button onClick={onCancel}>취소</S.Button>
          <S.Button isDelete onClick={onConfirm}>
            {confirmString}
          </S.Button>
        </S.BtnGroup>
      </S.Container>
    </S.RootStyle>
  );
}

export default NavigationGuardModal;
