import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

import { NavigationGuard } from 'components';
import { Breadcrumb } from 'components/layout/local';
import { VALID_MSG } from 'constants/common';
import { formatPrice, parseBillionUnitStr } from 'utils/formatter';
import { useSupportForm } from 'pages/support/hooks';
import { attachmentKeysState } from 'store/support';
import { AmountDropdownList } from 'assets/static/common';
import { useCreateProduct, useGetSupportDetail, useUpdateProduct } from 'service/support';
import {
  SupportFormModel,
  ManagementFormModel,
  SupportSpecificForm,
  ManagementDetailModel,
} from 'types/support';

import * as S from './SupportForm.styled';
import { FormSection, BasicFormSection, DetailFormSection, ApplyFormSection } from './section';

interface LoginFormProps {
  className?: string;
}

const SupportForm = ({ className }: LoginFormProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activeBlockPrompt, setActiveBlockPropmt] = useState(true);
  const attachmentKeys = useRecoilValue(attachmentKeysState);

  const ButtonLabel = () => {
    const ab = pathname.split('/')[2];
    if (ab === 'create') {
      return '생성하기';
    } else {
      return '수정하기';
    }
  };

  const [searchParams] = useSearchParams();
  const { data } = useGetSupportDetail(searchParams.get('id'), {
    enabled: !!searchParams.get('id'),
  });

  const methods = useForm<SupportFormModel>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const { mutate: supportCreate } = useCreateProduct();
  const { mutate: supportUpdate } = useUpdateProduct(
    searchParams.get('id'),
    attachmentKeys,
    !!pathname.includes('create'),
  );

  const {
    form,
    error,
    inputRef,
    cityName,
    regionState,
    isActiveTime,
    periodStatus,
    amountPrefix,
    setError,
    setCityName,
    handleBlurAge,
    handleBlurArea,
    setRegionState,
    handleSelectAge,
    handleSelectDate,
    handleBlurPeriod,
    handleAttachment,
    handleDeleteImage,
    handleSelectPeriod,
    handleAmountChange,
    handleBlurAttachment,
    handleChangeAreaList,
    handleClickActiveTime,
    handleBlurBusinessType,
    handlePeriodRadioGroup,
    handleChangeBusinessType,
  } = useSupportForm(data?.supportingInfo);

  const onSubmit = useCallback(
    (form: SupportSpecificForm) => (formData: SupportFormModel) => {
      if (
        !form.periodId ||
        !form.ageIds?.length ||
        !form.typeIds?.length ||
        !form.areaIds?.length ||
        !form.attachment?.length
      ) {
        setError((draft) => {
          if (!form.ageIds) {
            draft.ageIds = VALID_MSG.REQUIRED;
            // inputRefs.current.password!.focus();
          }

          if (!form.periodId) {
            draft.periodId = VALID_MSG.REQUIRED;
            // inputRefs.current.email!.focus();
          }

          if (!form.attachment?.length) {
            draft.attachment = VALID_MSG.REQUIRED;
            // inputRefs.current.email!.focus();
          }

          if (!form.typeIds?.length) {
            draft.typeIds = VALID_MSG.REQUIRED;
            // inputRefs.current.email!.focus();
          }

          if (!form.areaIds?.length) {
            draft.areaIds = VALID_MSG.REQUIRED;
            // inputRefs.current.email!.focus();
          }
        });
        return;
      } else {
        setActiveBlockPropmt(false);

        const amountString =
          formData.amountType === 'amount'
            ? `${
                AmountDropdownList[amountPrefix as keyof typeof AmountDropdownList]
              } ${parseBillionUnitStr(formData?.amount as string)}원`
            : formData?.amount;

        const req = {
          ...formData,
          amount: amountString,
          periodType: periodStatus,
          periodId: +form.periodId,
          startDate: form.startDate,
          attachment: form.attachment,
          cityId: +regionState.cityId,
          closeDate: form.endDate ? dayjs(form.endDate).utc().format('YYYY-MM-DD HH:mm') : null,
          regionId: +regionState.regionId,
          includeTime: isActiveTime ? 1 : 0,
          ageIds: form.ageIds.map((item: string) => +item),
          typeIds: form.typeIds.map((item: string) => +item),
          areaIds: form.areaIds.map((item: string) => +item),
          contact: formData.contact.filter((item) => item.name !== '').map((item) => item.name),
        };

        searchParams.get('id')
          ? supportUpdate(req as unknown as ManagementDetailModel)
          : supportCreate(req as unknown as ManagementFormModel);
      }
    },
    [
      amountPrefix,
      isActiveTime,
      periodStatus,
      regionState.cityId,
      regionState.regionId,
      searchParams,
      setError,
      supportCreate,
      supportUpdate,
    ],
  );

  const onError = () => {
    if (form.ageIds.length === 0) {
      setError((draft) => {
        draft.ageIds = VALID_MSG.REQUIRED;
      });
    }

    if (form.periodId === '') {
      setError((draft) => {
        draft.periodId = VALID_MSG.REQUIRED;
      });
    }

    if (form.typeIds.length === 0) {
      setError((draft) => {
        draft.typeIds = VALID_MSG.REQUIRED;
      });
    }

    if (cityName === '') {
      setError((draft) => {
        draft.regionId = VALID_MSG.REQUIRED;
      });
    }

    if (form.areaIds.length === 0) {
      setError((draft) => {
        draft.areaIds = VALID_MSG.REQUIRED;
      });
    }

    if (form.attachment === null || form.attachment.length === 0) {
      setError((draft) => {
        draft.attachment = VALID_MSG.REQUIRED;
      });
    }

    if (form.endDate === undefined && periodStatus === 'date') {
      setError((draft) => {
        draft.endDate = VALID_MSG.REQUIRED;
      });
    }
  };

  const handleCancelBtnClick = () => navigate(-1);

  useEffect(() => {
    if (periodStatus !== 'date') {
      setError((draft) => {
        draft.endDate = '';
      });
    }
  }, [form.periodId, periodStatus, setError]);

  useEffect(() => {
    const { setValue } = methods;
    setValue('to', data?.supportingInfo?.to ?? '');
    setValue('url', data?.supportingInfo?.url ?? '');
    setValue('title', data?.supportingInfo?.title ?? '');
    setValue('method', data?.supportingInfo?.method ?? '');
    setValue('content', data?.supportingInfo?.content ?? '');
    setValue('institution', data?.supportingInfo?.institution ?? '');
    setValue('amountType', data?.supportingInfo?.amountType ?? 'amount');
    setValue(
      'amount',
      data?.supportingInfo?.amountType === 'amount'
        ? formatPrice(data?.supportingInfo?.amount)
        : data?.supportingInfo?.amount ?? '',
    );
    setValue(
      'contact',
      data?.supportingInfo?.contact.map((item: string) => ({
        name: item,
      })) ?? [{ name: '' }],
    );
  }, [data, methods]);

  return (
    <>
      <Breadcrumb title="지원사업 관리" />
      <NavigationGuard when={activeBlockPrompt} />

      <FormProvider {...methods}>
        <S.Form
          autoComplete="off"
          className={className}
          onSubmit={methods.handleSubmit(onSubmit(form), onError)}
        >
          <FormSection formCompo={<BasicFormSection />} label="기본 정보" />

          <FormSection
            label="상세 정보"
            formCompo={
              <DetailFormSection
                form={form}
                error={error}
                inputRef={inputRef}
                cityName={cityName}
                regionState={regionState}
                periodStatus={periodStatus}
                isActiveTime={isActiveTime}
                amountPrefix={amountPrefix}
                setCityName={setCityName}
                handleAmountChange={handleAmountChange}
                handleClickActiveTime={handleClickActiveTime}
                handlePeriodRadioGroup={handlePeriodRadioGroup}
                handleSelectDate={handleSelectDate}
                setRegionState={setRegionState}
                handleAttachment={handleAttachment}
                handleDeleteImage={handleDeleteImage}
                handleBlurAttachment={handleBlurAttachment}
              />
            }
          />

          <FormSection
            label="지원 자격"
            formCompo={
              <ApplyFormSection
                form={form}
                error={error}
                handleChangeAreaList={handleChangeAreaList}
                handleSelectAge={handleSelectAge}
                handleSelectPeriod={handleSelectPeriod}
                handleChangeBusinessType={handleChangeBusinessType}
                handleBlurArea={handleBlurArea}
                handleBlurBusinessType={handleBlurBusinessType}
                handleBlurAge={handleBlurAge}
                handleBlurPeriod={handleBlurPeriod}
              />
            }
          />

          <S.Bottom>
            <S.ConfirmBtn size="md" type="submit" variant="primary" label={ButtonLabel()} />
            <S.CancelBtn
              size="md"
              label="취소"
              type="button"
              variant="secondary"
              onClick={handleCancelBtnClick}
            />
          </S.Bottom>
        </S.Form>
      </FormProvider>
    </>
  );
};

export default SupportForm;
