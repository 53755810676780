import React from 'react';
import { useRecoilValue } from 'recoil';

import { isShowModalState, modalComponentState } from 'store';
import Dimmer from '../dimmer/Dimmer.component';
import Portal from '../portal/Portal.component';

function Modal() {
  const isModalMounted = useRecoilValue(isShowModalState);
  const ModalComponent = useRecoilValue(modalComponentState);

  return (
    <Portal elementId="modal-root" mounted={!!isModalMounted}>
      <Dimmer>{ModalComponent}</Dimmer>
    </Portal>
  );
}

export default Modal;
