import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import { CheckTable } from 'components/common';

export const Root = styled(CheckTable)`
  tr {
    grid-template-columns: 2.7734% 34.9768% 12.4807% 8.0123% 10.9399% 6.1633% 17.4114% 1fr;
  }
`;

export const Body = styled(CheckTable.Body)`
  ${({ theme }) => css`
    & > tr {
      border-bottom: 1px solid ${theme.colors.gray30};

      &:nth-of-type(20) {
        border-bottom: 0;
      }
    }
  `}
`;

export const DetailLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const NoDataRow = styled.tr`
  && {
    &:hover td {
      background-color: inherit;
    }
  }
`;

export const Cell = styled(CheckTable.Cell)`
  @media (max-width: 1500px) {
    &[data-hidden='true'] {
      border: 2px solid red;
      display: none;
    }
  }
`;
