import React from 'react';

import { addressInfo, copyright, outbounds } from 'assets/static';

import * as S from './Footer.styled';
import { Icon } from 'components/icon';

const Footer = () => {
  return (
    <S.Footer>
      <S.Container>
        <S.Logo>
          <Icon name="mainLocoIcon" />
        </S.Logo>
        <S.Info>
          <S.Address>
            <S.DescList>
              {addressInfo.map(({ title, desc }) => (
                <div key={title}>
                  <dt>{title}</dt>
                  <dd>{desc}</dd>
                </div>
              ))}
            </S.DescList>
          </S.Address>
          <span>{copyright}</span>
        </S.Info>
        <S.Outbounds>
          {outbounds.map(({ label, link }) => (
            <li key={label}>
              <a href={link} target="_blank" rel="noreferrer">
                {label}
              </a>
            </li>
          ))}
        </S.Outbounds>
      </S.Container>
    </S.Footer>
  );
};

export default Footer;
