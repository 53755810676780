import React from 'react';

import { Icon } from 'components';
import * as S from './LoginContainer.styled';

const LoginContainer = () => {
  return (
    <S.Section>
      <S.SectionTitle>로그인</S.SectionTitle>
      <S.LogoBox>
        <Icon name="mainLocoIcon" />
      </S.LogoBox>
      <S.CustomLoginForm />
    </S.Section>
  );
};

export default LoginContainer;
