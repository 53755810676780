import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ScrapManagement = styled.div`
  ${({ theme }) => css`
    position: static;
    display: flex;
    align-items: center;
    column-gap: 16px;
    width: 100%;
    height: 52px;
    padding: 0 20px;
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.gray30};
    z-index: ${theme.zIndex.modal};
    margin-bottom: -40px;
  `}
`;

export const Count = styled.span`
  ${({ theme }) => css`
    ${theme.fonts.regular15};
    color: ${theme.colors.white};

    & > strong {
      ${theme.fonts.medium15};
      color: ${theme.colors.blue10};
    }

    @media ${theme.breakPoint.device.tablet} {
      color: ${theme.colors.gray70};

      & > strong {
        color: ${theme.colors.blue40};
      }
    }
  `}
`;

export const Utils = styled.div`
  ${({ theme }) => css`
    display: flex;
    column-gap: 8px;

    & > button {
      ${theme.button.variant.ghost};
      ${theme.fonts.medium15};
      color: ${theme.colors.white};
    }

    @media ${theme.breakPoint.device.tablet} {
      & > button {
        color: ${theme.colors.gray60};
      }
    }
  `}
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    width: 1px;
    height: 12px;
    border: 1px solid ${theme.colors.gray30};
  `}
`;

interface BtnProps {
  isDelete?: boolean;
}

export const Button = styled.button<BtnProps>`
  ${({ theme, isDelete }) => css`
    ${theme.fonts.regular15};
    ${theme.button.size.sm};
    ${theme.button.variant.outline};
    border-radius: 0;
    font-weight: ${isDelete ? '500' : '400'};

    && {
      color: ${isDelete ? theme.colors.red20 : theme.colors.gray60};
      border: 1px solid ${isDelete ? theme.colors.red20 : theme.colors.gray30};
    }
  `}
`;
