import React, { useRef, useState, useCallback, ForwardedRef, useEffect } from 'react';

import { Dropdown } from 'components';
import useCommonList from 'hooks/useCommonList';
import { useKeyTrap, useOnClickOutside } from 'hooks';
import * as S from './RegionSelector.styled';

interface RegionSelector {
  id: string;
  name: string;
  error?: boolean;
  cityName?: string;
  className?: string;
  required?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  innerHidden?: boolean;
  selectedHidden?: boolean;
  selectedOption?: [number, number][];
  openerRef: ForwardedRef<HTMLButtonElement>;
  regionState?: { regionId: number; cityId: number };
  setCityName: (c: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLButtonElement>) => void;
  onDelete?: (regionId: number, cityId: number) => () => void;
  onChange?: (regionId: number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  setRegionState: ({ regionId, cityId }: { regionId: number; cityId: number }) => void;
}

const RegionSelector = ({
  id,
  name,
  error,
  cityName,
  readOnly,
  required,
  className,
  openerRef,
  placeholder,
  regionState,
  selectedOption,
  innerHidden = false,
  onBlur,
  setCityName,
  setRegionState,
}: RegionSelector) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const cityListRef = useRef<HTMLUListElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const { cityState: region } = useCommonList();

  useKeyTrap(dropdownRef, handleClose);
  useOnClickOutside(containerRef, handleClose);

  function handleToggleDropdown() {
    isOpen ? handleClose() : handleOpen();
  }

  function handleOpen() {
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }

  const handleChangeSelectedRegionId = useCallback(
    (regionId: number) => () => {
      if (regionId === 1) {
        setCityName('전국');
        handleClose();
      }
      setRegionState({ regionId, cityId: 1 });
    },
    [setCityName, setRegionState],
  );

  const handleChangeSelectedCityId = useCallback(
    (city: { regionId: number; cityId: number }) => () => {
      const { regionId, cityId } = city;
      setCityName(region.getTotalName(regionId, cityId));
      setRegionState({ regionId, cityId });
      handleClose();
    },
    [region, setCityName, setRegionState],
  );

  useEffect(() => {
    cityListRef.current?.scrollTo(0, 0);
  }, []);

  const uuid = `RegionSelector-${id}`;
  const tipId = 'tip-option';
  return (
    <S.RegionSelectorWrapper>
      <S.RegionSelector ref={containerRef} className={className}>
        <S.OpenerBtn
          ref={openerRef}
          type="button"
          name={name}
          disabled={readOnly}
          aria-controls={uuid}
          aria-expanded={isOpen}
          aria-required={required ? 'true' : 'false'}
          aria-invalid={error}
          aria-describedby={tipId}
          onClick={handleToggleDropdown}
          onBlur={onBlur}
        >
          {cityName !== '' ? cityName : <S.Placeholder>{placeholder}</S.Placeholder>}
          <S.ArrowIcon name="arrowBold" />
        </S.OpenerBtn>
        <S.DropBox ref={dropdownRef} id={uuid} isOpen={isOpen} innerHidden={innerHidden}>
          <S.OptionContainer>
            <S.RegionList>
              {region.regionList.map(({ regionId, name }) => {
                return (
                  <Dropdown.Option
                    key={regionId}
                    label={name}
                    value={String(regionId)}
                    selected={
                      regionState?.regionId === regionId ||
                      !!selectedOption?.find(([rId]) => rId === regionId)
                    }
                    onClick={handleChangeSelectedRegionId(regionId)}
                  />
                );
              })}
            </S.RegionList>
            <S.CityList ref={cityListRef}>
              {regionState?.regionId === 1 ? (
                <S.Default>도/시를 먼저 선택해 주세요</S.Default>
              ) : (
                region.cityList
                  .filter(({ regionId }) => regionState?.regionId === regionId)
                  .map(({ regionId, cityId, name }) => {
                    return (
                      <Dropdown.Option
                        key={cityId}
                        label={name}
                        value={String(cityId)}
                        selected={
                          !region.cityList.findIndex(
                            () =>
                              regionId === regionState?.regionId && cityId === regionState?.cityId,
                          )
                        }
                        onClick={handleChangeSelectedCityId({ regionId, cityId })}
                      />
                    );
                  })
              )}
            </S.CityList>
          </S.OptionContainer>
        </S.DropBox>
      </S.RegionSelector>
    </S.RegionSelectorWrapper>
  );
};

export default RegionSelector;
