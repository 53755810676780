import React, { useMemo } from 'react';
import useCommonList from 'hooks/useCommonList';

import { ManagementDetailModel } from 'types/support';
import * as S from './Apply.styled';

export type ApplyProps = {
  data: ManagementDetailModel;
  className?: string;
};

function Apply({ data, className }: ApplyProps) {
  const { ageState, areaState, periodState, BusinessTypeState } = useCommonList();

  const areaList = useMemo(
    () =>
      Object.entries(areaState)
        .filter((item) => {
          const [id] = item;
          return data?.areaIds?.includes(+id);
        })
        .map((item) => item[1])
        .join(', '),
    [areaState, data?.areaIds],
  );

  const typeList = useMemo(
    () =>
      Object.entries(BusinessTypeState)
        .filter((item) => {
          const [id] = item;
          return data?.typeIds?.includes(+id);
        })
        .map((item) => item[1])
        .join(', '),
    [BusinessTypeState, data?.typeIds],
  );

  const ageList = useMemo(
    () =>
      Object.entries(ageState)
        .filter((item) => {
          const [id] = item;
          return data?.ageIds?.includes(+id);
        })
        .map((item) => item[1])
        .join(', '),
    [ageState, data?.ageIds],
  );

  return (
    <S.RootStyle className={className}>
      <S.CustomDescList label="지원분야" val={areaList} />
      <S.CustomDescList label="지원대상" val={typeList} />
      <S.CustomDescList label="창업기간" val={periodState[data?.periodId]} isFull />
      <S.CustomDescList label="연령" val={ageList} />
    </S.RootStyle>
  );
}

export default Apply;
