import React, { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { MemberItemModel } from 'types/member';
import useFilterParams from 'hooks/useFIlterParams';
import useUpdateEffect from 'hooks/useUpdateEffect';
import { Breadcrumb } from 'components/layout/local';
import type { MemberQueriesModel } from 'types/member';
import { userTableColumnList } from 'assets/static/user';
import { useGetMemberExcel, useGetMemberList } from 'service/member';
import { download } from 'utils/helper/common';

import * as S from './UserContainer.styled';
import MemberTable from '../table/MemberTable.component';
import MemberFilter from '../filter/Filter.component';

interface MemberContainerProps {
  className?: string;
}

function MemberContainer({ className }: MemberContainerProps) {
  const { filters } = useFilterParams<MemberQueriesModel>(
    'receiveSms',
    'receiveEmail',
    'orderBy',
    'page',
  );

  const { data: memberListData } = useGetMemberList(filters);

  const CheckProps = () => ({
    data: memberListData?.userList,
    dataNumArr: memberListData?.userList?.map((data: MemberItemModel) => data.name) ?? [],
    pageInfo: memberListData?.pageInfo,
    isDone: true,
  });

  const { data, refetch, isSuccess } = useGetMemberExcel({
    enabled: false,
  });

  const handleDownloadExcel = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const [scrapCnt, setScrapCnt] = useState(0);
  const [viewCnt, setViewCnt] = useState(0);
  const [filterStatus, setFilterStatus] = useState<{
    scrapeCount?: { column: string; order: string };
    viewCount?: { column: string; order: string };
  }>({});

  const handleClickClickableTh = useCallback(
    (name?: string) => () => {
      if (name === 'scrapeCount') {
        if (scrapCnt === 0) {
          setScrapCnt((prev) => prev + 1);
          setFilterStatus((prev) => {
            return { ...prev, scrapeCount: { column: 'scrapeCount', order: 'DESC' } };
          });
        } else if (scrapCnt === 1) {
          setScrapCnt((prev) => prev + 1);
          setFilterStatus((prev) => {
            return { ...prev, scrapeCount: { column: 'scrapeCount', order: 'ASC' } };
          });
        } else if (scrapCnt === 2) {
          setScrapCnt(0);
          const newFilterStatus = { ...filterStatus };
          delete newFilterStatus.scrapeCount;
          setFilterStatus(newFilterStatus);
        }
      } else if (name === 'viewCount') {
        if (viewCnt === 0) {
          setViewCnt((prev) => prev + 1);
          setFilterStatus((prev) => {
            return { ...prev, viewCount: { column: 'viewCount', order: 'DESC' } };
          });
        } else if (viewCnt === 1) {
          setViewCnt((prev) => prev + 1);
          setFilterStatus((prev) => {
            return { ...prev, viewCount: { column: 'viewCount', order: 'ASC' } };
          });
        } else if (viewCnt === 2) {
          setViewCnt(0);
          const newFilterStatus = { ...filterStatus };
          delete newFilterStatus.viewCount;
          setFilterStatus(newFilterStatus);
        }
      }
    },
    [filterStatus, scrapCnt, viewCnt],
  );

  const [searchParams, setSearchParams] = useSearchParams();

  useUpdateEffect(() => {
    if (data || isSuccess) {
      const blob = new Blob([data]);
      const downloadUrl = URL.createObjectURL(blob);
      download(downloadUrl, '회원관리.xlsx');
    }
  }, [isSuccess]);

  useUpdateEffect(() => {
    if (scrapCnt !== 0 || viewCnt !== 0) {
      const jsonData = JSON.stringify(Object.values(filterStatus));
      setSearchParams({
        ...Object.fromEntries(searchParams),
        orderBy: jsonData,
      });
    } else if (scrapCnt === 0 && viewCnt === 0) {
      searchParams.delete('orderBy');
      setSearchParams(searchParams);
    }
  }, [filterStatus, scrapCnt, searchParams, setSearchParams, viewCnt]);

  return (
    <S.RootStyle className={className}>
      <Breadcrumb title="지원사업 관리" />

      <MemberFilter setScrapCnt={setScrapCnt} setViewCnt={setViewCnt} />
      <MemberTable
        isDone
        viewCnt={viewCnt}
        scrapCnt={scrapCnt}
        pageInfo={CheckProps().pageInfo}
        columnArr={userTableColumnList}
        dataArr={CheckProps().data}
        handleClickClickableTh={handleClickClickableTh}
        handleDownloadExcel={handleDownloadExcel}
      />
    </S.RootStyle>
  );
}

export default MemberContainer;
