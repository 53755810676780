import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Icon } from 'components/icon';
import DialogBtn from 'components/common/button/dialogBtn/DialogBtn';
import { MonthYear } from 'types';
import MonthYearDialog from './MonthYearDialog';

interface MonthYearBtnProps {
  className?: string;
  monthYear: MonthYear;
  handleChangeMonth: (month: number) => () => void;
  handleChangeYear: (year: number) => () => void;
}

const MonthYearBtn = ({
  className,
  monthYear,
  handleChangeMonth,
  handleChangeYear,
}: MonthYearBtnProps) => {
  return (
    <Root className={className}>
      <CustomDialogBtn
        popup={(isOpen, dialogRef) => (
          <MonthYearDialog
            ref={dialogRef}
            isOpen={isOpen}
            monthYear={monthYear}
            handleChangeMonth={handleChangeMonth}
            handleChangeYear={handleChangeYear}
          />
        )}
      >
        <time>
          <span>{monthYear.monthName}</span>
          <span>{monthYear.year}</span>
        </time>
        <Icon name="triangleIcon" />
      </CustomDialogBtn>
    </Root>
  );
};

export default MonthYearBtn;

const Root = styled.div`
  position: relative;
`;

const CustomDialogBtn = styled(DialogBtn)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    & > time {
      ${theme.fonts.semibold15};
      display: flex;
      justify-content: space-between;
      column-gap: 4px;
      margin-right: 8px;
      color: ${theme.colors.black};
    }

    &[aria-pressed='true'] > svg {
      transform: rotate(180deg);
    }
  `}
`;
