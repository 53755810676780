import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { InputContext } from 'components/common';

export const RootStyle = styled.dl`
  display: flex;
  width: 100%;
  height: 48px;

  & > div {
    height: 48px;
  }
`;

export const LabelBox = styled.dt`
  ${({ theme }) => css`
    ${theme.fonts.medium15};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 1;
    min-width: 210px;
    height: 100%;
    background-color: ${theme.colors.gray10};
  `}
`;

export const CustomRegionSelector = styled(InputContext.RegionSelector)`
  width: 100%;
  height: 48px;

  & > div {
    top: 52px;
    width: 570px;
  }

  & > button {
    height: 48px;
    border: 0;
    border-radius: 0;
  }
`;
