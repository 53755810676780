import { css } from '@emotion/react';
import styled from '@emotion/styled';
import DefaultButton from 'components/common/button/defaultBtn/DefaultBtn.component';

export const RootStyle = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 13px;
  width: 100%;
`;

export const FilterLabel = styled.label`
  ${({ theme }) => css`
    ${theme.fonts.medium15};
    min-width: 210px;
    height: 100%;
    padding: 12px 20px;
    background-color: ${theme.colors.gray10};
    color: ${theme.colors.gray50};
  `}
`;

export const DetailFilterContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 24px;
    flex-grow: 1;
    height: 48px;
    border: 1px solid ${theme.colors.gray30};
  `}
`;

export const BtnGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 12px;
  width: 100%;
`;

export const ConfirmBtn = styled(DefaultButton)`
  border-radius: 0;
`;

export const ResetBtn = styled(ConfirmBtn)`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.gray30};
    color: ${theme.colors.gray60};
    background-color: ${theme.colors.white};
  `}
`;
