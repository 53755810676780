import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';

import { Icon } from 'components/icon';

export const RegionSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 52px;
`;

export const RegionSelector = styled.div`
  position: relative;
  width: 100%;
`;

export const OpenerBtn = styled.button`
  ${({ theme }) => css`
    ${theme.dropdown.thin};
  `}
`;

export const Placeholder = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.gray50};
  `}
`;

export const ArrowIcon = styled(Icon)`
  ${({ theme }) => css`
    fill: ${theme.colors.gray50};
  `}
`;

interface DropBoxProps {
  isOpen: boolean;
  innerHidden?: boolean;
}

export const DropBox = styled.div<DropBoxProps>`
  ${({ theme, isOpen, innerHidden }) => css`
    position: absolute;
    top: 0;
    display: ${isOpen ? 'grid' : 'none'};
    grid-template-rows: ${innerHidden ? '1fr' : '272px 1fr'};
    width: 100%;
    border-radius: 10px;
    box-shadow: ${theme.boxShadows.dropdown};
    background-color: ${theme.colors.white};
    z-index: ${theme.zIndex.dialog};
    overflow: hidden;
  `}
`;

export const OptionContainer = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 38.0952% 1fr;
    width: 100%;
    height: 272px;
    background-color: ${theme.colors.white};
  `}
`;

const list = css`
  width: 100%;
  height: 100%;
  padding: 10px 0;
  overflow: overlay;
`;

export const RegionList = styled.ul`
  ${list};
`;

export const nationWide = (theme: Theme) => css`
  &&&& label {
    &::before {
      content: none;
    }
  }

  &&&& input[type='checkbox']:checked + label {
    color: ${theme.colors.blue40};
  }
`;

export const CityList = styled.ul`
  ${list};
  position: relative;
`;

export const Default = styled.li`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: ${theme.colors.gray50};
    background-color: ${theme.colors.gray10};
  `}
`;
