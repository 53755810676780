import ax from 'api/axios';
import { isEmpty as _isEmpty } from 'lodash-es';

import { ManagementQueriesModel, ManagementFormModel } from 'types/support';
import {
  getPresignedKey,
  parseFileExt,
  parseFileExtByAbs,
  parseFileName,
} from 'utils/helper/common';
import { addPresignedUrl } from './common';

export const loadSupportList = async (queries: ManagementQueriesModel) => {
  const params = _isEmpty(queries)
    ? {
        type: 'processing',
        periodType: 'all',
      }
    : {
        ...(queries?.page && { page: queries.page }),
        ...(queries?.type && { type: queries.type }),
        ...(queries?.title && { title: queries.title }),
        ...(queries?.areaId && { areaId: queries.areaId }),
        ...(queries?.cityId && { cityId: queries.cityId }),
        ...(queries?.orderBy && { orderBy: queries.orderBy }),
        ...(queries?.regionId && { regionId: queries.regionId }),
        ...(queries?.closeDate && { closeDate: queries.closeDate }),
        ...((queries?.periodType && { periodType: queries.periodType }) || {
          periodType: 'all',
        }),
      };

  const res = await ax.get('/admin/supporting', { params });
  return res.data;
};

export const loadSupportDetail = async (id?: string | null) => {
  const res = await ax.get(`/admin/supporting/${id}`);
  return res.data;
};

export const updateSupport = (req: ManagementFormModel, id?: string | null) => {
  return ax.put(`/admin/supporting/${id}`, req);
};

export const addSupport = (req: object) => {
  return ax.post('/admin/supporting', req);
};

export const deleteSupport = (id: string[]) => {
  return ax.patch('/admin/supporting', { supportingIds: id });
};

export const closeSupport = (id: string[]) => {
  return ax.patch('/admin/supporting/close', { supportingIds: id });
};

export const addSupportWithS3 = async (req: ManagementFormModel) => {
  const fileS3Keys: string[] = [];
  const fileList = req.attachment as FileList | null;
  const attachment: { name: string; file: string }[] = [];

  if (fileList !== null) {
    Array.from(fileList).forEach((item: File) => {
      fileS3Keys.push(getPresignedKey(parseFileExt(item.type) ?? parseFileExtByAbs(item.name)));
    });

    await Promise.all(
      fileS3Keys?.map((s3Key: string, idx: number) =>
        addPresignedUrl(s3Key, fileList[idx]!, 'attachment'),
      ),
    );

    Array.from(fileList)?.forEach((item: File, idx: number) => {
      attachment.push({
        name: `${parseFileName(item.name)}.${parseFileExtByAbs(item.name)}`,
        file: `${fileS3Keys[idx]}`,
      });
    });

    const reqData = {
      ...req,
      attachment,
    };

    return await addSupport(reqData);
  }
};

export const updateSupportWithS3 = async (
  req: ManagementFormModel,
  id?: string | null,
  attachmentKeys?: string[],
  isOverwrite?: boolean,
) => {
  const fileS3Keys: string[] = [];
  const fileList = req.attachment;
  const attachment: { name: string; file: string }[] = [];

  if (fileList !== null) {
    Array.from(fileList as FileList).forEach((item: File) => {
      if ('file' in item) {
        fileS3Keys.push('');
      } else {
        fileS3Keys.push(getPresignedKey(parseFileExt(item.type) ?? parseFileExtByAbs(item.name)));
      }
    });

    await Promise.all(
      fileS3Keys?.map((s3Key: string, idx: number) => {
        if (s3Key !== '') {
          return addPresignedUrl(s3Key, fileList[idx] as File, 'attachment');
        }
      }),
    );

    Array.from(fileList as FileList)?.forEach(
      (item: File | { name: string; file: string }, idx: number) => {
        if ('file' in item && attachmentKeys) {
          const newItem = { name: item.name, file: attachmentKeys[idx] };
          attachment.push(newItem);
        } else {
          attachment.push({
            name: `${parseFileName(item.name)}.${parseFileExtByAbs(item.name)}`,
            file: `${fileS3Keys[idx]}`,
          });
        }
      },
    );

    const reqData = {
      ...req,
      attachment,
    };

    return isOverwrite ? await addSupport(reqData) : await updateSupport(reqData, id);
  }
};
