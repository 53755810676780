/* eslint-disable no-useless-escape */
import { SyntheticEvent } from 'react';
import { FieldValues, UseFormClearErrors, UseFormSetValue } from 'react-hook-form';

import {
  clearFormControlErrors,
  checkEmailValidation,
  checkPasswordValidation,
  priceFormControl,
} from 'utils/helper';
import { LoginForm } from 'types/login';
import { TOAST_MSG } from 'constants/toast';
import { VALID_MSG } from 'constants/common';

type LoginOnChangeActionFnType = UseFormClearErrors<LoginForm>;

export const BASE_FORM_VALIDATION = (
  actionFn: LoginOnChangeActionFnType,
  errMsg?: string,
  maxLength?: number,
) => ({
  maxLength: maxLength ?? Infinity,
  required: errMsg ?? VALID_MSG.REQUIRED,
  setValueAs: (value: string) => value.trim(),
  onChange: (e: SyntheticEvent) => clearFormControlErrors(e, actionFn),
});

export const LOGIN_FORM_VALIDATION = {
  email: (actionFn: LoginOnChangeActionFnType) => {
    return {
      ...BASE_FORM_VALIDATION(actionFn, TOAST_MSG.LOGIN_REQUIRED_VALUE),
      validate: (value: string) => checkEmailValidation(value) || TOAST_MSG.LOGIN_FAIL,
    };
  },
  password: (actionFn: LoginOnChangeActionFnType) => {
    return {
      ...BASE_FORM_VALIDATION(actionFn, TOAST_MSG.LOGIN_REQUIRED_VALUE),
      validate: (value: string) => checkPasswordValidation(value) || TOAST_MSG.LOGIN_FAIL,
    };
  },
};

export const PRICE_FORM_VALIDATION = (
  setValue: UseFormSetValue<FieldValues>,
  actionFn: LoginOnChangeActionFnType,
) => ({
  required: VALID_MSG.REQUIRED,
  setValueAs: (value: string) => value.trim(),
  onChange: (e: SyntheticEvent) => priceFormControl(e, setValue, actionFn),
});

export const URL_FORM_VALIDATION = (actionFn: LoginOnChangeActionFnType) => ({
  ...BASE_FORM_VALIDATION(actionFn),
  pattern: {
    value:
      /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim,
    message: VALID_MSG.URL,
  },
});
