import { useState, useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { getMonthYearDetails, getNewMonthYear } from 'utils/helper/monthYear';

const useDatePicker = (initDate: Dayjs) => {
  const date = initDate ? dayjs(initDate) : dayjs();
  const initMonthYear = getMonthYearDetails(date);

  const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>(undefined);
  const [monthYear, setMonthYear] = useState(initMonthYear);

  const changePrevMonthYear = useCallback(() => {
    setMonthYear((prevData) => getNewMonthYear(prevData, -1));
  }, []);

  const changeNextMonthYear = useCallback(() => {
    const newMonthYear = getNewMonthYear(monthYear, 1);
    const currentYear = dayjs().format('YYYY');
    const isAfterTwoYear = newMonthYear.value.diff(dayjs(currentYear), 'year') >= 2;

    if (isAfterTwoYear) return;

    setMonthYear(newMonthYear);
  }, [monthYear]);

  const changeCurrentMonthYear = useCallback(() => {
    const currentMonthYear = getMonthYearDetails(dayjs());
    setMonthYear(currentMonthYear);
  }, []);

  const changeMonthYear = useCallback((date?: Dayjs) => {
    const monthYear = getMonthYearDetails(dayjs(date));
    setMonthYear(monthYear);
  }, []);

  const handleSelectDate = useCallback((date?: Dayjs) => {
    setSelectedDate(date);
  }, []);

  const handleChangeMonth = useCallback(
    (month: number) => () => {
      const date = dayjs(monthYear.value).set('month', month);
      changeMonthYear(date);
    },
    [changeMonthYear, monthYear.value],
  );

  const handleChangeYear = useCallback(
    (year: number) => () => {
      const date = dayjs(monthYear.value).set('year', year);
      changeMonthYear(date);
    },
    [changeMonthYear, monthYear.value],
  );

  return {
    selectedDate,
    monthYear,
    changePrevMonthYear,
    changeNextMonthYear,
    changeCurrentMonthYear,
    changeMonthYear,
    handleSelectDate,
    handleChangeMonth,
    handleChangeYear,
  };
};

export default useDatePicker;
