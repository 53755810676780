/* eslint-disable no-useless-escape */
export const checkEmailValidation = (email: string) => {
  if (!email) {
    return false;
  }

  const regExp = RegExp(/^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/);
  return regExp.test(email);
};

export const checkPasswordValidation = (password: string) => {
  //  8~16자의 영문 대문자, 숫자, 특수문자 중 최소 2종류
  const checkSpeacial = /(?=.*[!@#$%^&*()-_=+{};:,<.>])/; //  특수 문자 확인하는 정규식
  const checkNumber = /(?=.*[0-9])/; //  숫자 체크
  const checkBigLetter = /(?=.*[A-Z])/; //  대문자 체크
  const checkSmallLetter = /(?=.*[a-z])/;
  const checkLength = /^.{8,16}$/; //  길이 체크

  const checkList = [
    checkSpeacial.test(password),
    checkNumber.test(password),
    checkBigLetter.test(password),
    checkSmallLetter.test(password),
  ];
  // 4개 중 2개가 true 이면 true
  const boolenCheckThreeOfTwo = checkList.filter(Boolean).length >= 2;

  return checkLength.test(password) && boolenCheckThreeOfTwo;
};
