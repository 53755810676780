/* eslint-disable @typescript-eslint/prefer-function-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

import { Auth } from './auth';
import { renewAccessToken } from 'api/common';

interface Fn {
  (accessToken: string): void;
}

export class TokenService {
  #isAlreadyFetchingAccessToken = false;
  #subscribers: Fn[] = [];
  #auth;
  constructor(auth: Auth) {
    this.#auth = auth;
  }

  async resetTokenAndReattemptRequest(error: any) {
    try {
      const { response: errorResponse } = error;
      const refreshToken = this.#auth.refreshToken;
      if (!refreshToken) {
        this.expireSession();
        return Promise.reject(error);
      }
      const retryOriginalRequest = new Promise((resolve) => {
        this.addSubscriber((accessToken: string) => {
          errorResponse.config.headers.Authorization = `Bearer ${accessToken}`;
          resolve(axios(errorResponse.config));
        });
      });
      if (!this.#isAlreadyFetchingAccessToken) {
        try {
          this.#isAlreadyFetchingAccessToken = true;
          const tokens = await renewAccessToken(refreshToken);
          if (!tokens.accessToken) {
            return Promise.reject(error);
          }
          this.#auth.changeAccessToken(tokens.accessToken);
          this.#auth.changeRefreshToken(tokens.refreshToken);
          this.#isAlreadyFetchingAccessToken = false;
          this.onAccessTokenFetched(tokens.accessToken);
        } catch (err: any) {
          const { response: errorResponse } = err;
          const authErrorCode = ['ALREADY_LOGIN', 'NEED_LOGIN', 'ACCESS_TOKEN_EXPIRED'];
          if (authErrorCode.includes(errorResponse?.data.code)) {
            this.expireSession();
            return Promise.reject(err);
          }
        }
      }
      return retryOriginalRequest;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  getAccessToken() {
    return this.#auth.accessToken;
  }

  onAccessTokenFetched(accessToken: string) {
    this.#subscribers.forEach((callback) => callback(accessToken));
    this.#subscribers = [];
  }

  addSubscriber(callback: Fn) {
    this.#subscribers.push(callback);
  }

  expireSession() {
    this.#auth.refreshToken && alert('Your session has expired.');
    this.#auth.signOut();
    window.location.reload();
  }
}
