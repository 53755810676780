import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const RadioWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    input[type='radio'] {
      position: absolute;
      width: 1px;
      height: 1px;
      border: 0;
      padding: 0;
      margin: -1px;
      clip: rect(0, 0, 0, 0);
      overflow: hidden;
    }

    input[type='radio'] + label {
      ${theme.fonts.regular15};
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;

      &::before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 8px;
        border-radius: 50%;
        border: 1px solid ${theme.colors.gray40};
        text-align: center;
      }

      &::after {
        content: '';
        position: absolute;
        left: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: ${theme.colors.white};
      }
    }

    input[type='radio']:checked + label {
      &::before {
        border-color: ${theme.colors.blue20};
      }

      &::after {
        background-color: ${theme.colors.blue20};
      }
    }
  `}
`;
