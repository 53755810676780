/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useToast } from 'hooks';
import { PATH } from 'constants/path';
import { LoginForm } from 'types/login';
import { useAuth } from 'context/AuthProvider';
import { loginApi, logoutApi } from 'api/login';
import { numericOnly } from 'utils/formatter';
import { AxiosError } from 'axios';

export const useLogin = (isKeepLogin: boolean) => {
  const toast = useToast();
  const { auth, getRedirect } = useAuth();
  const navigate = useNavigate();

  return useMutation((req: LoginForm) => loginApi(req), {
    onSuccess: (res) => {
      auth.signIn(res?.data, isKeepLogin);
      const redirectPage = getRedirect() ?? PATH.root;

      navigate(redirectPage);
    },

    onError: (error: AxiosError<any>) => {
      const errResponse = error.response;

      if (errResponse?.status === 401) {
        let errMsg = errResponse.data.message;
        if (errResponse?.data.code === 'LOGIN_FAIL_EXCEEDED') {
          const minute = numericOnly(errMsg);
          errMsg = `제한된 로그인 시도 횟수를 초과했어요\n ${minute}분 후 다시 시도해 주세요`;
        }
        toast(errMsg);
      }
    },
  });
};

export const useLogout = () => {
  const { auth, getRedirect } = useAuth();
  const navigate = useNavigate();

  return useMutation(logoutApi, {
    onSuccess: () => {
      auth.signOut();
      navigate(PATH.login);
      getRedirect();
    },
  });
};
