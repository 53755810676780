import { useRecoilValue } from 'recoil';

import {
  commonAgeSelector,
  commonAreaSelector,
  commonCitySelector,
  commonPeriodSelector,
  commonBusinessItemSelector,
  commonBusinessTypeSelector,
} from 'store/common';

const useCommonList = () => {
  const ageState = useRecoilValue(commonAgeSelector);
  const cityState = useRecoilValue(commonCitySelector);
  const areaState = useRecoilValue(commonAreaSelector);
  const periodState = useRecoilValue(commonPeriodSelector);
  const BusinessItemState = useRecoilValue(commonBusinessItemSelector);
  const BusinessTypeState = useRecoilValue(commonBusinessTypeSelector);

  return {
    ageState,
    cityState,
    areaState,
    periodState,
    BusinessItemState,
    BusinessTypeState,
  };
};

export default useCommonList;
