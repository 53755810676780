import React from 'react';

import { FormInput, FormTextarea } from 'components';
import { useSupportFormRegister } from 'pages/support/hooks';
import { SupportFormObj } from 'pages/support/hooks/useSupportFormRegister';

function BasicFormSection() {
  const { errors, register, watch, clearErrors } = useSupportFormRegister();
  const { title, to, content } = SupportFormObj(register, clearErrors);

  return (
    <>
      <FormInput
        isFull
        required
        id="title"
        label="제목"
        maxLength={60}
        register={title}
        value={watch('title')}
        error={errors?.title?.message?.toString()}
      />
      <FormInput
        isFull
        id="to"
        required
        label="지원대상 (게시글)"
        maxLength={50}
        register={to}
        value={watch('to')}
        error={errors?.to?.message?.toString()}
      />
      <FormTextarea
        id="content"
        required
        label="지원내용"
        maxLength={1000}
        register={content}
        value={watch('content')}
        error={errors?.content?.message?.toString()}
      />
    </>
  );
}

export default BasicFormSection;
