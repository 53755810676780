import React, { HTMLInputTypeAttribute } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';

import * as S from './LabelInput.styled';

export interface LabelInputProps {
  id: string;
  className?: string;
  label: string;
  labelHidden?: boolean;
  error?: string;
  errorHidden?: boolean;
  required?: boolean;
  type?: HTMLInputTypeAttribute;
  name: string;
  placeholder: string;
  register: UseFormRegisterReturn<string>;
}

const LabelInput = ({
  register,
  id,
  name,
  className,
  label,
  labelHidden = false,
  error,
  errorHidden = false,
  required,
  type = 'text',
  placeholder,
  ...restProps
}: LabelInputProps) => (
  <S.Wrapper className={className}>
    <S.Label htmlFor={id} hidden={labelHidden}>
      {label}
    </S.Label>
    <S.Input
      {...register}
      id={id}
      name={name}
      type={type}
      autoComplete="off"
      placeholder={placeholder}
      aria-invalid={error ? 'true' : 'false'}
      aria-required={required ? 'true' : 'false'}
      {...restProps}
    />
    {!errorHidden && error && <S.ValidText id={`${id}-errMsg`}>{error}</S.ValidText>}
  </S.Wrapper>
);

export default LabelInput;
