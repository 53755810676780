import { css } from '@emotion/react';

import { resetCss } from './reset';

export const globalStyles = css`
  ${resetCss}

  .is-active {
    color: #000 !important;
    font-weight: bold !important;
  }

  .modal_container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    .inner_modal {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 300px;
      height: 200px;
      border-radius: 6px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
      background-color: #ffffff;
      .modal_body {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 65%;
        padding: 10px;
      }
      .modal_foot {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          padding: 10px;
          margin: 0 5px;
        }
      }
    }
    .btn_close {
      position: absolute;
      right: 0;
      top: 0;
      padding: 10px;
    }
  }

  .guard_btn {
    font-size: 16px;
    padding: 10px 20px;
    height: 50px;
    background-color: #222;
    color: #fff;
    border: none;
  }
`;
