export const INIT_AREA_TAG = '0';
export const INIT_REGION_TAG = '전국';

export const supportTableColumnList = [
  { key: 'title', label: '제목' },
  { key: 'region', label: '지역' },
  { key: 'closeDate', label: '마감일자' },
  { key: 'area', label: '지원분야' },
  { key: 'period', label: '창업기간' },
  { key: 'age', label: '연령' },
  { key: 'view', label: '조회수' },
];

export const localTabList = [
  { label: '진행중인 공고', key: 'processing' },
  { label: '마감된 공고', key: 'closed' },
];

export const periodRadioFilterList = [
  { value: 'all', label: '전체기간' },
  { value: 'always', label: '상시 모집' },
  { value: 'first_come', label: '선착순 마감' },
  { value: 'spent', label: '예산 소진 시 마감' },
  { value: 'closeDate', label: '직접 입력' },
];

export const PriceStatusRadioList = [
  { value: 'amount', label: '금액 입력' },
  { value: 'etc', label: '기타' },
];

export const PeriodRadioList = [
  { value: 'date', label: '마감일 설정' },
  { value: 'always', label: '상시 지원' },
  { value: 'first_come', label: '선착순 마감' },
  { value: 'spent', label: '예산 소진 시 마감' },
];

export const PeriodRadioObj = {
  always: '상시 지원',
  spent: '예산 소진 시 마감',
  first_come: '선착순 마감',
};
