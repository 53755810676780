import { css } from '@emotion/react';

import { fonts } from 'styles/themes/fonts';
import { colors } from 'styles/themes/colors';
import { boxShadows } from 'styles/themes/boxShadows';

export const input = {
  search: css`
    ${fonts.regular16};
    width: 100%;
    height: 56px;
    border-radius: 100px;
    border: 2px solid ${colors.blue10};
    padding: 0 40px 0 28px;
    background-color: ${colors.white};
    box-shadow: ${boxShadows.input};
    transition: 0.3s;

    &::placeholder {
      color: ${colors.gray50};
    }
  `,
  primary: css`
    ${fonts.regular15};
    width: 100%;
    height: 52px;
    border-radius: 10px;
    border: 1px solid ${colors.gray30};
    padding: 0 16px;
    transition: 0.3s;

    &::placeholder {
      color: ${colors.gray50};
    }

    @media (hover: hover) {
      &:not(:disabled):hover {
        border-color: ${colors.blue10};
      }
    }

    &:focus {
      border-color: ${colors.blue20};
    }

    &[aria-invalid='true'] {
      border-color: ${colors.red10};
    }

    &:disabled {
      border-color: ${colors.gray20};
      color: ${colors.gray70};
      background-color: ${colors.gray10};
      cursor: not-allowed;
    }
  `,
} as const;

export type InputType = typeof input;
