import React from 'react';
import { useSearchParams } from 'react-router-dom';

import * as S from './SupportManagement.styled';

interface ScrapManagementProps {
  selectedSupports: string[];
  onDelete: () => void;
  onClose: () => void;
}

const SupportManagement = ({ selectedSupports, onDelete, onClose }: ScrapManagementProps) => {
  const [searchParams] = useSearchParams();

  return (
    <S.ScrapManagement>
      <S.Count>
        <strong>{selectedSupports.length}</strong>개 선택됨
      </S.Count>
      <S.Divider />
      <S.Utils>
        {searchParams?.get('type') !== 'closed' && (
          <S.Button type="button" onClick={onClose}>
            마감으로 이동
          </S.Button>
        )}
        <S.Button type="button" onClick={onDelete} isDelete>
          공고 삭제
        </S.Button>
      </S.Utils>
    </S.ScrapManagement>
  );
};

export default SupportManagement;
