/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import dayjs from 'dayjs';

import { Icon, InputContext, FormInput } from 'components';
import CalendarBox from 'components/common/calendar/CalendarBox';
import { useSupportFormRegister } from 'pages/support/hooks';
import { SupportFormObj } from 'pages/support/hooks/useSupportFormRegister';
import { formatPeriodPlaceholder } from 'utils/formatter';
import { AmountDropdownList } from 'assets/static/common';
import { PeriodRadioList, PriceStatusRadioList } from 'assets/static/support';
import { ErrorForm } from 'types';
import { SupportSpecificForm } from 'types/support';
import * as S from './Detail.styled';

interface Props {
  cityName: string;
  periodStatus: string;
  amountPrefix: string;
  isActiveTime: boolean;
  form: SupportSpecificForm;
  error: ErrorForm<SupportSpecificForm>;
  inputRef: React.MutableRefObject<object>;
  regionState: { regionId: number; cityId: number };
  handleBlurAttachment: () => void;
  handleClickActiveTime: () => void;
  handleAmountChange: (opt: string) => void;
  handleDeleteImage: (index: number) => void;
  setCityName: React.Dispatch<React.SetStateAction<string>>;
  handleSelectDate: () => (selectDate: string, type?: 'START' | 'END') => void;
  handlePeriodRadioGroup: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleAttachment: (event: React.ChangeEvent<HTMLInputElement>, key: string) => void;
  setRegionState: ({ regionId, cityId }: { regionId: number; cityId: number }) => void;
}

function DetailFormSection({
  form,
  error,
  cityName,
  inputRef,
  regionState,
  isActiveTime,
  periodStatus,
  amountPrefix,
  setCityName,
  setRegionState,
  handleSelectDate,
  handleAttachment,
  handleDeleteImage,
  handleBlurAttachment,
  handleClickActiveTime,
  handlePeriodRadioGroup,
  handleAmountChange,
}: Props) {
  const {
    fields,
    errors,
    amountType,
    amountRegister,
    watch,
    remove,
    append,
    register,
    clearErrors,
    handlePriceRadioGroup,
  } = useSupportFormRegister();
  const { institution, method, url, contact } = SupportFormObj(register, clearErrors);

  return (
    <>
      <InputContext id="locationId" label="지역" error={error.regionId}>
        <S.CustomRegionSelector
          innerHidden
          selectedHidden
          name="locationId"
          cityName={cityName}
          regionState={regionState}
          setCityName={setCityName}
          placeholder="지역을 선택해 주세요"
          setRegionState={setRegionState}
        />
      </InputContext>

      <S.AmountBox>
        <S.CustomFormInput
          isFull
          id="amount"
          label="지원금액"
          value={watch('amount')}
          register={amountRegister}
          error={errors?.amount?.message?.toString()}
          kind={amountType === 'amount' ? 'price' : 'text'}
          addCheckBoxRender={() => (
            <FormInput.RadioBox
              name="price"
              value={amountType}
              radios={PriceStatusRadioList}
              onChange={handlePriceRadioGroup}
            />
          )}
          addDropDownRender={() => (
            <S.CustomInputContext
              id="amount"
              label="창업기간"
              labelHidden
              errorHidden
              error={errors?.amount?.message?.toString()}
            >
              <S.CustomDropdown
                name="amount"
                value={amountPrefix}
                options={AmountDropdownList}
                onSelect={handleAmountChange}
              />
            </S.CustomInputContext>
          )}
        />
      </S.AmountBox>

      <CalendarBox
        label="접수기간"
        addRadioBoxRender={() => (
          <CalendarBox.RadioBox
            name="period"
            value={periodStatus}
            radios={PeriodRadioList}
            onChange={handlePeriodRadioGroup}
          />
        )}
      >
        <S.StartDateInput
          ref={inputRef}
          selectDateType="START"
          value={form.startDate}
          onChange={handleSelectDate}
        />
        <span>~</span>
        <S.EndDateInput
          hasTimeSwitch
          ref={inputRef}
          selectDateType="END"
          error={error.endDate}
          hasTime={isActiveTime}
          value={form.endDate as string | undefined}
          disabledBefore={dayjs(form?.startDate) ?? dayjs()}
          placeholder={formatPeriodPlaceholder(periodStatus)}
          disabled={periodStatus === 'spent' || periodStatus === 'always'}
          onChange={handleSelectDate}
          handleClickActiveTime={handleClickActiveTime}
        />
      </CalendarBox>

      <S.CustomFormInput
        label="주관기관"
        id="institution"
        maxLength={50}
        register={institution}
        value={watch('institution')}
        error={errors?.institution?.message?.toString()}
      />

      <S.InquiriesBox>
        {fields.map((item, index) => {
          return (
            <S.InquiriesInputWrapper key={item.id}>
              <S.CustomFormInput
                id={item.id}
                maxLength={60}
                label={index === 0 ? '문의처' : ''}
                register={contact(index)}
                value={watch(`contact.${index}.name`)} // TODO:
                error={errors?.contact?.[index]?.name?.message}
              />
              {index !== 0 && (
                <button type="button" onClick={() => remove(index)}>
                  <Icon name="deleteIcon" />
                </button>
              )}
            </S.InquiriesInputWrapper>
          );
        })}
        <S.InquiriesInputBtn
          size="smd"
          variant="secondary"
          label=" + 문의처 추가"
          show={watch('contact')?.length < 3}
          disabled={watch('contact')?.length >= 3}
          onClick={() => append({ name: '' })}
        />
      </S.InquiriesBox>

      <S.CustomFormInput
        id="method"
        label="접수방법"
        maxLength={60}
        register={method}
        value={watch('method')}
        error={errors?.method?.message?.toString()}
      />

      <S.CustomFormInput
        id="url"
        label="URL"
        register={url}
        value={watch('url')}
        error={errors?.url?.message?.toString()}
      />

      <InputContext id="files" label="첨부파일">
        <S.CustomFileUpload
          id="files"
          name="attachment"
          imagePreview={form.attachment as File[]}
          handleAttachment={handleAttachment}
          handleDeleteImage={handleDeleteImage}
          handleBlurAttachment={handleBlurAttachment}
          error={error?.attachment}
        />
      </InputContext>
    </>
  );
}

export default DetailFormSection;
