import ax from 'api/axios';
import { isEmpty as _isEmpty } from 'lodash-es';

import { MemberQueriesModel } from 'types/member';

export const loadMemberList = async (queries: MemberQueriesModel) => {
  const params = _isEmpty(queries)
    ? {
        receiveSms: 'n',
        receiveEmail: 'n',
      }
    : { ...{ receiveSms: 'n', receiveEmail: 'n' }, ...queries };

  const res = await ax.get('/admin/user', { params });
  return res.data;
};

export const downloadMemberExcel = async () => {
  const res = await ax.get('/admin/user/excel', {
    responseType: 'arraybuffer',
  });
  return res.data;
};
