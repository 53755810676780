import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon } from 'components/icon';

export const SectorSelector = styled.div`
  position: relative;
  width: 100%;
  height: 52px;
`;

export const OpenerBtn = styled.button`
  ${({ theme }) => css`
    ${theme.dropdown.thin};
  `}
`;

export const Placeholder = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.gray50};
  `}
`;

export const ArrowIcon = styled(Icon)`
  ${({ theme }) => css`
    fill: ${theme.colors.gray50};
  `}
`;

interface DropBoxProps {
  isOpen: boolean;
}

export const DropBox = styled.div<DropBoxProps>`
  ${({ theme, isOpen }) => css`
    position: absolute;
    top: 48px;
    display: ${isOpen ? 'grid' : 'none'};
    grid-template-rows: repeat(272px, 1fr);
    width: 240px;
    border-radius: 10px;
    box-shadow: ${theme.boxShadows.dropdown};
    background-color: ${theme.colors.white};
    z-index: ${theme.zIndex.dialog};
    overflow: hidden;
  `}
`;

const list = css`
  width: 100%;
  height: 100%;
  padding: 10px 0;
  overflow: overlay;
`;

export const OptionList = styled.ul`
  ${list};
`;

export const InnerTags = styled.div`
  ${({ theme }) => css`
    min-height: 85px;
    height: max-content;
    max-height: 124px;
    border-top: 1px solid ${theme.colors.gray20};
    padding: 12px 20px;
    overflow: overlay;
  `}
`;

export const Tip = styled.p`
  ${({ theme }) => css`
    ${theme.fonts.regular14};
    margin-bottom: 8px;
    color: ${theme.colors.gray50};
  `}
`;

const taglist = css`
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 8px;
`;

export const InnerTagList = styled.ul`
  ${taglist};
`;

export const TagList = styled.ul`
  ${taglist};
  margin-top: 8px;
`;
