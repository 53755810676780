/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useImmer } from 'use-immer';
import dayjs from 'dayjs';

import { MONTH, YEAR_ARR } from 'constants/common';

const initState = {
  month: '',
  year: '',
};

interface SelectDateProps {
  month: any;
  year: any;
  handleChangeMonth: any;
  dialogRef: any;
  handleDialogClose?: any;
}

const SelectDate = ({ month, year, handleChangeMonth, dialogRef, ...rest }: SelectDateProps) => {
  const [dateState, setDateState] = useImmer(initState);

  const isEmpty = (value: any) => {
    if (value) {
      return value;
    } else {
      return false;
    }
  };

  const setDate = (monthDate: any, yearDate: any) => {
    const newMonth =
      isEmpty(monthDate) ||
      Object.keys(MONTH).find((key) => MONTH[key as unknown as keyof typeof MONTH] === month);
    const newYear = yearDate ? yearDate : year;
    const date = dayjs()
      .set('month', newMonth - 1)
      .set('year', newYear)
      .format('YYYY-MM-DD');
    handleChangeMonth(date);
  };
  const handleClickDate = (type: string, item: any) => (e: any) => {
    e.stopPropagation();

    if (type === 'month') {
      setDateState((draft) => {
        draft[type] = String(
          Object.keys(MONTH).find((key) => MONTH[key as unknown as keyof typeof MONTH] === item),
        );
      });
      setDate(
        Object.keys(MONTH).find((key) => MONTH[key as unknown as keyof typeof MONTH] === item),
        dateState.year,
      );
    } else {
      setDateState((draft) => {
        draft[type as keyof typeof dateState] = item;
      });
      setDate(dateState.month, item);
    }
  };

  return (
    <Root ref={dialogRef} {...rest}>
      <Header>
        <span>Month</span>
        <span>Year</span>
      </Header>
      <Body>
        <Ul>
          {Object.values(MONTH).map((item, i) => {
            return (
              <Btn
                key={i}
                type="button"
                selected={item === month}
                onClick={handleClickDate('month', item)}
              >
                <Li>{item}</Li>
              </Btn>
            );
          })}
        </Ul>

        <Ul>
          {YEAR_ARR.map((item, i) => {
            return (
              <Btn
                key={i}
                type="button"
                selected={item === year}
                onClick={handleClickDate('year', item)}
              >
                <Li>{item}</Li>
              </Btn>
            );
          })}
        </Ul>
      </Body>
    </Root>
  );
};

export default SelectDate;

const Root = styled.dialog`
  ${({ theme }) => css`
    position: absolute;
    display: block;
    left: 20px;
    width: 171px;
    height: 213px;
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.gray30};
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  `}
`;

const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.colors.gray30};
    padding: 9px 13px;
    & > span {
      font-size: 1.2rem;
      font-weight: 600;
      color: ${theme.colors.gray60};
      :first-child {
        width: 100px;
      }
      :last-child {
        width: 68px;
      }
    }
  `}
`;

const Body = styled.div`
  display: flex;
`;

const Ul = styled.ul`
  ${({ theme }) => css`
    height: 175px;
    overflow-y: auto;

    &:first-child {
      width: 100px;
      border-right: 1px solid ${theme.colors.gray30};
    }

    &:last-child {
      width: 68px;
    }
  `}
`;

const Li = styled.li`
  height: 36px;
  padding: 10px 13px;
`;

interface BtnProps {
  selected: boolean;
}

const Btn = styled.button<BtnProps>`
  ${({ theme, selected }) => css`
    width: 100%;
    font-size: 1.3rem;
    font-weight: 600;
    text-align: left;
    & > li {
      color: ${selected ? theme.colors.blue40 : theme.colors.gray70};
    }
    &:hover {
      font-weight: 700;
    }
  `}
`;
