import React from 'react';

import { Icon } from 'components/icon';
import * as S from './NoResult.styled';

interface NoResultProps {
  children?: React.ReactNode;
  title: string;
}

const NoResult = ({ children, title }: NoResultProps) => {
  return (
    <S.NoResult>
      <S.Content>
        <Icon name="noResultIcon" />
        <h3>{title}</h3>
      </S.Content>
      {children}
    </S.NoResult>
  );
};

export default NoResult;
