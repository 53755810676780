import dayjs, { Dayjs } from 'dayjs';
import { MonthYear } from 'types';

export const getUpdatedMonthYear = (monthYear: MonthYear, monthIncrement: number) => {
  return monthYear.startDate.clone().add(monthIncrement, 'month');
};

export const getMonthYearDetails = (initialDate: Dayjs) => {
  const value = initialDate;
  const month = initialDate.format('MM');
  const year = initialDate.format('YYYY');
  const date = initialDate.format('DD');
  const startDate = dayjs(`${year}${month}01`);
  const prevMonthStartDate = startDate.clone().subtract(1, 'month');
  const nextMonthStartDate = startDate.clone().add(1, 'month');
  const firstDOW = Number(startDate.format('d'));
  const lastDate = Number(startDate.clone().endOf('month').format('DD'));
  const monthName = startDate.format('MMM');
  const prevMonthLastDateNum = Number(prevMonthStartDate.endOf('month').format('DD'));
  const firstWeekPrevMonthDate = prevMonthStartDate.set(
    'date',
    prevMonthLastDateNum - firstDOW + 1,
  );

  return {
    value,
    startDate,
    prevMonthStartDate,
    nextMonthStartDate,
    firstDOW,
    lastDate,
    monthName,
    date,
    month,
    year,
    firstWeekPrevMonthDate,
  };
};

export const getNewMonthYear = (prevData: MonthYear, monthIncrement: number) => {
  const newMonthYear = getUpdatedMonthYear(prevData, monthIncrement);

  return getMonthYearDetails(newMonthYear);
};
