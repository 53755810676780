import React from 'react';

import { InputContext } from 'components';
import useCommonList from 'hooks/useCommonList';
import { ErrorForm } from 'types';
import { SupportSpecificForm } from 'types/support';
import * as S from './Apply.styled';

interface Props {
  form: SupportSpecificForm;
  error: ErrorForm<SupportSpecificForm>;
  handleSelectPeriod: (opt: string) => void;
  handleBlurAge: React.FocusEventHandler<HTMLButtonElement>;
  handleBlurArea: React.FocusEventHandler<HTMLButtonElement>;
  handleBlurPeriod: React.FocusEventHandler<HTMLButtonElement>;
  handleBlurBusinessType: React.FocusEventHandler<HTMLButtonElement>;
  handleSelectAge: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeAreaList: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeBusinessType: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function ApplyFormSection({
  form,
  error,
  handleBlurAge,
  handleBlurArea,
  handleSelectAge,
  handleBlurPeriod,
  handleSelectPeriod,
  handleChangeAreaList,
  handleBlurBusinessType,
  handleChangeBusinessType,
}: Props) {
  const { ageState, areaState, periodState, BusinessTypeState } = useCommonList();

  return (
    <>
      <InputContext id="typeIds" label="지원대상" error={error.typeIds}>
        <S.CustomSectorSelector
          name="typeIds"
          placeholder="지원대상을 선택해 주세요"
          value={form.typeIds}
          options={BusinessTypeState}
          onBlur={handleBlurBusinessType}
          onChange={handleChangeBusinessType}
        />
      </InputContext>

      <InputContext id="areaIds" label="지원분야" error={error.areaIds}>
        <S.CustomSectorSelector
          name="areaIds"
          placeholder="지원분야를 선택해 주세요"
          value={form.areaIds}
          options={areaState}
          onBlur={handleBlurArea}
          onChange={handleChangeAreaList}
        />
      </InputContext>

      <InputContext id="ageIds" label="연령" error={error.ageIds}>
        <S.CustomSectorSelector
          name="ageIds"
          placeholder="연령을 선택해 주세요"
          value={form.ageIds}
          options={ageState}
          onBlur={handleBlurAge}
          onChange={handleSelectAge}
        />
      </InputContext>

      <InputContext id="periodId" label="창업기간" error={error.periodId}>
        <S.CustomDropdown
          name="periodId"
          placeholder="창업기간을 선택해 주세요"
          value={form.periodId}
          options={periodState}
          onBlur={handleBlurPeriod}
          onSelect={handleSelectPeriod}
        />
      </InputContext>
    </>
  );
}

export default ApplyFormSection;
