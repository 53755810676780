import React, { useMemo } from 'react';

import useCommonList from 'hooks/useCommonList';
import * as S from './Area.styled';

interface AreaProps {
  area: string;
  handleSelectArea: (opt: string) => void;
}

function Area({ area, handleSelectArea }: AreaProps) {
  const { areaState } = useCommonList();

  const areaStateWithAllSelect = useMemo(() => ({ ...areaState, '0': '전국' }), [areaState]);

  return (
    <S.RootStyle>
      <S.LabelBox>지원분야</S.LabelBox>
      <S.CustomDropdown
        name="areaId"
        value={area}
        placeholder="지원분야를 선택해 주세요"
        options={areaStateWithAllSelect}
        onSelect={handleSelectArea}
      />
    </S.RootStyle>
  );
}

export default Area;
