import { css } from '@emotion/react';
import styled from '@emotion/styled';
import DefaultButton from 'components/common/button/defaultBtn/DefaultBtn.component';

export const RootStyle = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 13px;
  width: 100%;
`;

export const FilterContainer = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    width: 100%;
    border: 1px solid ${theme.colors.gray30};
    margin-top: 5rem;
  `}
`;

export const TopSearchFilter = styled.div`
  ${({ theme }) => css`
    grid-column: 1 / 3;
    border-bottom: 1px solid ${theme.colors.gray30};
  `}
`;

export const MiddlePeriodFilter = styled.div`
  ${({ theme }) => css`
    grid-row: 2;
    grid-column: 1 / 3;
    border-bottom: 1px solid ${theme.colors.gray30};
  `}
`;

export const BottomRegionFilter = styled.div`
  grid-row: 3;
  grid-column: 1;
`;

export const BottomAreaFilter = styled.div`
  grid-row: 3;
  grid-column: 2;
`;

export const BtnGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 12px;
  width: 100%;
`;

export const ConfirmBtn = styled(DefaultButton)`
  border-radius: 0;
`;

export const ResetBtn = styled(ConfirmBtn)`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.gray30};
    color: ${theme.colors.gray60};
    background-color: ${theme.colors.white};
  `}
`;
