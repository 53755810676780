import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const NavTab = styled.li`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    border-bottom: 1px solid ${theme.colors.gray20};
  `}
`;

export const NavBtn = styled.button`
  ${({ theme }) => css`
    ${theme.fonts.medium16}
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    padding: 0 16px;
    color: ${theme.colors.gray50};
    transition: color 0.3s, border-bottom 0.3s;

    &[aria-current='page'] {
      ${theme.fonts.semibold16}
      color: ${theme.colors.black};
      border-bottom: 2px solid ${theme.colors.gray70};
    }

    @media (hover: hover) {
      &:not(:disabled):hover {
        ${theme.fonts.semibold16}
        color: ${theme.colors.black};
      }
    }
  `}
`;
