import React, { forwardRef, useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';

import { colors } from 'styles';
import { MonthYear } from 'types';

interface MonthYearDialogProps {
  isOpen: boolean;
  monthYear: MonthYear;
  handleChangeMonth: (month: number) => () => void;
  handleChangeYear: (year: number) => () => void;
  className?: string;
}

const MonthYearDialog = forwardRef<HTMLDialogElement, MonthYearDialogProps>(
  ({ className, isOpen, monthYear, handleChangeMonth, handleChangeYear }, ref) => {
    const monthRef = useRef<HTMLButtonElement | null>(null);
    const monthContainerRef = useRef<HTMLUListElement | null>(null);
    const yearRef = useRef<HTMLButtonElement | null>(null);
    const yearContainerRef = useRef<HTMLUListElement | null>(null);

    useEffect(() => {
      if (
        isOpen &&
        monthContainerRef.current &&
        yearContainerRef.current &&
        monthRef.current &&
        yearRef.current
      ) {
        const monthParent = monthContainerRef.current;
        const yearParent = yearContainerRef.current;
        const monthTop =
          monthRef.current.getBoundingClientRect().top - monthParent.getBoundingClientRect().top;
        const yearTop =
          yearRef.current.getBoundingClientRect().top - yearParent.getBoundingClientRect().top;

        monthParent.scrollTo(0, monthTop);
        yearParent.scrollTo(0, yearTop);
      }
    }, [isOpen]);

    return (
      <>
        {isOpen && (
          <DialogRoot className={className} aria-modal="true" open={isOpen} ref={ref}>
            <Header>
              <div id="month">월</div>
              <div id="year">연도</div>
            </Header>
            <Container>
              <MonthList ref={monthContainerRef} aria-labelledby="month">
                {[...Array(12)].map((_, i) => {
                  const month = dayjs().set('month', 11 - i);
                  const isSelected = monthYear.month === month.format('MM');

                  return (
                    <li key={i}>
                      <MonthYearBtn
                        ref={(el) => isSelected && (monthRef.current = el)}
                        type="button"
                        aria-selected={isSelected}
                        onClick={handleChangeMonth(11 - i)}
                      >
                        <time>{month.format('MMM')}</time>
                      </MonthYearBtn>
                    </li>
                  );
                })}
              </MonthList>
              <YearList ref={yearContainerRef} aria-labelledby="year">
                {[...Array(10)].map((_, i) => {
                  const year = dayjs().add(1, 'year').subtract(i, 'year').format('YYYY');
                  const isSelected = monthYear.year === year;

                  return (
                    <li key={i}>
                      <MonthYearBtn
                        ref={(el) => isSelected && (yearRef.current = el)}
                        type="button"
                        aria-selected={isSelected}
                        onClick={handleChangeYear(+year)}
                      >
                        <time>{year}</time>
                      </MonthYearBtn>
                    </li>
                  );
                })}
              </YearList>
            </Container>
          </DialogRoot>
        )}
      </>
    );
  },
);

export default MonthYearDialog;
MonthYearDialog.displayName = 'MonthYearDialog';

const DialogRoot = styled.dialog`
  ${({ theme }) => css`
    position: absolute;
    top: calc(100% + 4px);
    width: 231px;
    border: 1px solid ${theme.colors.gray30};
    background-color: ${theme.colors.white};
    box-shadow: 0px 1px 3px rgba(175, 181, 187, 0.2);
    z-index: 1000;
  `}
`;

const Header = styled.header`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 100px 1fr;
    height: 32px;
    border-bottom: 1px solid ${theme.colors.gray20};

    & > div {
      ${theme.fonts.semibold14};
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 13px;
      color: ${theme.colors.gray60};
    }
  `}
`;

const Container = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 100px 1fr;
    column-gap: 1px;
    background-color: ${theme.colors.gray20};
  `}
`;

const mixin_list = css`
  height: 170px;
  background-color: ${colors.white};
  overflow-y: overlay;
`;

const MonthList = styled.ul`
  ${mixin_list};
`;

const YearList = styled.ul`
  ${mixin_list};
`;

const MonthYearBtn = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    height: 34px;
    padding: 0 13px;

    & > time {
      ${theme.fonts.regular13};
      color: ${theme.colors.gray70};
    }

    &[aria-selected='true'] > time {
      font-weight: 500;
      color: ${theme.colors.blue30};
    }
  `}
`;
