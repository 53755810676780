import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Icon } from 'components/icon';

export const RootStyle = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  row-gap: 8px;
  width: 100%;
`;

export const PreviewBox = styled.span`
  ${({ theme }) => css`
    ${theme.input.primary};
    position: relative;
    width: 570px;
    height: 44px;
    display: flex;
    align-items: center;
    border-radius: 0;
    padding-right: 50px;
    background-color: ${theme.colors.gray10};

    & > p {
      color: ${theme.colors.black};
      overflow: hidden;
      text-overflow: ellipsis; // text가 overflow 되면 ... 처리해줌
      white-space: nowrap;
    }
  `}
`;

export const IconBtn = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  padding-right: 16px;
`;

export const CloseIcon = styled(Icon)``;

interface UploadBtnProps {
  disabled?: boolean;
  error?: boolean;
}

export const UploadBtn = styled.div<UploadBtnProps>`
  ${({ theme, disabled, error }) => css`
    ${theme.button.size.smd};
    ${theme.button.variant.outline};
    color: ${disabled ? theme.colors.white : theme.colors.gray80};
    background-color: ${disabled ? theme.colors.gray30 : theme.colors.white};
    opacity: ${disabled ? 0.4 : 1};
    position: relative;
    transition: 0.3s;

    && {
      border: 1px solid ${error ? theme.colors.red10 : theme.colors.gray30};
      border-radius: 0;
      cursor: ${disabled ? 'not-allowed' : 'pointer'};

      &:hover {
        border-color: ${theme.colors.blue10};
      }
    }

    & > label {
      ${theme.fonts.medium15};
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      color: ${theme.colors.gray60};
      pointer-events: ${disabled ? 'none' : ''};
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      transition: 0.3s;

      &:hover {
        color: ${theme.colors.blue20};
      }
    }

    & > input {
      display: none;
    }
  `}
`;

export const ValidText = styled.p`
  ${({ theme }) => css`
    ${theme.fonts.regular14};
    position: absolute;
    left: 0;
    bottom: -24px;
    color: ${theme.colors.red20};
  `}
`;
