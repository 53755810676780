import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Anchor = styled.a`
  ${({ theme }) => css`
    ${theme.fonts.regular14};
    position: relative;
    height: max-content;
    transition: 0.3s;

    &::before {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: ${theme.colors.gray60};
      transition: 0.3s;
    }

    @media (hover: hover) {
      &:hover {
        color: ${theme.colors.black};

        &::before {
          background-color: ${theme.colors.black};
        }
      }
    }
  `}
`;
