import { css } from '@emotion/react';
import styled from '@emotion/styled';

import LoginForm from '../form/LoginForm.component';

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 384px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 120px 0;
`;

export const SectionTitle = styled.h1`
  ${({ theme }) => css`
    ${theme.a11y.visuallyHidden}
  `}
`;

export const LogoBox = styled.div`
  ${({ theme }) => css`
    margin-bottom: 42px;

    & > svg {
      fill: ${theme.colors.black};
    }
  `}
`;

export const CustomLoginForm = styled(LoginForm)`
  margin-bottom: 80px;
`;
