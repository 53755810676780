/* eslint-disable @typescript-eslint/no-explicit-any */
import { SyntheticEvent } from 'react';
import { KeyOf } from 'types';

import { formatPrice } from 'utils/formatter';

export const clearFormControlErrors = <T>(e: SyntheticEvent, clearErrorFn: any) => {
  const target = e.target as HTMLInputElement;
  const val = target?.value;

  if (val) {
    const name = target?.name;

    clearErrorFn(name as KeyOf<T>);
  }
};

export const priceFormControl = (
  e: SyntheticEvent<Element, Event>,
  setValue: any,
  clearErrorFn: any,
) => {
  const target = e.target as HTMLInputElement;
  const val = target?.value;
  const reNewValue = formatPrice(val);

  if (val.length < 2) {
    clearFormControlErrors(e, clearErrorFn);
  }
  return setValue('amount', reNewValue);
};
