/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

import ax from 'api/axios';

export const getCommonListApi = async <T>(key: string): Promise<T> => {
  const res = await ax.get(`/common/${key}`);
  return res.data;
};

export const renewAccessToken = async (refreshToken: string): Promise<any> => {
  const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/common/accesstoken`, {
    headers: { Authorization: `Bearer ${refreshToken}` },
  });

  return res.data;
};

export const loadPresignedUrl = async (s3key: string, fileName?: string) => {
  const params = {
    ...(fileName && { fileName }),
  };

  return await ax.get(`common/presigned/${s3key}`, { params }).then((res) => res?.data?.url);
};

export const addPresignedUrl = async (s3key: string, file: File | Blob, prefix: string) => {
  if (!file || !s3key) return;

  const postPresigned = await ax.post(`/common/presigned/${prefix}/${s3key}`);
  const { url, fields } = postPresigned.data;
  const formData = new FormData();
  for (const [key, value] of Object.entries(fields)) {
    formData.append(key, value as string | Blob);
  }

  formData.append('content-type', file.type);
  formData.append('file', file);

  await axios.post(url, formData);
};

export const loadFile = async (url: string) => {
  const res = await axios.get(url, { responseType: 'blob' });
  return res;
};
