import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CoverImg = styled.figure`
  position: relative;
  width: 100%;
  height: 100%;

  & > img {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }
`;

export const ImgCaption = styled.figcaption`
  ${({ theme }) => css`
    ${theme.a11y.visuallyHidden}
  `}
`;
