import { css } from '@emotion/react';
import styled from '@emotion/styled';
import DefaultButton from 'components/common/button/defaultBtn/DefaultBtn.component';

export const Form = styled.form`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 40px;
    border-top: 1px solid ${theme.colors.gray20};
  `}
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  width: 100%;
  margin-top: 40px;
`;

export const ConfirmBtn = styled(DefaultButton)`
  border-radius: 0;
`;

export const CancelBtn = styled(DefaultButton)`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.gray30};
    border-radius: 0;
    background-color: ${theme.colors.white};
    color: ${theme.colors.gray60};
  `}
`;
