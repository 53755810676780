import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { fonts } from 'styles/themes/fonts';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const TextAreaWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

interface StyledLabelProps {
  hidden: boolean;
}

export const Label = styled.label<StyledLabelProps>`
  ${({ theme, hidden = false }) => css`
    ${theme.fonts.medium15};
    position: relative;
    display: flex;
    align-items: center;
    min-width: 160px;
    color: ${theme.colors.gray70};
    padding-top: 12px;
    ${hidden && theme.a11y.visuallyHidden};
  `}
`;

export const TextArea = styled.textarea`
  ${({ theme }) => css`
    ${fonts.regular15};
    width: 100%;
    height: 300px;
    border: 1px solid ${theme.colors.gray30};
    resize: none;
    padding: 16px 24px 16px 16px;
    transition: 0.3s;

    &::placeholder {
      color: ${theme.colors.gray50};
    }

    @media (hover: hover) {
      &:not(:disabled):hover {
        border-color: ${theme.colors.blue10};
      }
    }

    &:focus {
      outline: none;
      border-color: ${theme.colors.blue20};
    }

    &[aria-invalid='true'] {
      border-color: ${theme.colors.red10};
    }

    &:disabled {
      border-color: ${theme.colors.gray20};
      color: ${theme.colors.gray70};
      background-color: ${theme.colors.gray10};
      cursor: not-allowed;
    }
  `}
`;

export const MaxLengthArea = styled.span`
  ${({ theme }) => css`
    ${theme.fonts.regular14};
    position: absolute;
    right: 0;
    bottom: -24px;
    color: ${theme.colors.gray60};
  `}
`;

export const ErrorMessage = styled.span`
  ${({ theme }) => css`
    ${theme.fonts.regular14};
    position: absolute;
    left: 0;
    bottom: -24px;
    color: ${theme.colors.red20};
  `}
`;
