import React, { useRef, useState, useMemo, ForwardedRef } from 'react';

import { Dropdown } from 'components';
import { useKeyTrap, useOnClickOutside } from 'hooks';
import * as S from './SectorSelector.styled';

interface SectorSelector {
  openerRef?: ForwardedRef<HTMLButtonElement>;
  id: string;
  className?: string;
  name: string;
  placeholder?: string;
  error?: boolean;
  required?: boolean;
  readOnly?: boolean;
  options: { [key: string]: string };
  selectedOption: string[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLButtonElement>) => void;
  onDelete?: (opt: string) => () => void;
}

const SectorSelector = ({
  openerRef,
  id,
  className,
  name,
  placeholder,
  error,
  required,
  readOnly,
  options,
  selectedOption,
  onChange,
  onBlur,
}: SectorSelector) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  useKeyTrap(dropdownRef, handleClose);
  useOnClickOutside(containerRef, handleClose);

  function handleToggleDropdown() {
    isOpen ? handleClose() : handleOpen();
  }

  function handleOpen() {
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }

  const selectedOptionLabel = useMemo(() => {
    if (!selectedOption) return false;

    return selectedOption.length > 1
      ? `${options[selectedOption[0]]} 외 ${selectedOption.length - 1}개`
      : options[selectedOption[0]];
  }, [options, selectedOption]);

  const uuid = `sectorSelector-${id}`;
  const tipId = 'tip-option';
  return (
    <S.SectorSelector ref={containerRef} className={className}>
      <S.OpenerBtn
        ref={openerRef}
        type="button"
        name={name}
        disabled={readOnly}
        aria-controls={uuid}
        aria-expanded={isOpen}
        aria-required={required ? 'true' : 'false'}
        aria-invalid={error}
        aria-describedby={tipId}
        onClick={handleToggleDropdown}
        onBlur={onBlur}
      >
        {selectedOptionLabel || <S.Placeholder>{placeholder}</S.Placeholder>}
        <S.ArrowIcon name="arrowBold" />
      </S.OpenerBtn>
      <S.DropBox ref={dropdownRef} id={uuid} isOpen={isOpen}>
        <S.OptionList>
          {Object.entries(options).map(([value, label]) => (
            <Dropdown.CheckOption
              key={label}
              id={label}
              label={label}
              value={value}
              checked={!!selectedOption.find((opt) => opt === value)}
              onChange={onChange}
            />
          ))}
        </S.OptionList>
      </S.DropBox>
    </S.SectorSelector>
  );
};

export default SectorSelector;
