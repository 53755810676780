import React, { useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { PATH } from 'constants/path';
import { Icon } from 'components/icon';
import { useLogout } from 'service/login';
import { useAuth } from 'context/AuthProvider';
import { Gnb } from './index';
import * as S from './Header.styled';

const Header = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { mutate: logoutMutate } = useLogout();

  const handleClickLogo = useCallback(() => {
    if (pathname !== '/') {
      navigate(PATH.root);
    } else {
      return;
    }
  }, [navigate, pathname]);

  const handleLogout = () => {
    logoutMutate();
  };

  return (
    <S.Header>
      <S.Container>
        <S.LogoImgBox onClick={handleClickLogo}>
          <Icon name="mainLocoIcon" />
        </S.LogoImgBox>
        <Gnb />
        {auth.isAuth ? (
          <p onClick={handleLogout}>로그아웃</p>
        ) : (
          <Link to={PATH.login}>
            <S.LoginBtn>로그인</S.LoginBtn>
          </Link>
        )}
      </S.Container>
    </S.Header>
  );
};

export default Header;
