export const size = {
  footer: {
    height_s: '322px',
    height_m: '348px',
    height_l: '412px',
  },
  container: {
    width: '1300px',
  },
} as const;

export type SizeTheme = typeof size;
