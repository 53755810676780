import ax from 'api/axios';

import { LoginForm } from 'types/login';

export const loginApi = (req: LoginForm) => {
  return ax.post('/admin/login', req);
};

export const logoutApi = () => {
  return ax.patch('/admin/logout');
};
