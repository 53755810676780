import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Main = styled.main`
  ${({ theme }) => css`
    position: relative;
    width: 100vw;
    min-width: max-content;
    min-height: 100vh;
    background-color: ${theme.colors.white};
  `}
`;
