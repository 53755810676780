/* eslint-disable @typescript-eslint/no-explicit-any */
import Cookies from 'js-cookie';

export type AuthType = 'online' | 'offline' | 'admin';

export type UserCB = (user: User | null, error: any) => void;

export interface User {
  accessToken: string;
  refreshToken: string;
  [index: string]: string;
}

export class Auth {
  private key;
  private user: User | null;
  private cb: UserCB | null;
  static isAuth: boolean;
  constructor() {
    this.key = 'SG_ADMIN_AUTH';
    this.user = null;
    this.cb = null;
  }

  get isAuth() {
    return !!this.user?.refreshToken;
  }

  get accessToken() {
    return this.user?.accessToken;
  }

  get refreshToken() {
    return this.user?.refreshToken;
  }

  get authType() {
    return this.user?.type;
  }

  get isAdmin() {
    return this.user?.type === 'admin';
  }

  get type() {
    return this.user?.type;
  }

  changeAccessToken(token: string) {
    const newUser = { ...this.user, accessToken: token };
    Cookies.set(this.key, JSON.stringify(newUser));
    this.setUser(newUser as User);
  }

  changeRefreshToken(token: string) {
    const newUser = { ...this.user, refreshToken: token };
    Cookies.set(this.key, JSON.stringify(newUser));
    this.setUser(newUser as User);
  }

  updatePasswordChanged(pwChanged: string) {
    const newUser = { ...this.user, passwordChanged: pwChanged };
    Cookies.set(this.key, JSON.stringify(newUser));
    this.setUser(newUser as User);
  }

  onAuthStateChanged(cb: UserCB) {
    this.cb = cb;

    this.onUserChange(this.user);

    return () => {
      this.cb = null;
    };
  }

  private onUserChange(user: User | null, error?: { message: string }) {
    this.cb && this.cb(user, error);
  }

  private setUser(user: User) {
    this.user = user;
  }

  signIn(data: User, isKeepLogin: boolean) {
    Cookies.set(this.key, JSON.stringify(data), {
      ...(isKeepLogin && { expires: 14 }),
    });
    this.onUserChange(data);
    this.resolveUser();
  }

  signOut() {
    Cookies.remove(this.key);
    this.onUserChange(null);
    this.user = null;
  }

  resolveUser() {
    if (window) {
      const signedInUser = Cookies.get(this.key);

      if (signedInUser) {
        this.setUser(JSON.parse(Cookies.get(this.key)!));
      }
    }

    return this;
  }
}
