import React from 'react';
import { isEmpty } from 'lodash-es';

import { Checkbox } from 'components';
import * as S from './LoginForm.styled';
import useLoginForm from '../hooks/useLoginForm';

interface LoginFormProps {
  className?: string;
}

const LoginForm = ({ className }: LoginFormProps) => {
  const {
    errors,
    isKeepLogin,
    emailRegister,
    passwordRegister,
    handleSubmit,
    handleChangeKeepLogin,
  } = useLoginForm();

  return (
    <S.Form className={className} autoComplete="off" onSubmit={handleSubmit}>
      <S.InputWrapper>
        <S.CustomLabelInput
          id="email"
          label="이메일"
          labelHidden
          errorHidden
          name="email"
          placeholder="이메일"
          register={emailRegister}
        />
        <S.CustomLabelInput
          id="password"
          label="비밀번호"
          labelHidden
          errorHidden
          name="password"
          type="password"
          placeholder="비밀번호"
          register={passwordRegister}
        />
      </S.InputWrapper>
      <S.Bottom>
        <Checkbox
          id="keepLogin"
          name="isKeepLogin"
          label="로그인 상태를 유지할게요"
          checked={isKeepLogin}
          onChange={handleChangeKeepLogin}
        />
      </S.Bottom>
      <S.LoginBtn isFull buttonType="primary" disabled={!isEmpty(errors)}>
        로그인
      </S.LoginBtn>
    </S.Form>
  );
};

export default LoginForm;
