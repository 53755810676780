import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;
`;

interface StyledLabelProps {
  hidden: boolean;
}

export const Label = styled.label<StyledLabelProps>`
  ${({ theme, hidden = false }) => css`
    ${theme.fonts.medium15};
    position: relative;
    display: flex;
    align-items: center;
    width: max-content;
    margin-bottom: 4px;
    color: ${theme.colors.gray70};
    ${hidden && theme.a11y.visuallyHidden};
  `}
`;

export const Input = styled.input`
  ${({ theme }) => css`
    ${theme.input.primary};
  `}
`;

export const ValidText = styled.p`
  ${({ theme }) => css`
    ${theme.fonts.regular14};
    margin-top: 4px;
    color: ${theme.colors.red20};
  `}
`;
