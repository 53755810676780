/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import useDialog from 'hooks/useDialog';

interface DialogBtnProps {
  children: React.ReactNode;
  popup: (isOpen: boolean, dialogRef: any, handleDialogClose: () => void) => void;
  disabled?: boolean;
  className?: string;
}

const DialogBtn = ({ children, className, popup, disabled }: DialogBtnProps) => {
  const { isOpen, dialogRef, handleToggleDialog, handleDialogClose } = useDialog();

  return (
    <>
      <Btn
        type="button"
        className={className}
        aria-haspopup="true"
        aria-pressed={isOpen}
        onClick={handleToggleDialog}
        disabled={disabled}
      >
        {children}
      </Btn>
      {typeof popup === 'function' && !disabled && popup(isOpen, dialogRef, handleDialogClose)}
    </>
  );
};
DialogBtn.displayName = 'DialogBtn';
export default DialogBtn;

interface BtnProps {
  disabled?: boolean;
}

const Btn = styled.button<BtnProps>`
  ${({ disabled }) => css`
    pointer-events: ${disabled ? 'none' : ''};
    background-color: transparent;
  `}
`;
