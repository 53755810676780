import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface InputWrapperProps {
  isCheckBoxRender: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

interface StyledLabelProps {
  hidden: boolean;
}

export const Label = styled.label<StyledLabelProps>`
  ${({ theme, hidden = false }) => css`
    ${theme.fonts.medium15};
    position: relative;
    display: flex;
    align-items: flex-start;
    min-width: 160px;
    width: max-content;
    margin-bottom: 4px;
    color: ${theme.colors.gray70};
    ${hidden && theme.a11y.visuallyHidden};
  `}
`;

export const Input = styled.input`
  ${({ theme }) => css`
    ${theme.input.primary};
    height: 44px;
    border-radius: 0;
  `}
`;

export const PriceInputWrapper = styled.div`
  position: relative;
  display: flex;
  column-gap: 12px;
  align-items: center;
  width: 570px;
`;

export const PriceInputPrefix = styled.span`
  ${({ theme }) => css`
    position: absolute;
    right: 0;
    padding-right: 16px;
    display: flex;
    align-items: center;
    pointer-events: none;

    & > p {
      ${theme.fonts.medium15};
      color: ${theme.colors.black};
    }
  `}
`;

export const ValidText = styled.p`
  ${({ theme }) => css`
    ${theme.fonts.regular14};
    position: absolute;
    left: 0;
    bottom: -24px;
    color: ${theme.colors.red20};
  `}
`;

export const MaxLengthArea = styled.span`
  ${({ theme }) => css`
    ${theme.fonts.regular14};
    position: absolute;
    right: 0;
    bottom: -24px;
    color: ${theme.colors.gray60};
    align-self: flex-end;
  `}
`;

export const maxLengthLabel = styled.span``;

export const RadioList = styled.ul`
  display: flex;
  column-gap: 28px;
`;

interface CheckInputWrapperProps {
  isFull?: boolean;
}

export const CheckInputWrapper = styled.div<CheckInputWrapperProps>`
  ${({ isFull = false }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: ${isFull ? '100%' : 'auto'};
  `}
`;

export const Tooltip = styled.p`
  ${({ theme }) => css`
    display: flex;
    margin-top: 4px;
    color: ${theme.colors.gray60};

    & > svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
      fill: ${theme.colors.gray50};
    }
  `}
`;
