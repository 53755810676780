import React from 'react';

import * as S from './Region.styled';

interface RegionProps {
  cityName?: string;
  regionState: { regionId: number; cityId: number };
  setCityName: React.Dispatch<React.SetStateAction<string>>;
  setRegionState: ({ regionId, cityId }: { regionId: number; cityId: number }) => void;
}

function Region({ cityName, regionState, setCityName, setRegionState }: RegionProps) {
  return (
    <S.RootStyle>
      <S.LabelBox>지역</S.LabelBox>
      <S.CustomRegionSelector
        innerHidden
        selectedHidden
        name="locationId"
        cityName={cityName}
        regionState={regionState}
        setCityName={setCityName}
        setRegionState={setRegionState}
      />
    </S.RootStyle>
  );
}

export default Region;
