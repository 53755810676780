import React from 'react';

import * as S from './DetailSection.styled';

interface DetailSectionProps {
  label: string | React.ReactNode;
  formCompo: React.ReactNode;
}

interface DetailSectionDescListProps {
  val: string | React.ReactNode;
  label: string;
  isUrl?: boolean;
  isFull?: boolean;
  className?: string;
}

function DetailSection({ formCompo, label }: DetailSectionProps) {
  return (
    <S.SectionContainer>
      <S.SectionLabel>{label}</S.SectionLabel>
      <S.DetailSectionContainer>{formCompo}</S.DetailSectionContainer>
    </S.SectionContainer>
  );
}

export default DetailSection;

DetailSection.DescList = function DescList({
  label,
  val,
  isFull,
  isUrl,
  className,
}: DetailSectionDescListProps) {
  const Value = isUrl ? (
    <a href={val as string} target="_blank" rel="noreferrer">
      {val}
    </a>
  ) : (
    val
  );

  return (
    <S.DescList isFull={isFull} className={className}>
      <dt>{label}</dt>
      <dd>{Value}</dd>
    </S.DescList>
  );
};
