import React, { FunctionComponent, HTMLProps } from 'react';

import { ButtonLayout } from './BaseBtn.styled';

export interface ButtonLayoutProps {
  buttonType: 'primary' | 'ghost' | 'outline';
  isFull?: boolean;
  className?: string;
}

export interface ButtonProps extends HTMLProps<HTMLButtonElement>, ButtonLayoutProps {}

const BaseBtn: FunctionComponent<ButtonProps> = ({
  children,
  buttonType,
  isFull = false,
  className,
}) => {
  return (
    <ButtonLayout buttonType={buttonType} isFull={isFull} className={className}>
      {children}
    </ButtonLayout>
  );
};

export default BaseBtn;
