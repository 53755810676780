import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Radio from '../radio/Radio.component';

export type CalendarBoxProps = {
  label?: string;
  className?: string;
  children: React.ReactNode;
  addRadioBoxRender: () => JSX.Element;
};

function CalendarBox({ label, children, addRadioBoxRender, className }: CalendarBoxProps) {
  return (
    <RootStyle>
      <Label>{label}</Label>
      <CalendarWrapperStyle className={className}>
        {addRadioBoxRender && addRadioBoxRender()}
        <CalendarBoxStyle>{children}</CalendarBoxStyle>
      </CalendarWrapperStyle>
    </RootStyle>
  );
}

export default CalendarBox;

interface RadioListProps {
  name: string;
  radios: Array<{ label: string; value: string }>;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

CalendarBox.RadioBox = function RadioBox({ name, radios, value, onChange }: RadioListProps) {
  return (
    <RadioList>
      {radios.map((radio) => (
        <li key={radio.value}>
          <Radio
            id={radio.value}
            name={name}
            label={radio.label}
            value={radio.value}
            checked={radio.value === value}
            onChange={onChange}
          />
        </li>
      ))}
    </RadioList>
  );
};

const RootStyle = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  ${({ theme }) => css`
    ${theme.fonts.medium15};
    position: relative;
    display: flex;
    align-items: flex-start;
    min-width: 160px;
    width: max-content;
    margin-bottom: 4px;
    color: ${theme.colors.gray70};
  `}
`;

const CalendarWrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 570px;
  row-gap: 12px;
`;

const CalendarBoxStyle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  width: 570px;
`;

const RadioList = styled.ul`
  display: flex;
  column-gap: 28px;
`;
