import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useImmer } from 'use-immer';

import { InputContext } from 'components/common';
import * as S from './Filter.styled';

interface MemberFilter {
  sms: boolean;
  email: boolean;
  [index: string]: string | boolean;
}

const INIT_STATE: MemberFilter = {
  sms: false,
  email: false,
};

interface FilterProps {
  setScrapCnt: React.Dispatch<React.SetStateAction<number>>;
  setViewCnt: React.Dispatch<React.SetStateAction<number>>;
  className?: string;
}

const MemberFilterCheckList: Array<{ label: string; key: string }> = [
  { label: 'SMS', key: 'receiveSms' },
  { label: 'E-mail', key: 'receiveEmail' },
];

function Filter({ setScrapCnt, setViewCnt, className }: FilterProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [form, setForm] = useImmer<MemberFilter>(INIT_STATE);

  const handleChangeForm = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      let value: string | boolean = e.target.value;

      value = e.target.checked;

      setForm((draft) => {
        draft[name as keyof MemberFilter] = value;
      });
    },
    [setForm],
  );

  const onSubmit = useCallback(() => {
    const isPageStepped = searchParams.get('page') && +(searchParams.get('page') as string) > 1;
    const { receiveSms, receiveEmail } = form;

    const smsBoolean = receiveSms ? 'y' : 'n';
    const emailBoolean = receiveEmail ? 'y' : 'n';

    isPageStepped
      ? setSearchParams({
          ...Object.fromEntries(searchParams),
          receiveSms: smsBoolean,
          receiveEmail: emailBoolean,
          page: '1',
        })
      : setSearchParams({
          ...Object.fromEntries(searchParams),
          receiveSms: smsBoolean,
          receiveEmail: emailBoolean,
        });
  }, [form, searchParams, setSearchParams]);

  const onReset = useCallback(() => {
    setForm(INIT_STATE);
    setScrapCnt(0);
    setViewCnt(0);
    setSearchParams({});
  }, [setForm, setScrapCnt, setSearchParams, setViewCnt]);

  return (
    <S.RootStyle className={className}>
      <S.DetailFilterContainer>
        <S.FilterLabel>광고성 정보 수신 동의</S.FilterLabel>
        <InputContext.CheckList
          data={form}
          direction="row"
          checkList={MemberFilterCheckList}
          onChange={handleChangeForm}
        />
      </S.DetailFilterContainer>
      <S.BtnGroup>
        <S.ResetBtn
          size="smd"
          type="button"
          label="필터 초기화"
          variant="secondary"
          onClick={onReset}
        />
        <S.ConfirmBtn label="검색" size="smd" variant="primary" onClick={onSubmit} />
      </S.BtnGroup>
    </S.RootStyle>
  );
}

export default Filter;
