export const WEEK_ARR = ['일', '월', '화', '수', '목', '금', '토'];

export const MONTH = Object.freeze({
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sept',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
});

export const YEAR_ARR = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022];

export const VALID_MSG = Object.freeze({
  REQUIRED: '필수 입력입니다.',
  TIME: '올바른 시간을 입력해 주세요',
  EMAIL: '이메일 형식이 올바르지 않습니다.',
  PHONE: '올바른 휴대폰 번호를 입력해 주세요.',
  BIRTH: '올바른 생년월일을 입력해 주세요.',
  URL: '올바른 주소를 입력해 주세요.',
});
