import { useCallback, useEffect, RefObject } from 'react';

const useKeyTrap = (ref: RefObject<HTMLElement>, handleClose: () => void) => {
  const handleKeyTrap = useCallback(
    (e: KeyboardEvent) => {
      if (!ref.current) {
        return;
      }
      const focusableNodeList = ref.current.querySelectorAll(
        '[href], [tabIndex], button:not([disabled]), input:not([disabled]), textarea, select',
      );
      const shiftKey = e.shiftKey;
      const eventTarget = e.target;
      const firstFocusableNdoe = focusableNodeList[0] as HTMLElement;
      const lastFocusableNode = focusableNodeList[focusableNodeList.length - 1] as HTMLElement;
      const isFirstFocusableNode = Object.is(eventTarget, firstFocusableNdoe);
      const isLastFocusableNode = Object.is(eventTarget, lastFocusableNode);

      if (shiftKey && isFirstFocusableNode) {
        e.preventDefault();
        lastFocusableNode.focus();
      }
      if (!shiftKey && isLastFocusableNode) {
        e.preventDefault();
        firstFocusableNdoe.focus();
      }
    },
    [ref],
  );

  useEffect(() => {
    const keyListenerMap = new Map([
      [27, handleClose],
      [9, handleKeyTrap],
    ]);

    const handleKeyListener = (e: KeyboardEvent) => {
      const listener = keyListenerMap.get(e.keyCode);
      typeof listener === 'function' && listener(e);
    };

    window.addEventListener('keydown', handleKeyListener);

    return () => {
      window.removeEventListener('keydown', handleKeyListener);
    };
  }, [handleClose, handleKeyTrap]);
};

export default useKeyTrap;
