import { useState, useCallback, useRef, useEffect } from 'react';

import { useOnClickOutside } from 'hooks';

const useDialog = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dialogOpener, setDialogOpener] = useState<Element | null>(null);
  const dialogRef = useRef<HTMLDialogElement>(null);

  const handleDialogOpen = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setDialogOpener(document.activeElement);
    setIsOpen(true);
    setTimeout(() => dialogRef.current?.focus());
  }, []);

  const handleDialogClose = useCallback(() => {
    setIsOpen(false);
    dialogOpener && (dialogOpener as HTMLElement).focus();
  }, [dialogOpener]);

  const handleToggleDialog = useCallback(
    (event: React.MouseEvent) => {
      isOpen ? handleDialogClose() : handleDialogOpen(event);
    },

    [handleDialogOpen, handleDialogClose, isOpen],
  );

  useOnClickOutside(dialogRef, handleDialogClose, dialogOpener as HTMLElement);

  useEffect(() => {
    const keyListenerMap = new Map([[27, handleDialogClose]]);

    const handleKeyListener = (event: KeyboardEvent) => {
      const listener = keyListenerMap.get(event.keyCode);
      typeof listener === 'function' && listener();
    };

    window.addEventListener('keydown', handleKeyListener);

    return () => {
      window.removeEventListener('keydown', handleKeyListener);
    };
  }, [handleDialogClose]);

  return {
    isOpen,
    dialogRef,
    handleDialogOpen,
    handleDialogClose,
    handleToggleDialog,
    setIsOpen,
  };
};

export default useDialog;
