import { PATH } from 'constants/path';

export const navMenuList = {
  header: [
    { label: '지원사업 관리', href: PATH.root },
    { label: '회원 관리', href: PATH.user },
  ],
};

const NAV_LIST = [
  {
    navigatePath: PATH.root,
    includePath: [PATH.root, PATH.support],
    content: '지원사업 관리',
  },
  {
    navigatePath: PATH.user,
    includePath: [PATH.user],
    content: '회원 관리',
  },
];

export { NAV_LIST };
