import { useCallback } from 'react';
import {
  useFieldArray,
  useFormContext,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import { VALID_MSG } from 'constants/common';
import { SupportFormModel } from 'types/support';
import { KeyOf } from 'types';
import { priceFormControl } from 'utils/helper';

type OnChangeActionFnType = UseFormClearErrors<SupportFormModel>;

const defaultRegisterOptions = (
  name: KeyOf<SupportFormModel>,
  clearErrors: UseFormClearErrors<SupportFormModel>,
) => ({
  required: VALID_MSG.REQUIRED,
  setValueAs: (value: string) => value.trim(),
  onChange: () => {
    clearErrors(name);
  },
});

export const amountRegisterOptions = (
  name: KeyOf<SupportFormModel>,
  setValue: UseFormSetValue<SupportFormModel>,
  clearErrorFn: OnChangeActionFnType,
) => ({
  ...defaultRegisterOptions(name, clearErrorFn),
  onChange: (e: React.SyntheticEvent) => priceFormControl(e, setValue, clearErrorFn),
});

export const SupportFormObj = (
  register: UseFormRegister<SupportFormModel>,
  clearErrors: UseFormClearErrors<SupportFormModel>,
) => ({
  title: register('title', {
    maxLength: 60,
    ...defaultRegisterOptions('title', clearErrors),
  }),
  to: register('to', {
    maxLength: 50,
    ...defaultRegisterOptions('to', clearErrors),
  }),
  content: register('content', {
    maxLength: 1000,
    ...defaultRegisterOptions('content', clearErrors),
  }),
  institution: register('institution', {
    maxLength: 50,
    ...defaultRegisterOptions('institution', clearErrors),
  }),
  method: register('method', {
    maxLength: 60,
    ...defaultRegisterOptions('method', clearErrors),
  }),
  url: register('url', {
    pattern: {
      value:
        /* eslint-disable no-useless-escape */
        /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim,
      message: VALID_MSG.URL,
    },
    ...defaultRegisterOptions('url', clearErrors),
  }),
  contact: (index: number) =>
    register(`contact.${index}.name`, {
      maxLength: 60,
      required: index === 0 && '필수 입력입니다.',
      setValueAs: (value: string) => value.trim(),
      onChange: () => {
        clearErrors(`contact.${index}.name`);
      },
    }),
});

const useSupportFormRegister = () => {
  const {
    watch,
    register,
    clearErrors,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<SupportFormModel>();

  const { fields, append, remove } = useFieldArray({
    name: 'contact',
  });

  const amountType = watch('amountType') ?? 'amount';
  const amountRegister = register(
    'amount',
    amountType === 'amount'
      ? amountRegisterOptions('amount', setValue, clearErrors)
      : defaultRegisterOptions('amount', clearErrors),
  );

  const handlePriceRadioGroup = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target as HTMLInputElement;
      if (amountType !== value) {
        setValue('amount', '');
        setValue('amountType', value as 'amount' | 'etc');
      }
    },
    [amountType, setValue],
  );

  return {
    fields,
    errors,
    control,
    amountType,
    amountRegister,
    watch,
    append,
    remove,
    register,
    setValue,
    clearErrors,
    handlePriceRadioGroup,
  };
};

export default useSupportFormRegister;
