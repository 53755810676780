import { useQuery } from 'react-query';

import { MemberQueriesModel } from 'types/member';
import { downloadMemberExcel, loadMemberList } from 'api/member';

const MEMBER_KEYS = {
  all: ['support'] as const,
  lists: () => [...MEMBER_KEYS.all, 'list'] as const,
  list: (filters: MemberQueriesModel) => [...MEMBER_KEYS.lists(), { filters }] as const,
  excel: () => [...MEMBER_KEYS.all, 'excel'] as const,
};

export const useGetMemberList = (filters: MemberQueriesModel) =>
  useQuery(MEMBER_KEYS.list(filters), () => loadMemberList(filters));

export const useGetMemberExcel = (options?: object) =>
  useQuery(MEMBER_KEYS.excel(), () => downloadMemberExcel(), {
    ...options,
  });
