import { css } from '@emotion/react';
import styled from '@emotion/styled';
import DefaultButton from 'components/common/button/defaultBtn/DefaultBtn.component';
import ImageUploadContainer from 'components/common/button/imageBtn/ImageBtn.component';
import DateInput from 'components/common/input/dateInput/DateInput';

import FormInput from 'components/common/input/formInput/FormInput.component';
import InputContext from 'components/common/input/inputContext/InputContext.component';

export const CustomRegionSelector = styled(InputContext.RegionSelector)`
  width: 570px;

  & > div {
    top: 52px;
    width: 570px;
  }

  & > button {
    height: 44px;
    border-radius: 0;
  }
`;

export const CustomFormInput = styled(FormInput)`
  & > div > div > div > input {
    width: 570px;
  }

  & > div > div > input {
    width: 570px;
  }

  & > div > input {
    width: 570px;
  }
`;

export const AmountBox = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
`;

export const CustomDropdown = styled(InputContext.Dropdown)`
  width: 100px;
  height: 44px;

  & > button {
    height: 44px;
    border-radius: 0;
  }
`;

export const InquiriesBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 40px;
  width: 100%;
`;

export const InquiriesInputWrapper = styled.div`
  position: relative;
  width: 730px;

  & > button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
  }
`;

interface InquiriesInputBtnProps {
  show?: boolean;
}

export const InquiriesInputBtn = styled(DefaultButton)<InquiriesInputBtnProps>`
  ${({ theme, show }) => css`
    ${theme.fonts.medium15};
    width: 570px;
    border: 1px dashed ${theme.colors.gray30};
    border-radius: 0;
    display: ${show ? 'block' : 'none'};
    margin-left: 160px;
    background-color: #fff;
  `}
`;

export const CalendarBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  width: 570px;
  margin-left: 160px;
`;

export const StartDateInput = styled(DateInput)``;
export const EndDateInput = styled(DateInput)``;

export const CustomFileUpload = styled(ImageUploadContainer)`
  width: 114px;
`;

export const CustomInputContext = styled(InputContext)`
  width: 100px;
  height: 44px;
`;
