import React from 'react';
import { Link } from 'react-router-dom';

import { PATH } from 'constants/path';
import { Icon } from 'components';
import * as S from './Custom404.styled';

const Custom404 = () => {
  return (
    <S.ErrorContainer>
      <Icon name="warningIcon" />
      <S.Title>페이지를 찾을 수 없습니다.</S.Title>
      <S.TextWrapper>
        <S.Text>
          요청하신 페이지의 주소가 잘못되었거나,
          <S.Br /> 주소 변경 또는 삭제로 사용하실 수 없습니다.
        </S.Text>
        <S.Text>입력하신 페이지의 주소가 정확한지 다시 한번 확인해 주세요.</S.Text>
      </S.TextWrapper>
      <Link to={PATH.root}>
        <S.Anchor>홈으로 이동</S.Anchor>
      </Link>
    </S.ErrorContainer>
  );
};

export default Custom404;
