export const numericOnly = (value?: string): string => {
  if (value) {
    const regex = /[^0-9]/g;
    return value.replaceAll(regex, '');
  } else {
    return '';
  }
};

export const checkFirstNum = (value: string): string => {
  if (value === '0') {
    return value;
  }

  if (typeof value === 'string' && value[0] === '0') {
    value = value.slice(1);
  }
  return value;
};

export const formatPrice = (number: string) => {
  const comma = (str: string) => {
    str = String(str);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  };

  const unComma = (str: string) => {
    str = String(str);
    return str.replace(/[^\d]+/g, '');
  };

  const pureString = number && comma(unComma(number));
  return pureString;
};

export const numericOnlyWithColons = (v: string) => {
  // eslint-disable-next-line
  const regex = /[^0-9\:]/g;

  return v.replaceAll(regex, '');
};

export const parseBillionUnitStr = (amount: string) => {
  const inputNumber = +numericOnly(amount);
  const unitWords = ['만', '억', '조', '경'];
  const splitUnit = 10000;
  const splitCount = unitWords.length;
  const resultArray = [];
  let resultString = '';

  for (let i = 0; i < splitCount; i++) {
    let unitResult = (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
    unitResult = Math.floor(unitResult);
    if (unitResult > 0) {
      resultArray[i] = unitResult;
    }
  }

  for (let i = 0; i < resultArray.length; i++) {
    if (!resultArray[i]) continue;
    resultString = String(resultArray[i]) + unitWords[i] + resultString;
  }

  return resultString;
};

export const formatPeriodPlaceholder = (type: string) => {
  switch (type) {
    case 'date':
      return '마감일시 (필수)';
    case 'always':
    case 'spent':
      return '마감일시';
    case 'first_come':
      return '마감일시 (선택)';
  }
};
