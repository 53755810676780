/* eslint-disable no-case-declarations */
import { selector } from 'recoil';
import { getCommonListApi } from 'api/common';
import Region from 'utils/region';

interface CityModel {
  cityList: { cityId: number; regionId: number; name: string }[];
  regionList: {
    regionId: number;
    name: string;
  }[];
}

interface BusinessItemModel {
  businessItemList: {
    itemId: number;
    name: string;
  }[];
}

interface BusinessTypeModel {
  businessTypeList: {
    typeId: number;
    name: string;
  }[];
}

interface AreaModel {
  areaList: {
    areaId: number;
    name: string;
  }[];
}

interface AgeModel {
  ageList: {
    ageId: number;
    name: string;
  }[];
}

interface PeriodModel {
  periodList: {
    periodId: number;
    name: string;
  }[];
}

export interface CommonStateModel {
  city: CityModel;
  businessItem: BusinessItemModel;
  businessType: BusinessTypeModel;
  area: AreaModel;
  age: AgeModel;
  period: PeriodModel;
}

export const COMMON_KEYS = {
  city: 'city',
  businessItem: 'business/item',
  businessType: 'business/type',
  area: 'area',
  age: 'age',
  period: 'period',
};

export const commonCitySelector = selector({
  key: 'common/cityState',
  get: async () => {
    const res = await getCommonListApi<CityModel>(COMMON_KEYS.city);
    return new Region(res);
  },
});

export const commonAgeSelector = selector({
  key: 'common/ageState',
  get: async () => {
    const res = await getCommonListApi<AgeModel>(COMMON_KEYS.age);
    return res.ageList.reduce(
      (acc, cur) => ({ ...acc, [cur.ageId]: cur.name }),
      {} as { [key: number]: string },
    );
  },
});

export const commonPeriodSelector = selector({
  key: 'common/periodState',
  get: async () => {
    const res = await getCommonListApi<PeriodModel>(COMMON_KEYS.period);
    return res.periodList.reduce(
      (acc, cur) => ({ ...acc, [cur.periodId]: cur.name }),
      {} as { [key: number]: string },
    );
  },
});

export const commonAreaSelector = selector({
  key: 'common/areaState',
  get: async () => {
    const res = await getCommonListApi<AreaModel>(COMMON_KEYS.area);
    return res.areaList.reduce(
      (acc, cur) => ({ ...acc, [cur.areaId]: cur.name }),
      {} as { [key: number]: string },
    );
  },
});

export const commonBusinessItemSelector = selector({
  key: 'common/businessItemState',
  get: async () => {
    const res = await getCommonListApi<BusinessItemModel>(COMMON_KEYS.businessItem);
    return res.businessItemList.reduce(
      (acc, cur) => ({ ...acc, [cur.itemId]: cur.name }),
      {} as { [key: number]: string },
    );
  },
});

export const commonBusinessTypeSelector = selector({
  key: 'common/businessTypeState',
  get: async () => {
    const res = await getCommonListApi<BusinessTypeModel>(COMMON_KEYS.businessType);
    return res.businessTypeList.reduce(
      (acc, cur) => ({ ...acc, [cur.typeId]: cur.name }),
      {} as { [key: number]: string },
    );
  },
});
