/* eslint-disable @typescript-eslint/ban-types */
import React, { ForwardedRef, forwardRef } from 'react';
import dayjs from 'dayjs';

import { Radio } from 'components';
import { periodRadioFilterList } from 'assets/static/support';
import * as S from './Period.styled';

export type PeriodProps = {
  className?: string;
  closeDate?: string;
  periodMethod: string;
  handleSelectDate: () => (selectDate: string) => void;
  handlePeriodRadioGroup: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function Period(
  { periodMethod, handlePeriodRadioGroup, closeDate, handleSelectDate, className }: PeriodProps,
  ref: ForwardedRef<{}>,
) {
  return (
    <S.RootStyle className={className}>
      <S.LabelBox>마감일자</S.LabelBox>
      <S.FilterBox>
        <S.RadioBox>
          {periodRadioFilterList.map((radio) => (
            <li key={radio.value}>
              <Radio
                name="period"
                id={radio.value}
                label={radio.label}
                value={radio.value}
                checked={radio.value === periodMethod}
                onChange={handlePeriodRadioGroup}
              />
            </li>
          ))}
          <S.CustomDateInput
            ref={ref}
            value={closeDate}
            selectDateType="END"
            placeholder="마감일 입력"
            disabledBefore={dayjs()}
            onChange={handleSelectDate}
            disabled={periodMethod !== 'closeDate'}
          />
        </S.RadioBox>
      </S.FilterBox>
    </S.RootStyle>
  );
}

export default forwardRef(Period);
