import { css } from '@emotion/react';
import styled from '@emotion/styled';
import DateInput from 'components/common/input/dateInput/DateInput';

export const RootStyle = styled.dl`
  display: flex;
  width: 100%;
  height: 48px;
`;

export const LabelBox = styled.dt`
  ${({ theme }) => css`
    ${theme.fonts.medium15};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 1;
    min-width: 210px;
    height: 100%;
    background-color: ${theme.colors.gray10};
  `}
`;

export const FilterBox = styled.dd`
  flex-grow: 1;
  height: 100%;
  padding: 12px 20px;
`;

export const RadioBox = styled.ul`
  display: flex;
  align-items: center;
  column-gap: 28px;
  height: 100%;
`;

export const CustomDateInput = styled(DateInput)`
  ${({ theme }) => css`
    width: 200px;
    height: 36px;

    & > input {
      ${theme.fonts.regular14};
      height: 36px;
      border-width: 1px;
      color: ${theme.colors.gray50};
    }

    & > button {
      opacity: 0;
    }
  `}
`;
