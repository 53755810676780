import React, { useCallback } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
dayjs.locale('ko');

import { loadPresignedUrl } from 'api/common';
import useCommonList from 'hooks/useCommonList';
import { downloadFile } from 'utils/helper/download';
import { ManagementDetailModel } from 'types/support';
import { PeriodRadioObj } from 'assets/static/support';
import DetailSection from '../DetailSection.component';
import * as S from './Detail.styled';

export type DetailProps = {
  data: ManagementDetailModel;
  className?: string;
};

function Detail({ data, className }: DetailProps) {
  const { cityState } = useCommonList();
  const regionName = cityState.getTotalName(data?.regionId, data?.cityId);

  const download = useCallback(
    (name: string, url: string) => async () => {
      const fileUrl = await loadPresignedUrl(url, name);
      downloadFile(fileUrl);
    },
    [],
  );

  const FileList = useCallback(() => {
    const res = data?.attachment;

    if (res === null) return '-';
    return (
      <ul>
        {res?.map((item: { name: string; file: string }) => {
          return (
            <li key={item.name}>
              <button type="button" onClick={download(item.name, item.file)}>
                {item?.name ?? '-'}
              </button>
            </li>
          );
        })}
      </ul>
    );
  }, [data?.attachment, download]);

  const ContactList = useCallback(() => {
    const contacts = data?.contact;
    return (
      <ul>
        {contacts?.map((item, idx) => {
          return <li key={idx}>{item}</li>;
        })}
      </ul>
    );
  }, [data?.contact]);

  const PeriodList = useCallback(() => {
    const start = dayjs(data?.startDate).format('YYYY-MM-DD(ddd)');
    let endDate;

    if (data?.includeTime !== 0) {
      endDate = data?.closeDate && dayjs(data?.closeDate).format('YYYY-MM-DD(ddd), HH:mm');
    } else {
      endDate = data?.closeDate && dayjs(data?.closeDate).format('YYYY-MM-DD(ddd)');
    }

    if (
      data?.periodType === 'spent' ||
      data?.periodType === 'always' ||
      (data?.periodType === 'first_come' && data?.closeDate === null)
    ) {
      return (
        <S.PeriodBox>
          <b>{PeriodRadioObj[data?.periodType as keyof typeof PeriodRadioObj]}, </b>
          <p>{start} ~</p>
        </S.PeriodBox>
      );
    } else if (data?.periodType === 'first_come' && data?.closeDate !== null) {
      return (
        <S.PeriodBox>
          <b>{PeriodRadioObj[data?.periodType as keyof typeof PeriodRadioObj]}, </b>
          <p>
            {start} ~ {endDate}
          </p>
        </S.PeriodBox>
      );
    } else {
      return `${start} ~ ${endDate ? endDate : ''}`;
    }
  }, [data?.closeDate, data?.includeTime, data?.periodType, data?.startDate]);

  return (
    <S.RootStyle className={className}>
      <DetailSection.DescList label="지역" val={regionName} />
      <DetailSection.DescList label="지원금액" val={data?.amount} />
      <DetailSection.DescList label="접수 기간" val={PeriodList()} />
      <DetailSection.DescList label="주관 기관" val={data?.institution} />
      <DetailSection.DescList label="문의처" val={ContactList()} isFull />
      <DetailSection.DescList label="접수 방법" val={data?.method} isFull />
      <DetailSection.DescList label="URL" val={data?.url} isFull isUrl />
      <DetailSection.DescList label="첨부파일" val={FileList()} isFull />
    </S.RootStyle>
  );
}

export default Detail;
