/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { auth } from 'context/AuthProvider';
import { TokenService } from 'utils/helper/tokenService';

const tokenService = new TokenService(auth);

export const BASE_URL = process.env.REACT_APP_BASE_URL;
const instance = axios.create({
  baseURL: BASE_URL,
});

const createInstance = () => {
  return setInterceptors(instance);
};

const setInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(onRequest, onRequestError);
  instance.interceptors.response.use(onResponse, onResponseError);
  return instance;
};

const onRequest = (config: AxiosRequestConfig) => {
  if (!config) {
    config = {};
  }
  if (!config.headers) {
    config.headers = {};
  }
  const accessToken = tokenService.getAccessToken();

  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
};

const onRequestError = (err: AxiosError) => {
  return Promise.reject(err);
};

const onResponse = (response: AxiosResponse) => {
  return response;
};

const onResponseError = async (err: AxiosError<any>): Promise<unknown> => {
  const { response: errorResponse } = err;
  if (errorResponse?.data.code === 'ACCESS_TOKEN_EXPIRED') {
    return tokenService.resetTokenAndReattemptRequest(err);
  }

  const authErrorCode = ['ALREADY_LOGIN', 'NEED_LOGIN'];
  if (authErrorCode.includes(errorResponse?.data.code)) {
    tokenService.expireSession();
  }

  return Promise.reject(err);
};

export const ax = createInstance();

export default ax;
