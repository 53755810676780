import { v4 } from 'uuid';

export const getOneDepthPath = (path: string): string => {
  const p = path.split('/').filter(Boolean)[0] ?? '';
  return `/${p}`;
};

export const getPresignedKey = (ext: string) => `${v4()}.${ext}`;

export const parseFileExt = (fileType: string): string => {
  return fileType.split('/')[1];
};

export function parseFileName(fileName: string): string {
  return fileName.split('.').slice(0, -1).join('.');
}

export const parseFileExtByAbs = (fileName: string): string => {
  return fileName.split('.').slice(-1).join('.');
};

export const downloadFile = (file: Blob, fileName: string) => {
  const URL = window.URL ?? window.webkitURL;
  const downloadUrl = URL.createObjectURL(file);
  download(downloadUrl, fileName);
};

export const download = (url: string, name: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 100);
};
