import React from 'react';

import { Icon } from 'components/icon';
import useModal from 'hooks/useModal';

import * as S from './CloseModal.styled';

interface DeleteModalProps {
  desc: string;
  confirmString: string;
  title?: string;
  onClick: (closeModal: () => void) => () => void;
}

function CloseModal({ title, desc, confirmString, onClick }: DeleteModalProps) {
  const { closeModal } = useModal();

  return (
    <S.RootStyle role="dialog" aria-live="assertive" aria-modal="true">
      <S.Container>
        <S.CloseBtn onClick={closeModal}>
          <Icon name="close" />
        </S.CloseBtn>
        <h1>{title}</h1>
        <S.Description>{desc}</S.Description>
        <S.BtnGroup>
          <S.Button onClick={closeModal}>취소</S.Button>
          <S.Button isDelete onClick={onClick(closeModal)}>
            {confirmString}
          </S.Button>
        </S.BtnGroup>
      </S.Container>
    </S.RootStyle>
  );
}

export default CloseModal;
