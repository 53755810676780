import React from 'react';

import * as S from './Image.styled';

export interface ImageProps {
  src: string;
  alt: string;
  className?: string;
}

function Image({ src, alt, className }: ImageProps) {
  return (
    <S.CoverImg className={className}>
      <img src={src} alt="" />
      <S.ImgCaption>{alt}</S.ImgCaption>
    </S.CoverImg>
  );
}

export default Image;
