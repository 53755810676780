import { useCallback, useEffect, useRef, useState, useLayoutEffect } from 'react';
import { useImmer } from 'use-immer';
import dayjs from 'dayjs';

import { ErrorForm } from 'types';
import { ManagementDetailModel, SupportSpecificForm } from 'types/support';
import { VALID_MSG } from 'constants/common';
import useCommonList from 'hooks/useCommonList';
import { useToast } from 'hooks';

const SUPPORT_SPECIFIC_FORM_INIT_STATE: SupportSpecificForm = {
  ageIds: [],
  periodId: '',
  regionId: '',
  typeIds: [],
  areaIds: [],
  attachment: [],
  endDate: undefined,
  startDate: dayjs().format('YYYY-MM-DD'),
};

const SUPPORT_SPECIFIC_FORM_ERROR_INIT_STATE: ErrorForm<SupportSpecificForm> = {
  ageIds: '',
  areaIds: '',
  typeIds: '',
  periodId: '',
  regionId: '',
  endDate: '',
  startDate: '',
  attachment: '',
};

const useSupportForm = (data?: ManagementDetailModel) => {
  const inputRef = useRef({});
  const [form, setForm] = useImmer(SUPPORT_SPECIFIC_FORM_INIT_STATE);
  const [error, setError] = useImmer<ErrorForm<SupportSpecificForm>>(
    SUPPORT_SPECIFIC_FORM_ERROR_INIT_STATE,
  );
  const toast = useToast();

  const handleDeleteImage = useCallback(
    (index: number) => {
      setForm((draft) => {
        draft['attachment'] = (draft['attachment'] as []).filter((_, idx) => idx !== index);
      });
    },
    [setForm],
  );

  const handleAttachment = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
      const name = event?.target?.name ?? key;
      const files = event?.target?.files ?? name;
      const newFileList = [...(form['attachment'] as []), ...Object.entries(files)];

      switch (name) {
        case 'attachment':
          if (files.length === 0) return;
          if (newFileList.length > 3) {
            for (let i = newFileList.length; i > 3; i--) {
              newFileList.pop();
            }
            toast('파일은 최대 3개까지 추가할 수 있습니다 :)');
          }

          newFileList.map((item) => {
            if (Array.isArray(item)) {
              const file = item[1];
              const { size } = file;
              if (!file) return;
              if (size <= 99999999999999) {
                setForm((draft) => {
                  draft['attachment'] = [...(draft['attachment'] as []), file];
                });
                setError((draft) => {
                  draft['attachment'] = '';
                });
              }
            }
          });
          break;
      }
    },
    [form, setError, setForm, toast],
  );

  const handleBlurAttachment = useCallback(() => {
    setError((draft) => {
      draft['attachment'] = VALID_MSG.REQUIRED;
    });
  }, [setError]);

  const [periodStatus, setPeriodMethod] = useState('date');

  const handlePeriodRadioGroup = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target as HTMLInputElement;
      if (periodStatus !== value) setPeriodMethod(value);
    },
    [periodStatus],
  );

  // ------------------ Area -------------------------------------

  const handleChangeAreaList = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = e.target;
      setForm((draft) => {
        if (checked) {
          draft['areaIds'].push(value);
        } else {
          draft['areaIds'] = draft.areaIds.filter((v) => v !== value);
        }
      });
      setError((draft) => {
        draft['areaIds'] = '';
      });
    },
    [setForm, setError],
  );

  const handleBlurArea = useCallback<React.FocusEventHandler<HTMLButtonElement>>(() => {
    if (form['areaIds'].length === 0) {
      setError((draft) => {
        draft['areaIds'] = VALID_MSG.REQUIRED;
      });
    }
  }, [form, setError]);

  // ------------------ BusinessType -------------------------------------

  const handleChangeBusinessType = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = e.target;
      setForm((draft) => {
        if (checked) {
          draft['typeIds'].push(value);
        } else {
          draft['typeIds'] = draft.typeIds.filter((v) => v !== value);
        }
      });
      setError((draft) => {
        draft['typeIds'] = '';
      });
    },
    [setForm, setError],
  );

  const handleBlurBusinessType = useCallback<React.FocusEventHandler<HTMLButtonElement>>(() => {
    if (form['typeIds'].length === 0) {
      setError((draft) => {
        draft['typeIds'] = VALID_MSG.REQUIRED;
      });
    }
  }, [form, setError]);

  // ------------------ AgeType -------------------------------------

  const handleSelectAge = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = e.target;
      setForm((draft) => {
        if (checked) {
          draft['ageIds'].push(value);
        } else {
          draft['ageIds'] = draft.ageIds.filter((v) => v !== value);
        }
      });
      setError((draft) => {
        draft['ageIds'] = '';
      });
    },
    [setForm, setError],
  );

  const handleBlurAge = useCallback<React.FocusEventHandler<HTMLButtonElement>>(() => {
    if (form['ageIds'].length === 0) {
      setError((draft) => {
        draft['ageIds'] = VALID_MSG.REQUIRED;
      });
    }
  }, [form, setError]);

  // ------------------ PeriodType -------------------------------------

  const handleSelectPeriod = useCallback(
    (opt: string) => {
      setForm((draft) => {
        draft['periodId'] = opt;
      });
      setError((draft) => {
        draft['periodId'] = '';
      });
    },
    [setForm, setError],
  );

  const handleBlurPeriod = useCallback<React.FocusEventHandler<HTMLButtonElement>>(() => {
    if (form['periodId'] === '') {
      setError((draft) => {
        draft['periodId'] = VALID_MSG.REQUIRED;
      });
    }
  }, [form, setError]);

  // ------------------ Region -------------------------------------

  const { cityState } = useCommonList();
  const [cityName, setCityName] = useState('');
  const [regionState, setRegionState] = useState({ regionId: 1, cityId: 1 });

  const handleSelectDate = useCallback(
    () => (selectDate: string, type?: 'START' | 'END') => {
      if (type === 'START') {
        const isReservationDateBeforeToday =
          dayjs(selectDate).diff(dayjs(form.endDate), 'minute') > 0;

        if (isReservationDateBeforeToday) {
          setForm((draft) => {
            draft['startDate'] = selectDate;
          });
          setForm((draft) => {
            draft['endDate'] = dayjs(form.endDate).add(1, 'day').format('YYYY-MM-DD HH:mm');
          });
        } else {
          setForm((draft) => {
            draft['startDate'] = selectDate;
          });
        }
      } else {
        setForm((draft) => {
          draft['endDate'] = selectDate;
        });
      }
    },
    [form.endDate, setForm],
  );

  const [isActiveTime, setIsActiveTime] = useState(true);
  const handleClickActiveTime = useCallback(() => {
    setIsActiveTime((prev) => !prev);
  }, []);

  const [amountPrefix, setAmountPrefix] = useState('1');

  const handleAmountChange = useCallback((opt: string) => {
    setAmountPrefix(opt);
  }, []);

  useEffect(() => {
    if (periodStatus === 'always' || periodStatus === 'spent') {
      setForm((draft) => {
        draft['endDate'] = null;
      });
    }
    if (cityName !== '') {
      setError((draft) => {
        draft.regionId = '';
      });
    }
  }, [cityName, periodStatus, setError, setForm]);

  useLayoutEffect(() => {
    if (data) {
      setPeriodMethod(data?.periodType);
      setIsActiveTime(data?.includeTime === 1 ? true : false);
      setCityName(cityState.getTotalName(data?.regionId, data?.cityId));
      setRegionState({ regionId: data?.regionId, cityId: data?.cityId });
      setForm((draft) => {
        draft.startDate = dayjs(data?.startDate).format('YYYY-MM-DD');
        draft.endDate = data?.closeDate
          ? dayjs(data?.closeDate).format('YYYY-MM-DD HH:mm')
          : undefined;
        draft.areaIds = data?.areaIds?.map((item) => item.toString());
        draft.ageIds = data?.ageIds?.map((item) => item.toString()) ?? [];
        draft.periodId = data?.periodId.toString();
        draft.attachment = data?.attachment ?? [];
        draft.typeIds = data?.typeIds?.map((item) => String(item)) ?? [];
      });
    }
  }, [cityState, data, setForm]);

  return {
    form,
    error,
    cityName,
    inputRef,
    regionState,
    periodStatus,
    isActiveTime,
    amountPrefix,
    setForm,
    setError,
    setCityName,
    handleBlurAge,
    handleBlurArea,
    setRegionState,
    handleSelectAge,
    handleSelectDate,
    handleBlurPeriod,
    handleSelectPeriod,
    handleAmountChange,
    handleChangeAreaList,
    handlePeriodRadioGroup,
    handleChangeBusinessType,
    handleBlurBusinessType,
    handleClickActiveTime,
    handleAttachment,
    handleDeleteImage,
    handleBlurAttachment,
  };
};

export default useSupportForm;
