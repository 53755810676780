import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Avatar = styled.div`
  ${({ theme }) => css`
    ${theme.fonts.medium14};
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 12px;
    border-radius: 50%;
    color: ${theme.colors.gray70};

    & > figure {
      width: 28px;
      height: 28px;
    }
  `}
`;
