export const addressInfo = [
  { title: '주소', desc: '서울특별시 성북구 화랑로14길 5, 에이치-1 904호(하월곡동)' },
  { title: '대표전화', desc: '02-564-0309' },
  { title: '팩스', desc: '02-6442-2440' },
  { title: '청소년보호책임자', desc: '안강엽' },
];

export const copyright = 'Copyright ⓒ 2022 STARTUP GUIDE. All Rights Reserved.';

export const outbounds = [
  {
    label: '매체소개',
    link: '',
  },
  {
    label: '개인정보 처리방침',
    link: '',
  },
  {
    label: '이용약관',
    link: '',
  },
  { label: '청소년보호정책', link: '' },
];
