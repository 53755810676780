import React from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';

import { AuthLayout, BaseLayout } from 'components';
import { Login, NotFound, Support, User } from 'pages';
import { SupportForm, SupportDetail } from 'pages/support';
import { PATH } from 'constants/path';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path={PATH.login} element={<Login />} />
          <Route path={PATH.notFound} element={<NotFound />} />
        </Route>
        <Route element={<BaseLayout />}>
          <Route index element={<Support />} />
          <Route path={PATH.supportCreate} element={<SupportForm />} />
          <Route path={PATH.supportUpdate} element={<SupportForm />} />
          <Route path={PATH.supportDetail} element={<SupportDetail />} />
          <Route path={PATH.user} element={<User />} />
        </Route>
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
