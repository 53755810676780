import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Header = styled.header`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid ${theme.colors.gray20};
    background-color: ${theme.colors.white};
    z-index: ${theme.zIndex.header};
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: ${theme.size.container.width};
    height: 100%;

    & > p {
      justify-self: flex-end;
      color: ${theme.colors.gray60};
      cursor: pointer;
    }

    @media ${theme.breakPoint.device.desktop} {
      display: grid;
      grid-template-columns: 144px 1fr 120px;
      width: ${theme.size.container.width};
      margin: 0 auto;
    }
  `}
`;

export const LogoImgBox = styled.button`
  ${({ theme }) => css`
    position: relative;
    width: 144px;
    height: 32px;

    & > svg {
      fill: ${theme.colors.black};
    }
  `}
`;

export const LoginBtn = styled.button`
  ${({ theme }) => css`
    ${theme.button.variant.primary};
    ${theme.button.size.xs};

    @media ${theme.breakPoint.device.desktop} {
      display: none;
    }
  `}
`;

export const MenuBtn = styled.button`
  ${({ theme }) => css`
    width: 28px;
    height: 28px;
    padding: 4px;

    & > svg {
      width: 20px;
      height: 20px;
      fill: ${theme.colors.gray50};
    }

    @media ${theme.breakPoint.device.desktop} {
      display: none;
    }
  `}
`;
