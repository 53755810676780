/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';

import * as S from './ImageBtn.styled';

interface ImageUploadContainerProps {
  id?: string;
  name: string;
  error?: string;
  className?: string;
  imagePreview: File[];
  handleDeleteImage: (index: number) => void;
  handleBlurAttachment: () => void;
  handleAttachment: any;
}

const ImageUploadContainer = ({
  id,
  name,
  error,
  className,
  imagePreview,
  handleDeleteImage,
  handleAttachment,
  handleBlurAttachment,
}: ImageUploadContainerProps) => {
  const isAttachesFullLength = useMemo(() => imagePreview?.length > 2, [imagePreview]);

  const deleteFiles = (index: number) => () => {
    handleDeleteImage(index);
  };

  return (
    <S.RootStyle className={className}>
      {imagePreview?.length > 0 &&
        imagePreview?.map((_, idx) => {
          return (
            <S.PreviewBox key={idx}>
              <p>{imagePreview[idx]?.name}</p>
              <S.IconBtn type="button" onClick={deleteFiles(idx)}>
                <S.CloseIcon name="deleteIcon" />
              </S.IconBtn>
            </S.PreviewBox>
          );
        })}
      <S.UploadBtn disabled={isAttachesFullLength} error={!!error} id="123">
        <label htmlFor="imageUpload">첨부파일 추가</label>
        <input
          multiple
          type="file"
          name={name}
          id="imageUpload"
          accept=".pdf, .hwp, .hwpx, .doc, .xlsx, .docx image/gif, image/jpg, image/jpeg, image/png, image/heic"
          onChange={handleAttachment}
          onBlur={handleBlurAttachment}
        />
      </S.UploadBtn>
      {error && <S.ValidText id={`${id}-errMsg`}>{error}</S.ValidText>}
    </S.RootStyle>
  );
};

export default ImageUploadContainer;
