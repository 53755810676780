/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useSetRecoilState } from 'recoil';

import {
  loadSupportList,
  loadSupportDetail,
  addSupportWithS3,
  updateSupportWithS3,
  deleteSupport,
  closeSupport,
} from 'api/supporting';
import { useToast } from 'hooks';
import { PATH } from 'constants/path';
import { ManagementDetailModel, ManagementQueriesModel, ManagementFormModel } from 'types/support';
import { attachmentKeysState } from 'store/support';

const SUPPORT_KEYS = {
  all: ['support'] as const,
  lists: () => [...SUPPORT_KEYS.all, 'list'] as const,
  list: (filters: ManagementQueriesModel) => [...SUPPORT_KEYS.lists(), { filters }] as const,
  details: () => [...SUPPORT_KEYS.all, 'detail'] as const,
  detail: (id?: string | null) => [...SUPPORT_KEYS.details(), id] as const,
};

export const useGetSupportList = (filters: ManagementQueriesModel, options?: object) =>
  useQuery(SUPPORT_KEYS.list(filters), () => loadSupportList(filters), {
    ...options,
  });

export const fetchS3SupportDetail = async (id?: string | null) => {
  const res = await loadSupportDetail(id);
  const attachmentKeys = res?.supportingInfo?.attachment
    ?.map((item: { name: string; file: string }) => item.file)
    ?.map((item: string) => item.split('/')[1]);

  const confirmResult = { ...res, attachmentKeys };

  return confirmResult;
};

export const useGetSupportDetail = (id?: string | null, options?: object) => {
  const setAttachmentKeys = useSetRecoilState(attachmentKeysState);

  return useQuery(SUPPORT_KEYS.detail(id), () => fetchS3SupportDetail(id), {
    onSuccess: (data) => setAttachmentKeys(data?.attachmentKeys),
    ...options,
  });
};

export const useCreateProduct = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const toast = useToast();

  return useMutation((reqData: ManagementFormModel) => addSupportWithS3(reqData), {
    onSuccess: async () => {
      queryClient.invalidateQueries(SUPPORT_KEYS.lists());
      navigate(PATH.root);
      toast('지원사업 등록이 완료되었습니다.');
    },
  });
};

export const useUpdateProduct = (
  id?: string | null,
  attachmentKeys?: string[],
  isOverwrite?: boolean,
) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const toast = useToast();

  return useMutation(
    (reqData: ManagementDetailModel) =>
      updateSupportWithS3(reqData, id, attachmentKeys, isOverwrite),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(SUPPORT_KEYS.lists());
        navigate(PATH.root);
        toast(isOverwrite ? '지원사업 생성이 완료되었습니다.' : '지원사업 수정이 완료되었습니다.');
      },
    },
  );
};

export const useDeleteProduct = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();

  const toast = useToast();

  return useMutation((ids: string[]) => deleteSupport(ids), {
    onSuccess: async () => {
      queryClient.invalidateQueries(SUPPORT_KEYS.lists());
      if (pathname !== '/') {
        navigate(PATH.root);
      }
      toast('공고가 삭제되었습니다');
    },
  });
};

export const useCloseSupport = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();

  const toast = useToast();

  return useMutation((ids: string[]) => closeSupport(ids), {
    onSuccess: async () => {
      queryClient.invalidateQueries(SUPPORT_KEYS.lists());
      if (pathname !== '/') {
        navigate(PATH.root);
      }
      toast('공고가 마감되었습니다');
    },
  });
};
