import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';

import { ButtonProps } from './BaseBtn.component';

export const primaryButtonStyles = (theme: Theme) => css`
  ${theme.button.size.sm};
  ${theme.button.variant.primary};
`;

export const outlineButtonStyles = (theme: Theme) => css`
  ${theme.button.size.sm};
  ${theme.button.variant.outline};
`;

export const ghostButtonStyles = (theme: Theme) => css`
  ${theme.button.size.sm};
  ${theme.button.variant.ghost};
`;

export const ButtonLayout = styled.button<ButtonProps>`
  width: ${({ isFull }) => (isFull ? '100%' : 'auto')};
  ${({ theme, buttonType }) => {
    switch (buttonType) {
      case 'primary':
        return primaryButtonStyles(theme);
      case 'ghost':
        return ghostButtonStyles(theme);
      case 'outline':
        return outlineButtonStyles(theme);
      default:
        return primaryButtonStyles(theme);
    }
  }}
`;

export default {
  ButtonLayout,
};
