import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const NavItem = styled.li`
  ${({ theme }) => css`
    & > a {
      ${theme.tab.header};
      padding: 10px 20px;
    }
  `}
`;
