import React from 'react';
import { isEmpty } from 'lodash-es';

import { Icon } from 'components/icon';
import { PageInfoModel } from 'types';

import * as S from './Table.styled';
import Pagination from '../pagination/Pagination.component';

interface TableProps {
  title: string;
  pageInfo: PageInfoModel;
  children: React.ReactNode;
  className?: string;
}

interface TableHeadProps {
  viewCnt: number;
  scrapCnt: number;
  clickableThIndex: number[];
  columnArr: { key: string; label: string }[];
  handleClickClickableTh: (name?: string) => () => void;
}

interface TableBodyProps {
  className?: string;
  children: React.ReactNode;
}

interface TableExtraButtonProps {
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
}

const Table = ({ title, pageInfo, children, ...rest }: TableProps) => {
  return (
    <S.TableContainer>
      <S.RootStyle {...rest}>
        <caption className="a11y">{title}</caption>
        {children}
      </S.RootStyle>
      {!isEmpty(pageInfo) && <Pagination pageInfo={pageInfo} />}
    </S.TableContainer>
  );
};

Table.Head = function TableHeader({
  viewCnt,
  scrapCnt,
  columnArr,
  clickableThIndex,
  handleClickClickableTh,
}: TableHeadProps) {
  const ClickableTh = (key: string, label: string, idx: number) => {
    if (clickableThIndex?.includes(idx) && key === 'viewCount') {
      return (
        <S.Th
          key={key}
          scope="col"
          orderFilterClickCnt={viewCnt}
          onClick={handleClickClickableTh(key)}
          isClickable={clickableThIndex?.includes(idx)}
        >
          {label}
          {viewCnt !== 0 ? <Icon name="arrowFill" /> : <Icon name="arrowFlatIcon" />}
        </S.Th>
      );
    } else if (clickableThIndex?.includes(idx) && key === 'scrapeCount') {
      return (
        <S.Th
          key={key}
          scope="col"
          orderFilterClickCnt={scrapCnt}
          onClick={handleClickClickableTh(key)}
          isClickable={clickableThIndex?.includes(idx)}
        >
          {label}
          {scrapCnt !== 0 ? <Icon name="arrowFill" /> : <Icon name="arrowFlatIcon" />}
        </S.Th>
      );
    } else
      return (
        <S.Th
          key={key}
          scope="col"
          orderFilterClickCnt={scrapCnt}
          onClick={handleClickClickableTh(key)}
          isClickable={clickableThIndex?.includes(idx)}
        >
          {label}
        </S.Th>
      );
  };

  return (
    <S.Thead>
      <S.Tr>
        {!clickableThIndex?.length &&
          columnArr.map(({ key, label }) => (
            <S.Th key={key} scope="col">
              {label}
            </S.Th>
          ))}
        {clickableThIndex?.length > 0 &&
          columnArr.map(({ key, label }, idx) => ClickableTh(key, label, idx))}
      </S.Tr>
    </S.Thead>
  );
};

Table.Body = function TableBody({ children, className }: TableBodyProps) {
  return <S.Tbody className={className}>{children}</S.Tbody>;
};

Table.Row = function TableRow({ children, className }: TableBodyProps) {
  return <S.Tr className={className}>{children}</S.Tr>;
};

Table.Cell = function TableData({ children, className, ...rest }: TableBodyProps) {
  return (
    <S.Td className={className} {...rest}>
      {children}
    </S.Td>
  );
};

Table.ExtraButton = function ExtraButton({
  onClick,
  children,
  className,
  ...rest
}: TableExtraButtonProps) {
  return (
    <S.DownloadBtn className={className} onClick={onClick} {...rest}>
      {children}
    </S.DownloadBtn>
  );
};

export default Table;
