import React from 'react';

import * as S from './Switch.styled';

interface SwitchProps {
  children: React.ReactNode | string;
  handleClickActiveTime: () => void;
  isActive?: boolean;
}

export default function Switch({
  children,
  handleClickActiveTime,
  isActive,
  ...restProps
}: SwitchProps) {
  return (
    <S.Root {...restProps}>
      <S.Input id="keepLogin" type="checkbox" checked={isActive} onChange={handleClickActiveTime} />
      <S.Label htmlFor="keepLogin" isActive={isActive} />
      <S.Text htmlFor="keepLogin">{children}</S.Text>
    </S.Root>
  );
}
