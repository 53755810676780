import { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import useCommonList from 'hooks/useCommonList';
import useUpdateEffect from 'hooks/useUpdateEffect';
import { INIT_AREA_TAG, INIT_REGION_TAG } from 'assets/static/support';

interface SearchInputState {
  search: string;
}

const useSupportFilter = (
  localNavTabLabel: string,
  setViewCnt: React.Dispatch<React.SetStateAction<number>>,
  setCloseDateCnt: React.Dispatch<React.SetStateAction<number>>,
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { register, watch, resetField } = useForm<SearchInputState>();

  const searchRegister = register('search', {
    setValueAs: (value: string) => value.trim(),
  });
  const searchVal = watch('search');

  const [periodMethod, setPeriodMethod] = useState('all');

  const handlePeriodRadioGroup = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target as HTMLInputElement;
      if (periodMethod !== value) setPeriodMethod(value);
    },
    [periodMethod],
  );

  const inputRef = useRef({});
  const [closeDate, setCloseDate] = useState<string | undefined>(undefined);
  const handleSelectDate = useCallback(
    () => (selectDate: string) => {
      setCloseDate(selectDate);
    },
    [],
  );

  const [area, setArea] = useState(INIT_AREA_TAG);
  const handleSelectArea = useCallback((opt: string) => {
    setArea(opt);
  }, []);

  const [cityName, setCityName] = useState(INIT_REGION_TAG);
  const [regionState, setRegionState] = useState({ regionId: 1, cityId: 1 });

  const { areaState } = useCommonList();
  const areaStateWithAllSelect = useMemo(() => ({ ...areaState, '0': '전국' }), [areaState]);

  const onSubmit = useCallback(() => {
    const isPageStepped = searchParams.get('page') && +(searchParams.get('page') as string) > 1;
    const areaId = String(Object.keys(areaStateWithAllSelect).indexOf(area));

    const periodOptions = {
      ...(periodMethod !== 'all' && periodMethod !== 'closeDate' && { periodType: periodMethod }),
      ...(periodMethod !== 'all' &&
        periodMethod === 'closeDate' && { periodType: 'closeDate', closeDate: closeDate }),
    };

    isPageStepped
      ? setSearchParams({
          ...Object.fromEntries(searchParams),
          ...periodOptions,
          ...(areaId && areaId !== '0' && { areaId }),
          ...(searchVal !== '' && searchVal != null && { title: searchVal }),
          ...(regionState?.cityId && { cityId: String(regionState.cityId) }),
          ...(regionState?.regionId && { regionId: String(regionState.regionId) }),
          page: '1',
        })
      : setSearchParams({
          ...Object.fromEntries(searchParams),
          ...periodOptions,
          ...(areaId && areaId !== '0' && { areaId }),
          ...(searchVal !== '' && searchVal != null && { title: searchVal }),
          ...(regionState?.cityId && { cityId: String(regionState.cityId) }),
          ...(regionState?.regionId && { regionId: String(regionState.regionId) }),
        });
  }, [
    area,
    areaStateWithAllSelect,
    closeDate,
    periodMethod,
    regionState.cityId,
    regionState.regionId,
    searchParams,
    searchVal,
    setSearchParams,
  ]);

  const onReset = useCallback(() => {
    setViewCnt(0);
    resetField('search');
    setCloseDateCnt(0);
    setPeriodMethod('all');
    setArea(INIT_AREA_TAG);
    setCloseDate(undefined);
    setCityName(INIT_REGION_TAG);
    setRegionState({ regionId: 1, cityId: 1 });

    setSearchParams({
      type: localNavTabLabel,
    });
  }, [localNavTabLabel, resetField, setCloseDateCnt, setSearchParams, setViewCnt]);

  useUpdateEffect(() => {
    onReset();
  }, [localNavTabLabel]);

  useEffect(() => {
    if (!searchParams.get('type')) {
      setSearchParams({ type: localNavTabLabel });
    }
  }, [localNavTabLabel, searchParams, setSearchParams]);

  return {
    area,
    cityName,
    inputRef,
    closeDate,
    regionState,
    periodMethod,
    searchRegister,
    onReset,
    onSubmit,
    setCityName,
    setRegionState,
    handleSelectArea,
    handleSelectDate,
    handlePeriodRadioGroup,
  };
};

export default useSupportFilter;
