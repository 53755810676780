import React from 'react';
import dayjs from 'dayjs';

import { NoResult, Table } from 'components/common';
import { MemberItemModel } from 'types/member';
import { PageInfoModel } from 'types';

import * as S from './MemberTable.styled';

interface PMCheckTableProps {
  isDone: boolean;
  viewCnt: number;
  scrapCnt: number;
  tabName?: string;
  className?: string;
  pageInfo: PageInfoModel;
  dataArr: MemberItemModel[];
  columnArr: { key: string; label: string }[];
  handleDownloadExcel: () => void;
  handleClickClickableTh: (name?: string) => () => void;
}

const MemberTable = ({
  className,
  tabName,
  columnArr,
  dataArr,
  pageInfo,
  viewCnt,
  scrapCnt,
  handleClickClickableTh,
  handleDownloadExcel,
}: PMCheckTableProps) => {
  return (
    <S.RootStyle
      title="userTable"
      pageInfo={pageInfo}
      className={className}
      data-status={tabName}
      aria-rowcount={dataArr?.length}
    >
      <Table.Head
        viewCnt={viewCnt}
        scrapCnt={scrapCnt}
        columnArr={columnArr}
        clickableThIndex={[5, 6]}
        handleClickClickableTh={handleClickClickableTh}
      />
      <S.Body>
        {dataArr?.length ? (
          dataArr?.map((item, pIdx) => {
            return (
              <Table.Row key={`${pIdx}`}>
                {columnArr &&
                  columnArr?.map(({ key }, cIdx) => {
                    return (
                      <S.Cell key={`${key}-${cIdx}`}>
                        {key === 'created' && item[key as keyof MemberItemModel] != null
                          ? dayjs(item[key as keyof MemberItemModel]).format('YYYY-MM-DD')
                          : item[key as keyof MemberItemModel] ?? '-'}
                      </S.Cell>
                    );
                  })}
              </Table.Row>
            );
          })
        ) : (
          <S.NoDataRow>
            <NoResult title="회원 목록이 없습니다" />
          </S.NoDataRow>
        )}
      </S.Body>
      <Table.ExtraButton onClick={handleDownloadExcel}>엑셀 다운로드</Table.ExtraButton>
    </S.RootStyle>
  );
};

export default MemberTable;
