import { css } from '@emotion/react';

import { fonts } from 'styles/themes/fonts';
import { colors } from 'styles/themes/colors';

const flexBoxCenter = css`
  display: flex;
  align-items: center;
`;

export const tab = {
  body36: css`
    ${flexBoxCenter};
    ${fonts.medium15};
    position: relative;
    height: 100%;
    padding: 8px 20px;
    color: ${colors.gray60};
    transition: 0.3s;

    &[aria-selected='true'] {
      color: ${colors.blue30};

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: ${colors.blue30};
      }
    }

    @media (hover: hover) {
      &:not(:disabled):hover {
        color: ${colors.gray70};
      }
    }
  `,
  body40: css`
    ${flexBoxCenter};
    ${fonts.medium16};
    position: relative;
    height: 100%;
    padding: 8px 20px;
    color: ${colors.gray60};
    transition: 0.3s;

    &[aria-selected='true'] {
      color: ${colors.blue30};

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: ${colors.blue30};
      }
    }

    @media (hover: hover) {
      &:not(:disabled):hover {
        color: ${colors.gray70};
      }
    }
  `,

  header: css`
    ${flexBoxCenter};
    ${fonts.medium16};
    height: 100%;
    color: ${colors.gray50};
    transition: 0.3s;

    &[aria-current='page'] {
      color: ${colors.black};
      font-weight: 600;
    }

    @media (hover: hover) {
      &:not(:disabled):hover {
        color: ${colors.black};
      }
    }
  `,
  my: css`
    ${flexBoxCenter};
    ${fonts.medium15};
    height: 100%;
    color: ${colors.gray60};
    transition: 0.3s;

    &[aria-selected='true'] {
      color: ${colors.blue40};
    }

    @media (hover: hover) {
      &:not(:disabled):hover {
        color: ${colors.gray70};
      }
    }
  `,
} as const;

export type tabType = typeof tab;
