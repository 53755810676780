/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { ManagementDetailModel } from 'types/support';
import DetailSection from '../DetailSection.component';
import * as S from './Basic.styled';

export type BasicProps = {
  data: ManagementDetailModel;
  className?: string;
};

function Basic({ data, className }: BasicProps) {
  return (
    <S.RootStyle className={className}>
      <DetailSection.DescList label="제목" val={data?.title} isFull />
      <DetailSection.DescList label="지원대상 (게시글)" val={data?.to} isFull />
      <DetailSection.DescList label="지원내용" val={data?.content} isFull />
    </S.RootStyle>
  );
}

export default Basic;
