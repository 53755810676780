import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const RootStyle = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, auto);
    width: 100%;
    border: 1px solid ${theme.colors.gray30};
  `}
`;

interface DescListProps {
  isFull?: boolean;
}

export const DescList = styled.dl<DescListProps>`
  ${({ theme, isFull }) => css`
    grid-column: ${isFull ? '1 / 3' : ''};
    display: grid;
    grid-template-columns: 210px auto;

    &:not(:last-of-type) {
      border-bottom: 1px solid ${theme.colors.gray30};
    }

    & > dt {
      ${theme.fonts.medium15};
      grid-column: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 48px;
      color: ${theme.colors.gray60};
      background-color: ${theme.colors.gray10};
    }

    & > dd {
      ${theme.fonts.regular15};
      grid-column: 2;
      display: flex;
      align-items: center;
      min-height: 48px;
      padding: 12px 20px;
      color: ${theme.colors.black};
      white-space: pre-line;
    }
  `}
`;

export const PeriodBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    height: 100%;

    & > b {
      ${theme.fonts.semibold15};
      margin-right: 4px;
    }

    & > p {
      ${theme.fonts.regular15};
    }
  `}
`;
