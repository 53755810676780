import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { LabelInput } from 'components/common';

export const RootStyle = styled.dl`
  display: flex;
  width: 100%;
  height: 48px;
`;

export const LabelBox = styled.dt`
  ${({ theme }) => css`
    ${theme.fonts.medium15};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 1;
    min-width: 210px;
    height: 100%;
    background-color: ${theme.colors.gray10};
  `}
`;

export const SearchForm = styled.dd`
  flex-grow: 1;
  height: 100%;
`;

export const SearchInput = styled(LabelInput)`
  width: 100%;

  & > input {
    border: 0;
    border-radius: 0;
    height: 48px;
  }
`;
