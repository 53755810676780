import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

interface DateProps {
  className?: string;
  date: Dayjs;
  selectedDate?: Dayjs;
  disabled?: boolean;
  handleSelectDate: (date?: Dayjs) => () => void;
}

const Date = ({ className, date, selectedDate, disabled, handleSelectDate }: DateProps) => {
  const isToday = dayjs().isSame(date, 'd');
  const isSelectedDate = !!selectedDate && selectedDate?.isSame(date, 'd');

  return (
    <Root className={className}>
      <DateButton
        type="button"
        aria-current={isToday ? 'date' : 'false'}
        disabled={disabled}
        onClick={handleSelectDate(date)}
        isSelectedDate={isSelectedDate}
      >
        <time>{date.format('D')}</time>
      </DateButton>
    </Root>
  );
};

export default Date;

const Root = styled.li`
  ${({ theme }) => css`
    width: 32px;
    height: 32px;
    background-color: ${theme.colors.white};
  `}
`;

interface DateButtonProps {
  isSelectedDate: boolean;
}

const DateButton = styled.button<DateButtonProps>`
  ${({ theme, isSelectedDate }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &::before,
    &&:hover::before {
      background-color: ${isSelectedDate && theme.colors.blue30};
    }

    && > time {
      font-weight: ${isSelectedDate && 500};
      color: ${isSelectedDate && theme.colors.white};
    }

    &[aria-current='date'] > time {
      font-weight: 500;
      color: ${isSelectedDate ? theme.colors.white : theme.colors.blue30};
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &:not([disabled]):hover {
      & > time {
        font-weight: 700;
      }
    }

    & > time {
      ${theme.fonts.regular14};
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      color: ${theme.colors.black};
      text-align: center;
      z-index: 2;
    }

    &[disabled] > time {
      color: ${theme.colors.gray50};
    }
  `}
`;
