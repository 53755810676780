import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Root = styled.div`
  width: 134px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  position: relative;
`;

export const Input = styled.input`
  display: none;
`;

interface LabelProps {
  isActive?: boolean;
}

export const Label = styled.label<LabelProps>`
  ${({ theme, isActive }) => css`
    position: relative;
    display: block;
    width: 32px;
    height: 18px;
    border-radius: 9px;
    color: ${theme.colors.gray70};
    background-color: ${isActive ? theme.colors.blue20 : theme.colors.gray40};
    transition: 0.2s linear;

    :hover {
      cursor: pointer;
    }

    ::after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: ${theme.colors.white};
      transform: translateX(0px);
      transition: transform 0.2s linear;
    }

    input[type='checkbox']:checked + &::after {
      content: '';
      position: absolute;
      top: 2px;
      left: 12px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: ${theme.colors.white};
      transform: translateX(5px);
      transition: transform 0.2s linear;
    }

    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none;
  `}
`;

export const Text = styled.label`
  ${({ theme }) => css`
    font-size: 14px;
    border: none;
    color: ${theme.colors.gray70};

    :hover {
      cursor: pointer;
    }

    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none;
  `}
`;
