export enum PATH {
  root = '/',
  support = '/support',
  supportDetail = '/support/detail',
  supportCreate = '/support/create',
  supportUpdate = '/support/update',
  login = '/login',
  notFound = '/404',
  user = '/user',
}
