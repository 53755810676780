import React from 'react';

import * as S from './LocalNavTab.styled';

interface LocalNavTabProps {
  label: string;
  tabs: { label: string; key: string }[];
  handleTab: (label: string) => () => void;
}

const LocalNavTab = ({ handleTab, tabs, label }: LocalNavTabProps) => {
  return (
    <S.NavTab>
      {tabs.map((item, idx) => (
        <S.NavBtn
          key={idx}
          type="button"
          aria-current={item.key === label ? 'page' : 'false'}
          onClick={handleTab(item.key)}
        >
          {item.label}
        </S.NavBtn>
      ))}
    </S.NavTab>
  );
};

export default LocalNavTab;
