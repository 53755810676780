import React, { Context, createContext, useContext } from 'react';
import { isEmpty } from 'lodash-es';

import { PageInfoModel } from 'types';
import { Icon } from 'components/icon';

import * as S from './CheckTable.styled';
import Pagination from '../pagination/Pagination.component';

interface CheckTableContextState {
  checkList: string[];
}

interface CheckTableProps {
  title: string;
  children: React.ReactNode;
  checkList: string[];
  className?: string;
  pageInfo: PageInfoModel;
}

interface CheckTableHeadProps {
  id: string;
  data: string[];
  clickableThIndex: number[];
  viewCnt: number;
  closeDateCnt: number;
  columnArr: { key: string; label: string }[];
  handleClickClickableTh: (name: string) => () => void;
  handleAllCheck: (arr: string[]) => (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface CheckTableBodyProps {
  children: React.ReactNode;
  className?: string;
}

interface CheckTableRowProps {
  id?: string;
  children: React.ReactNode;
}

interface CheckTableCheckCellProps {
  id: string;
  data: string;
  handleCheck: (e: React.SyntheticEvent<Element, Event>) => void;
}

interface CheckTableCellProps {
  children: React.ReactNode;
}

const CheckTableContext: Context<CheckTableContextState> = createContext<CheckTableContextState>(
  {} as CheckTableContextState,
);

const CheckTable = ({ title, children, checkList, pageInfo, ...restProps }: CheckTableProps) => {
  return (
    <CheckTableContext.Provider value={{ checkList }}>
      <S.TableContainer>
        <S.Root {...restProps}>
          <caption className="a11y">{title}</caption>
          {children}
        </S.Root>
        {!isEmpty(pageInfo) && <Pagination pageInfo={pageInfo} />}
      </S.TableContainer>
    </CheckTableContext.Provider>
  );
};

CheckTable.Head = function TableHeader({
  id,
  data,
  columnArr = [],
  clickableThIndex,
  viewCnt,
  closeDateCnt,
  handleAllCheck,
  handleClickClickableTh,
}: CheckTableHeadProps) {
  const { checkList } = useContext(CheckTableContext);

  const ClickableTh = (key: string, label: string, idx: number) => {
    if (clickableThIndex?.includes(idx) && key === 'view') {
      return (
        <S.Th
          key={key}
          scope="col"
          orderFilterClickCnt={viewCnt}
          onClick={handleClickClickableTh(key)}
          isClickable={clickableThIndex?.includes(idx)}
        >
          {label}
          {viewCnt !== 0 ? <Icon name="arrowFill" /> : <Icon name="arrowFlatIcon" />}
        </S.Th>
      );
    } else if (clickableThIndex?.includes(idx) && key === 'closeDate') {
      return (
        <S.Th
          key={key}
          scope="col"
          orderFilterClickCnt={closeDateCnt}
          onClick={handleClickClickableTh(key)}
          isClickable={clickableThIndex?.includes(idx)}
        >
          {label}
          {closeDateCnt !== 0 ? <Icon name="arrowFill" /> : <Icon name="arrowFlatIcon" />}
        </S.Th>
      );
    } else
      return (
        <S.Th key={key} scope="col" isClickable={clickableThIndex?.includes(idx)}>
          {label}
        </S.Th>
      );
  };

  return (
    <S.Thead>
      <S.Tr>
        <S.Th isClickable>
          <S.CheckInputWrapper>
            <input
              id={id}
              type="checkbox"
              onChange={handleAllCheck(data)}
              checked={checkList.length !== 0 && checkList.length === data.length}
            />
            <label htmlFor={id} />
          </S.CheckInputWrapper>
        </S.Th>
        {columnArr.map(({ key, label }, idx) => ClickableTh(key, label, idx))}
      </S.Tr>
    </S.Thead>
  );
};

CheckTable.Body = function TableBody({ children, className }: CheckTableBodyProps) {
  return <S.Tbody className={className}>{children}</S.Tbody>;
};

CheckTable.Row = function TableRow({ children }: CheckTableRowProps) {
  return <S.Tr>{children}</S.Tr>;
};

CheckTable.CheckCell = function TableCheckData({
  id,
  data,
  handleCheck,
}: CheckTableCheckCellProps) {
  const { checkList } = useContext(CheckTableContext);
  return (
    <S.CheckTd>
      <S.CheckInputWrapper>
        <input
          id={id}
          value={data}
          type="checkbox"
          checked={checkList.includes(data)}
          onChange={handleCheck}
        />
        <label htmlFor={id} />
      </S.CheckInputWrapper>
    </S.CheckTd>
  );
};

CheckTable.Cell = function TableData({ children, ...rest }: CheckTableCellProps) {
  return <S.Td {...rest}>{children}</S.Td>;
};

export default CheckTable;
