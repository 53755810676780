import React, { Suspense, useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { SuspenseFallback } from 'components';
import { Breadcrumb } from 'components/layout/local';
import { useModal } from 'hooks';
import { useDeleteProduct, useGetSupportDetail } from 'service/support';
import { detailTypeOptions } from 'assets/static';

import Apply from './section/apply/Apply.component';
import Basic from './section/basic/Basic.component';
import Detail from './section/detail/Detail.component';
import DetailSection from './section/DetailSection.component';

import * as S from './SupportDetail.styled';
import CloseModal from '../modal/CloseModal.component';

function SupportDetail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { data } = useGetSupportDetail(searchParams.get('id'));

  const onUpdate = useCallback(() => {
    navigate(`/support/update?id=${searchParams.get('id')}`);
  }, [navigate, searchParams]);

  const onOverlap = useCallback(() => {
    navigate(`/support/create?id=${searchParams.get('id')}`);
  }, [navigate, searchParams]);

  const [dropdownDetailStatus, setDropdownDetailStatus] = useState('1');
  const { openModal } = useModal();

  const { mutate: deletePost } = useDeleteProduct();

  const handleClickDelete = useCallback(
    (closeModal: () => void) => () => {
      deletePost([searchParams.get('id') as string]);
      closeModal();
    },
    [deletePost, searchParams],
  );

  const handlePeriodRadioGroup = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const { innerText } = event.target as HTMLButtonElement;
      if (dropdownDetailStatus !== innerText) setDropdownDetailStatus(innerText);

      if (innerText === '공고 삭제') {
        openModal(
          <CloseModal
            confirmString="삭제"
            title="공고를 삭제하시겠어요?"
            desc="공고를 삭제하면 리스트에서 사라지며, 다시 복구할 수 없습니다."
            onClick={handleClickDelete}
          />,
        );
      } else {
        onOverlap();
      }
    },
    [dropdownDetailStatus, handleClickDelete, onOverlap, openModal],
  );

  return (
    <S.RootStyle>
      <Breadcrumb
        title="지원사업 관리"
        updateBtnRender={() => (
          <Breadcrumb.UpdateBtn
            name="breadcrumb-update"
            handleClickUpdate={onUpdate}
            selectedOption={dropdownDetailStatus}
            onClick={handlePeriodRadioGroup}
            options={detailTypeOptions}
          />
        )}
      />

      <Suspense fallback={<SuspenseFallback />}>
        <DetailSection label="기본 정보" formCompo={<Basic data={data?.supportingInfo} />} />
        <DetailSection label="상세 정보" formCompo={<Detail data={data?.supportingInfo} />} />
        <DetailSection label="지원 자격" formCompo={<Apply data={data?.supportingInfo} />} />
      </Suspense>
    </S.RootStyle>
  );
}

export default SupportDetail;
