import styled from '@emotion/styled';
import InputContext from 'components/common/input/inputContext/InputContext.component';

export const CustomSectorSelector = styled(InputContext.SectorSelector)`
  width: 570px;

  & > button {
    height: 44px;
    border-radius: 0;
  }
`;

export const CustomDropdown = styled(InputContext.Dropdown)`
  width: 570px;

  & > button {
    height: 44px;
    border-radius: 0;
  }
`;
