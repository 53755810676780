import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface CheckProps {
  eligible: boolean;
}

export const Check = styled.li<CheckProps>`
  ${({ theme, eligible }) => css`
    display: flex;
    align-items: center;
    column-gap: 4px;

    & > span {
      ${theme.fonts.regular14};
      color: ${eligible ? theme.colors.blue40 : theme.colors.gray60};
    }

    & > svg {
      fill: ${eligible ? theme.colors.blue40 : theme.colors.gray60};
    }
  `}
`;
