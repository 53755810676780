import { useToast } from 'hooks';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import { LoginForm, LoginFormErrType } from 'types/login';
import { LOGIN_FORM_VALIDATION } from 'assets/static/form';
import { makeCryptoFunction } from 'utils/helper';
import { useLogin } from 'service/login';

const useLoginForm = () => {
  const {
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const emailRegister = register('email', LOGIN_FORM_VALIDATION.email(clearErrors));
  const passwordRegister = register('password', LOGIN_FORM_VALIDATION.password(clearErrors));

  const [isKeepLogin, setIsKeepLogin] = useState(false);
  const handleChangeKeepLogin = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
    const value = e.target.checked;
    setIsKeepLogin(value);
  }, []);

  const { mutate } = useLogin(isKeepLogin);
  const submitHandler = (data: LoginForm) => {
    const reqData = {
      ...data,
      password: makeCryptoFunction(data?.password),
    };

    mutate(reqData);
  };

  const toast = useToast();
  const submitErrorHandler = (errors: LoginFormErrType) => {
    const errArr = Object.entries(errors).flatMap((errData) =>
      errData.filter((errData) => typeof errData === 'object'),
    );
    toast(errArr[0].message || errArr[1].message);
  };

  return {
    errors,
    isKeepLogin,
    emailRegister,
    passwordRegister,
    handleChangeKeepLogin,
    handleSubmit: handleSubmit(submitHandler, submitErrorHandler),
  };
};

export default useLoginForm;
