import React from 'react';

import { RecoilRoot } from 'recoil';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Global, ThemeProvider } from '@emotion/react';

import { queryClient } from 'service';
import { globalStyles, theme } from 'styles';
import { AuthProvider } from 'context/AuthProvider';
import Router from './router';

const App = () => {
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Global styles={globalStyles} />
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <Router />
          </AuthProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </RecoilRoot>
  );
};

export default App;
