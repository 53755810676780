/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import dayjs, { Dayjs } from 'dayjs';

import { Icon } from 'components/icon';
import { fonts } from 'styles';

interface TimePickerProps {
  err: string;
  time: string;
  disabled?: boolean;
  className?: string;
  selectedDate?: Dayjs;
  handleBlurTime: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleChangeTime: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TimePicker = ({
  className,
  selectedDate,
  time,
  err,
  disabled,
  handleChangeTime,
  handleBlurTime,
}: TimePickerProps) => {
  const handleKeyListener = (e: KeyboardEvent) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyListener);

    return () => {
      window.removeEventListener('keydown', handleKeyListener);
    };
  }, []);

  return (
    <>
      <Root className={className}>
        <SelectedDate>
          <Icon name="calendarIcon" />
          <time>
            {selectedDate && dayjs(selectedDate).format('DD/MM/YYYY') !== 'Invalid Date' ? (
              dayjs(selectedDate).format('DD/MM/YYYY')
            ) : (
              <Placeholder>DD/MM/YYYY</Placeholder>
            )}
          </time>
        </SelectedDate>
        <TimeContainer disabled={disabled}>
          <Icon name="timeIcon" />
          <TimeInput
            value={time}
            disabled={disabled}
            onChange={handleChangeTime}
            onBlur={handleBlurTime}
          />
        </TimeContainer>
      </Root>
      {err && <ValidText>{err}</ValidText>}
    </>
  );
};

export default TimePicker;

const Root = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 143px 1fr;
    align-items: center;
    height: 40px;
    border: 1px solid ${theme.colors.gray30};
  `}
`;

const mixin_container = css`
  ${fonts.regular14};
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 13px;

  & > svg {
    margin-right: 8px;
  }
`;

const SelectedDate = styled.div`
  ${mixin_container};
`;

interface TimeContainerProps {
  disabled?: boolean;
}

const TimeContainer = styled.div<TimeContainerProps>`
  ${({ theme, disabled }) => css`
    ${mixin_container};
    position: relative;

    background-color: ${disabled ? theme.colors.gray30 : theme.colors.white};
    opacity: ${disabled ? 0.4 : 1};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 1px;
      height: 20px;
      background-color: ${theme.colors.gray30};
      transform: translateY(-50%);
    }
  `}
`;

const TimeInput = styled.input<TimeContainerProps>`
  ${({ theme, disabled }) => css`
    ${theme.fonts.regular14};
    width: 50px;
    && {
      height: 20px;
    }
    border: 0;
    padding: 0 3px;
    background-color: ${disabled ? theme.colors.gray30 : theme.colors.white};
    opacity: ${disabled ? 0.4 : 1};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `}
`;

const ValidText = styled.p`
  ${({ theme }) => css`
    margin-top: 4px;
    ${theme.fonts.regular13};
    color: ${theme.colors.red20};
  `}
`;

const Placeholder = styled.span`
  ${({ theme }) => css`
    ${theme.fonts.regular14};
    color: ${theme.colors.gray50};
  `}
`;
