import React, { useCallback, useEffect } from 'react';

import { useModal } from 'hooks';
import { usePrompt } from 'hooks/usePrompt';
import NavigationGuardModal from '../modal/navigationGuardModal/NavigationGuardModal.component';

type NavigationGuardProps = {
  when: boolean;
};

const NavigationGuard = ({ when }: NavigationGuardProps) => {
  const { openModal, closeModal } = useModal();
  const { showPrompt, confirmNavigation, cancelNavigation } = usePrompt(when);

  const handleClickNo = useCallback(() => {
    closeModal();
    cancelNavigation();
  }, [cancelNavigation, closeModal]);

  const onConfirm = useCallback(() => {
    closeModal();
    confirmNavigation();
  }, [closeModal, confirmNavigation]);

  useEffect(() => {
    showPrompt &&
      openModal(
        <NavigationGuardModal
          confirmString="나가기"
          title="이 페이지에서 나가시겠어요?"
          desc="이 페이지에서 나가면 작성된 내용이 저장되지 않습니다."
          onCancel={handleClickNo}
          onConfirm={onConfirm}
        />,
      );
  }, [handleClickNo, onConfirm, openModal, showPrompt]);

  return <></>;
};

export default NavigationGuard;
