import React from 'react';

import * as S from './Checkbox.styeld';

interface CheckboxProps {
  id: string;
  className?: string;
  name?: string;
  label?: string;
  value?: string | number;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = ({ id, className, name, label, checked, value, onChange }: CheckboxProps) => {
  const uuid = `checkbox-${id}`;
  return (
    <S.Wrapper className={className}>
      <input
        type="checkbox"
        id={uuid}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={uuid}>{label}</label>
    </S.Wrapper>
  );
};

export default Checkbox;
