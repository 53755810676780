import React from 'react';
import * as S from './RadioBtn.styled';

interface RadioBtnProps {
  id: string;
  className?: string;
  name: string;
  label: string;
  value: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioBtn = ({ id, className, name, label, value, checked, onChange }: RadioBtnProps) => {
  return (
    <S.RadioBtnWrapper className={className}>
      <input
        type="radio"
        id={id}
        name={name}
        tabIndex={-1}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} tabIndex={0}>
        {label}
      </label>
    </S.RadioBtnWrapper>
  );
};

export default RadioBtn;
