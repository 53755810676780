import React from 'react';
import dayjs from 'dayjs';

import { PageInfoModel } from 'types';
import { CheckTable, NoResult } from 'components/common/index';
import * as S from './SupportTable.styled';

export interface SupportItemModel {
  age: string;
  view: number;
  area: string;
  title: string;
  region: string;
  period: string;
  closeType: string;
  supportingId: string;
  closeDate: string | null;
}

interface SupportTableProps {
  viewCnt: number;
  isDone: boolean;
  className?: string;
  checkList: string[];
  dataNumArr: string[];
  closeDateCnt: number;
  pageInfo: PageInfoModel;
  dataArr: SupportItemModel[];
  columnArr: { key: string; label: string }[];
  handleClickClickableTh: (name?: string) => () => void;
  handleCheck: (e: React.SyntheticEvent<Element, Event>) => void;
  handleAllCheck: (arr: string[]) => (e: React.SyntheticEvent<Element, Event>) => void;
}

const SupportTable = ({
  className,
  columnArr,
  dataArr,
  dataNumArr,
  checkList,
  pageInfo,
  handleAllCheck,
  handleCheck,
  handleClickClickableTh,
  viewCnt,
  closeDateCnt,
}: SupportTableProps) => {
  return (
    <S.Root
      title="supportTable"
      pageInfo={pageInfo}
      className={className}
      checkList={checkList}
      aria-rowcount={dataArr?.length}
    >
      <CheckTable.Head
        id="supportCheck"
        data={dataNumArr}
        viewCnt={viewCnt}
        columnArr={columnArr}
        closeDateCnt={closeDateCnt}
        clickableThIndex={[2, 6]}
        handleAllCheck={handleAllCheck}
        handleClickClickableTh={handleClickClickableTh}
      />

      <S.Body>
        {dataArr?.length ? (
          dataArr?.map((item, pIdx) => {
            const formattedNum = item.supportingId;

            return (
              <CheckTable.Row key={`${formattedNum}0${pIdx}`} id={formattedNum}>
                <CheckTable.CheckCell
                  id={item.supportingId}
                  data={formattedNum}
                  handleCheck={handleCheck}
                />
                {columnArr &&
                  columnArr.map(({ key }, cIdx) => {
                    return (
                      <S.Cell key={`${key}-${cIdx}`}>
                        {cIdx === 0 && (
                          <S.DetailLink to={`/support/detail?id=${item.supportingId}`} />
                        )}
                        <span>
                          {key === 'closeDate' && item[key as keyof SupportItemModel] != null
                            ? dayjs(item[key as keyof SupportItemModel]).format('YYYY-MM-DD')
                            : item[key as keyof SupportItemModel] ?? '-'}
                        </span>
                      </S.Cell>
                    );
                  })}
              </CheckTable.Row>
            );
          })
        ) : (
          <S.NoDataRow>
            <NoResult title="지원사업 목록이 없습니다" />
          </S.NoDataRow>
        )}
      </S.Body>
    </S.Root>
  );
};

export default SupportTable;
