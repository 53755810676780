import React from 'react';

import { useModal } from 'hooks';
import * as S from './Dimmer.styled';

interface DimmerProps {
  children: React.ReactNode;
  isAbleClick?: boolean;
}

const Dimmer: React.FC<DimmerProps> = ({ children, isAbleClick = true }) => {
  const { closeModal } = useModal();

  const onClickDimmed = (e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget === e.target && isAbleClick) {
      closeModal();
    }
  };

  return <S.Dimmer onClick={onClickDimmed}>{children}</S.Dimmer>;
};

export default Dimmer;
