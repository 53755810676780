import React from 'react';

import Area from './area/Area.component';
import Period from './period/Period.component';
import Region from './region/Region.components';
import Search from './search/Search.component';
import * as S from './SupportFIlter.styled';
import { useSupportFilter } from '../hooks';

export type FilterProps = {
  className?: string;
  localNavTabLabel: string;
  setViewCnt: React.Dispatch<React.SetStateAction<number>>;
  setCloseDateCnt: React.Dispatch<React.SetStateAction<number>>;
};

function SupportFilter({ localNavTabLabel, setViewCnt, setCloseDateCnt, className }: FilterProps) {
  const {
    area,
    inputRef,
    cityName,
    closeDate,
    periodMethod,
    searchRegister,
    regionState,
    onReset,
    onSubmit,
    setCityName,
    setRegionState,
    handleSelectDate,
    handleSelectArea,
    handlePeriodRadioGroup,
  } = useSupportFilter(localNavTabLabel, setViewCnt, setCloseDateCnt);

  return (
    <S.RootStyle className={className}>
      <S.FilterContainer>
        <S.TopSearchFilter>
          <Search searchRegister={searchRegister} />
        </S.TopSearchFilter>
        <S.MiddlePeriodFilter>
          <Period
            ref={inputRef}
            handlePeriodRadioGroup={handlePeriodRadioGroup}
            closeDate={closeDate}
            handleSelectDate={handleSelectDate}
            periodMethod={periodMethod}
          />
        </S.MiddlePeriodFilter>
        <S.BottomRegionFilter>
          <Region
            cityName={cityName}
            setCityName={setCityName}
            regionState={regionState}
            setRegionState={setRegionState}
          />
        </S.BottomRegionFilter>
        <S.BottomAreaFilter>
          <Area area={area} handleSelectArea={handleSelectArea} />
        </S.BottomAreaFilter>
      </S.FilterContainer>
      <S.BtnGroup>
        <S.ResetBtn label="필터 초기화" size="smd" variant="secondary" onClick={onReset} />
        <S.ConfirmBtn label="검색" size="smd" variant="primary" onClick={onSubmit} />
      </S.BtnGroup>
    </S.RootStyle>
  );
}

export default SupportFilter;
