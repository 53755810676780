import React, { useRef, useState, useMemo, useContext, createContext, ForwardedRef } from 'react';

import { Checkbox } from 'components';
import { useKeyTrap, useOnClickOutside } from 'hooks';
import * as S from './Dropdown.styled';

interface DropdownProps {
  id: string;
  name: string;
  error?: boolean;
  required?: boolean;
  readOnly?: boolean;
  className?: string;
  placeholder?: string;
  children: React.ReactNode;
  selectedOption: string | string[];
  options: { [key: string]: string };
  openerRef: ForwardedRef<HTMLButtonElement>;
  onSelect?: (opt: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLButtonElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface DropdownOptionProps {
  value: string;
  label: string;
  className?: string;
  selected?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

interface DropdownCheckOptionProps {
  id: string;
  value: string;
  label: string;
  checked?: boolean;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface DropdownContextValue {
  name?: string;
  selectedOption: string | string[];
  handleChangeOption?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickOption: (opt: string) => (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const DropdownContext = createContext({} as DropdownContextValue);

const Dropdown = ({
  id,
  name,
  error,
  options,
  required,
  readOnly,
  children,
  openerRef,
  className,
  placeholder,
  selectedOption,
  onBlur,
  onChange,
  onSelect,
}: DropdownProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLUListElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const selectedOptionLabel = useMemo(() => {
    if (!selectedOption) return false;

    if (typeof selectedOption === 'object') {
      return selectedOption.length > 1
        ? `${options[selectedOption[0]]} 외 ${selectedOption.length - 1}개`
        : options[selectedOption[0]];
    }

    return options[selectedOption];
  }, [options, selectedOption]);

  useKeyTrap(dropdownRef, handleClose);
  useOnClickOutside(containerRef, handleClose);

  function handleToggleDropdown() {
    isOpen ? handleClose() : handleOpen();
  }

  function handleOpen() {
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }

  const handleClickOption = (option: string) => () => {
    typeof onSelect === 'function' && onSelect(option);
    handleClose();
  };

  const uuid = `dropdown-${id}`;

  return (
    <DropdownContext.Provider
      value={{ name, selectedOption, handleClickOption, handleChangeOption: onChange }}
    >
      <S.Drdopdown ref={containerRef} className={className}>
        <S.OpenerBtn
          ref={openerRef}
          type="button"
          name={name}
          disabled={readOnly}
          aria-controls={uuid}
          aria-expanded={isOpen}
          aria-invalid={error}
          aria-required={required ? 'true' : 'false'}
          onClick={handleToggleDropdown}
          onBlur={onBlur}
        >
          {selectedOptionLabel || <S.Placeholder>{placeholder}</S.Placeholder>}
          <S.ArrowIcon name="arrowBold" />
        </S.OpenerBtn>
        <S.DropList id={uuid} isOpen={isOpen}>
          {children}
        </S.DropList>
      </S.Drdopdown>
    </DropdownContext.Provider>
  );
};

Dropdown.Option = function OptionItem({
  label,
  value,
  disabled,
  selected,
  className,
  onClick,
}: DropdownOptionProps) {
  const { name, handleClickOption } = useContext(DropdownContext);

  return (
    <S.Option className={className} aria-selected={selected}>
      <button
        type="button"
        name={name}
        disabled={disabled}
        onClick={onClick ?? handleClickOption(value)}
      >
        {label}
      </button>
    </S.Option>
  );
};

Dropdown.CheckOption = function CheckOptionItem({
  id,
  label,
  value,
  checked,
  className,
  onChange,
}: DropdownCheckOptionProps) {
  const { name, selectedOption, handleChangeOption } = useContext(DropdownContext);

  return (
    <S.CheckOption className={className} aria-selected={selectedOption === value}>
      <Checkbox
        id={id}
        name={name}
        label={label}
        value={value}
        checked={checked ?? selectedOption.includes(value)}
        onChange={onChange ?? handleChangeOption!}
      />
    </S.CheckOption>
  );
};

export default Dropdown;
