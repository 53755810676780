import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const RootStyle = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 362px;
    padding: 22px 28px;
    background-color: ${theme.colors.white};
    z-index: ${theme.zIndex.modal};
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background-color: ${theme.colors.white};

    h1 {
      ${theme.fonts.semibold17};
      color: ${theme.colors.black};
      margin-bottom: 4px;
    }
  `}
`;

export const CloseBtn = styled.button`
  ${({ theme }) => css`
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;

    & > svg {
      width: 12px;
      fill: ${theme.colors.gray40};
    }
  `}
`;

export const Description = styled.span`
  ${({ theme }) => css`
    ${theme.fonts.regular14};
    width: 100%;
    color: ${theme.colors.gray70};
    margin-bottom: 24px;
  `}
`;

export const BtnGroup = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

interface BtnProps {
  isDelete?: boolean;
}

export const Button = styled.button<BtnProps>`
  ${({ theme, isDelete }) => css`
    ${theme.fonts.regular15};
    font-weight: ${isDelete ? '600' : '400'};
    color: ${isDelete ? theme.colors.blue40 : theme.colors.gray40};
  `}
`;
