import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { Table } from 'components/common';

export const RootStyle = styled(Table)`
  min-height: 400px;

  tr {
    grid-template-columns: 8.6255% 11.8644% 13.5593% 11.7103% 18.3389% 9.0878% 1fr;
    min-height: 40px;
  }
`;

export const Body = styled(Table.Body)`
  ${({ theme }) => css`
    & > tr {
      border-bottom: 1px solid ${theme.colors.gray30};
    }
  `}
`;

export const DetailLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const NoDataRow = styled.tr`
  && {
    &:hover td {
      background-color: inherit;
    }
  }
`;

export const Cell = styled(Table.Cell)`
  @media (max-width: 1500px) {
    &[data-hidden='true'] {
      border: 2px solid red;
      display: none;
    }
  }
`;
