import React from 'react';
import * as S from './FormSection.styled';

interface FormSectionProps {
  formCompo: React.ReactNode;
  label: string;
}

function FormSection({ formCompo, label }: FormSectionProps) {
  return (
    <S.SectionContainer>
      <S.SectionLabel>{label}</S.SectionLabel>
      <S.FormSectionContainer>{formCompo}</S.FormSectionContainer>
    </S.SectionContainer>
  );
}

export default FormSection;
