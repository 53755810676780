import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const SectionContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    width: 100%;
    border-top: 1px solid ${theme.colors.gray20};
    padding-top: 40px;
  `}
`;

export const SectionLabel = styled.label`
  ${({ theme }) => css`
    ${theme.fonts.medium14};
    color: ${theme.colors.gray50};
  `}
`;

export const DetailSectionContainer = styled.div`
  width: 100%;
`;

interface DescListProps {
  isFull?: boolean;
}

export const DescList = styled.dl<DescListProps>`
  ${({ theme, isFull }) => css`
    grid-column: ${isFull ? '1 / 3' : ''};
    display: grid;
    grid-template-columns: 210px auto;

    &:not(:last-of-type) {
      border-bottom: 1px solid ${theme.colors.gray30};
    }

    & > dt {
      ${theme.fonts.medium15};
      grid-column: 1;
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 48px;
      padding: 12px 20px;
      color: ${theme.colors.gray60};
      background-color: ${theme.colors.gray10};
    }

    & > dd {
      ${theme.fonts.regular15};
      grid-column: 2;
      display: flex;
      align-items: center;
      min-height: 48px;
      padding: 12px 20px;
      color: ${theme.colors.black};
      white-space: pre-line;
      word-break: break-all;

      & > a {
        color: #23a0e7;

        &:hover {
          text-decoration: underline;
        }
      }

      & > ul {
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        & > li > button {
          ${theme.fonts.regular15};
          padding: 0;
          color: #23a0e7;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  `}
`;
