import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { colors } from 'styles/themes/colors';

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const Root = styled.table`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    border: 1px solid ${theme.colors.gray30};
    border-bottom: 0;

    &[aria-rowcount='0'] {
      border-bottom: 0;
    }
  `}
`;

export const Thead = styled.thead`
  ${({ theme }) => css`
    background-color: ${theme.colors.gray10};

    & > tr {
      & > th {
        height: 40px;

        & > svg {
          margin-left: 4px;
          font-size: 8px;
          & > path {
            fill: ${theme.colors.gray40};
          }
        }
      }
    }
  `}
`;

export const Tr = styled.tr`
  position: relative;
  display: grid;
`;

export const mixin_cell = css`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 12px;
  color: ${colors.black};
  text-align: left;

  & > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

interface ThProps {
  isClickable?: boolean;
  orderFilterClickCnt?: number;
}

export const Th = styled.th<ThProps>`
  ${({ theme, isClickable, orderFilterClickCnt }) => css`
    ${mixin_cell}
    ${theme.fonts.medium14};
    position: relative;
    border-bottom: 1px solid ${theme.colors.gray30};
    color: ${theme.colors.gray60};
    cursor: ${isClickable ? 'pointer' : ''};
    pointer-events: ${isClickable ? '' : 'none'};

    & > svg {
      transform: ${orderFilterClickCnt === 2 ? 'rotate(180deg)' : ''};
    }
  `}
`;

export const Tbody = styled.tbody`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};

    tr:not(:last-of-type) {
      border-bottom: 1px solid ${theme.colors.gray30};
    }

    & > tr {
      :hover td {
        background-color: ${theme.colors.gray10};
      }

      & > td {
        min-height: 40px;
      }

      & > td:first-of-type {
        z-index: 1;
      }
    }
  `}
`;

export const CheckTd = styled.td`
  ${({ theme }) => css`
    ${mixin_cell}
    position: relative;
    font: ${theme.fonts.regular14};
    cursor: pointer;
  `}
`;

export const Td = styled.td`
  ${({ theme }) => css`
    ${mixin_cell}
    ${theme.fonts.regular14};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
  `}
`;

export const CheckInput = styled.input`
  z-index: 1;
`;

export const Label = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const CheckInputWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    z-index: 1;

    & > input[type='checkbox'] {
      position: absolute;
      width: 1px;
      height: 1px;
      border: 0;
      padding: 0;
      margin: -1px;
      clip: rect(0, 0, 0, 0);
      overflow: hidden;
    }

    & > label {
      ${theme.fonts.regular15};
      display: flex;
      align-items: center;
      min-height: 100%;
      color: ${theme.colors.gray80};
      cursor: pointer;
      user-select: none;

      &::before {
        content: '';
        width: 16px;
        height: 16px;
        margin-right: 12px;
        border: 1px solid ${theme.colors.gray40};
        transition: background-color 0.3s, border 0.3s;
      }

      @media (hover: hover) {
        &:hover::before {
          border-color: ${theme.colors.blue10};
        }
      }
    }
    & > input[type='checkbox']:checked + label {
      &::before {
        content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 7.32091L2.58174 6.23088L6.64065 10.3209L13.4087 3.50098L14.5 4.59101L6.64065 12.501L1.5 7.32091Z' fill='%23fff' /%3E%3C/svg%3E%0A");
        vertical-algin: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid ${theme.colors.blue20};
        font-size: 2.3rem;
        background-color: ${theme.colors.blue20};
      }
    }
  `}
`;
