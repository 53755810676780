import React, {
  ReactNode,
  useContext,
  forwardRef,
  ForwardedRef,
  createContext,
  HTMLInputTypeAttribute,
} from 'react';

import { Radio, Checkbox, Dropdown, RadioBtn, SectorSelector, RegionSelector } from 'components';
import * as S from './InputContext.styled';

interface InputContextProps {
  id: string;
  label: string;
  error?: string;
  optional?: boolean;
  className?: string;
  labelHidden?: boolean;
  errorHidden?: boolean;
  children?: React.ReactNode;
}

interface InputProps {
  name: string;
  value: string;
  placeholder: string;
  type?: HTMLInputTypeAttribute;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface UnitInputProps extends InputProps {
  unit: ReactNode;
  className?: string;
}

interface RadioListProps {
  name: string;
  value: string;
  radios: Array<{ label: string; value: string }>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-types
interface CheckListProps<T extends Object> {
  data: T;
  direction?: 'column' | 'row';
  checkList: Array<{ label: string; key: string }>;
  entire?: { label: string; subText?: string; isAllChecked: boolean };
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface DropdownProps {
  name: string;
  value: string;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  options: { [key: string]: string };
  onSelect: (opt: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLButtonElement>) => void;
}

interface LabelInputContextValue {
  id: string;
  isError: boolean;
  required: boolean;
}

interface TagSelectorProps {
  name: string;
  value: string[];
  className?: string;
  placeholder: string;
  options: { [key: string]: string };
  onDelete?: (opt: string) => () => void;
  onBlur: (e: React.FocusEvent<HTMLButtonElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface RegionSelectorProps {
  name: string;
  cityName?: string;
  className?: string;
  placeholder?: string;
  innerHidden?: boolean;
  selectedHidden?: boolean;
  value?: [number, number][];
  regionState: { regionId: number; cityId: number };
  setCityName: (c: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLButtonElement>) => void;
  onDelete?: (regionId: number, citiyId: number) => () => void;
  onChange?: (regionId: number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  setRegionState: ({ regionId, cityId }: { regionId: number; cityId: number }) => void;
}

const LabelInputContext = createContext({} as LabelInputContextValue);

const InputContext = ({
  id,
  error,
  label,
  children,
  className,
  optional = false,
  labelHidden = false,
  errorHidden = false,
}: InputContextProps) => {
  const isShowValidError = !errorHidden && error;

  return (
    <LabelInputContext.Provider value={{ id, required: !optional, isError: !!error }}>
      <S.Wrapper className={className}>
        <S.Label htmlFor={id} hidden={labelHidden}>
          {label}
          {optional && <S.Optional>(선택)</S.Optional>}
        </S.Label>
        {children}
        {isShowValidError && <S.ValidText id={`${id}-errMsg`}>{error}</S.ValidText>}
      </S.Wrapper>
    </LabelInputContext.Provider>
  );
};

InputContext.Input = forwardRef(function Input(
  { type = 'text', name, value, placeholder, onChange, onBlur }: InputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { id, required, isError } = useContext(LabelInputContext);

  return (
    <S.Input
      ref={ref}
      id={id}
      type={type}
      aria-required={required ? 'true' : 'false'}
      aria-invalid={isError ? 'true' : 'false'}
      aria-errormessage={`${id}-errMsg`}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
});

InputContext.UnitInput = forwardRef(function UnitInput(
  { type = 'text', className, unit, name, value, placeholder, onChange, onBlur }: UnitInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { id, required, isError } = useContext(LabelInputContext);

  return (
    <S.UnitInputContainer className={className}>
      <S.Unit>{unit}</S.Unit>
      <S.Input
        css={S.unitInput}
        ref={ref}
        id={id}
        type={type}
        aria-required={required ? 'true' : 'false'}
        aria-invalid={isError ? 'true' : 'false'}
        aria-errormessage={`${id}-errMsg`}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />
    </S.UnitInputContainer>
  );
});

InputContext.RadioList = function RadioList({ name, radios, value, onChange }: RadioListProps) {
  return (
    <S.RadioList>
      {radios.map((radio) => (
        <li key={radio.value}>
          <Radio
            id={radio.value}
            name={name}
            label={radio.label}
            value={radio.value}
            checked={radio.value === value}
            onChange={onChange}
          />
        </li>
      ))}
    </S.RadioList>
  );
};

InputContext.RadioBtnList = function RadioBtnList({
  name,
  value,
  radios,
  onChange,
}: RadioListProps) {
  return (
    <S.RadioBtnlist>
      {radios.map((radio) => (
        <li key={radio.value}>
          <RadioBtn
            id={radio.value}
            name={name}
            label={radio.label}
            value={radio.value}
            checked={radio.value === value}
            onChange={onChange}
          />
        </li>
      ))}
    </S.RadioBtnlist>
  );
};

InputContext.CheckList = function CheckList<T>({
  entire,
  direction = 'column',
  data,
  checkList,
  onChange,
}: CheckListProps<T & { [index: string]: unknown }>) {
  return (
    <S.CheckList direction={direction}>
      {entire && (
        <S.AllCheck subText={entire?.subText}>
          <Checkbox
            id="entire"
            name="entire"
            label={entire.label}
            checked={entire.isAllChecked}
            onChange={onChange}
          />
        </S.AllCheck>
      )}
      {checkList.map((item) => (
        <Checkbox
          key={item.key}
          id={item.key}
          name={item.key}
          label={item.label}
          checked={data[item.key] as boolean}
          onChange={onChange}
        />
      ))}
    </S.CheckList>
  );
};

InputContext.Dropdown = forwardRef(function LabelDropdown(
  { className, name, placeholder, disabled, options, value, onSelect, onBlur }: DropdownProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { id, required, isError } = useContext(LabelInputContext);
  return (
    <Dropdown
      id={id}
      name={name}
      openerRef={ref}
      error={isError}
      options={options}
      readOnly={disabled}
      required={required}
      className={className}
      selectedOption={value}
      placeholder={placeholder}
      onSelect={onSelect}
      onBlur={onBlur}
    >
      {Object.entries(options).map(([val, label]) => {
        return <Dropdown.Option key={label} value={val} label={label} selected={val === value} />;
      })}
    </Dropdown>
  );
});

InputContext.SectorSelector = function LabelSectorSelector({
  className,
  name,
  placeholder,
  options,
  value,
  onChange,
  onDelete,
  onBlur,
}: TagSelectorProps) {
  const { id, required, isError } = useContext(LabelInputContext);
  return (
    <SectorSelector
      id={id}
      className={className}
      name={name}
      placeholder={placeholder}
      error={isError}
      required={required}
      selectedOption={value}
      options={options}
      onChange={onChange}
      onDelete={onDelete}
      onBlur={onBlur}
    />
  );
};

InputContext.RegionSelector = forwardRef(function LabelRegionSelector(
  {
    name,
    value,
    cityName,
    className,
    regionState,
    innerHidden,
    placeholder,
    selectedHidden,
    onBlur,
    onChange,
    onDelete,
    setCityName,
    setRegionState,
  }: RegionSelectorProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { id, required, isError } = useContext(LabelInputContext);

  return (
    <RegionSelector
      id={id}
      name={name}
      openerRef={ref}
      error={isError}
      cityName={cityName}
      required={required}
      className={className}
      selectedOption={value}
      regionState={regionState}
      innerHidden={innerHidden}
      placeholder={placeholder}
      selectedHidden={selectedHidden}
      onBlur={onBlur}
      onChange={onChange}
      onDelete={onDelete}
      setCityName={setCityName}
      setRegionState={setRegionState}
    />
  );
});

export default InputContext;
