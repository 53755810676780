import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Icon from 'components/icon/Icon';

export const Drdopdown = styled.div`
  position: relative;
  width: 100%;
  height: 52px;
`;

export const OpenerBtn = styled.button`
  ${({ theme }) => css`
    ${theme.dropdown.thin};
  `}
`;

export const Placeholder = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.gray50};
  `}
`;

export const ArrowIcon = styled(Icon)`
  ${({ theme }) => css`
    fill: ${theme.colors.gray50};
  `}
`;

interface DropListProps {
  isOpen: boolean;
}

export const DropList = styled.ul<DropListProps>`
  ${({ theme, isOpen }) => css`
    position: absolute;
    top: 48px;
    display: ${isOpen ? 'unset' : 'none'};
    width: 240px;
    border-radius: 10px;
    padding: 10px 0;
    box-shadow: ${theme.boxShadows.dropdown};
    background-color: ${theme.colors.white};
    z-index: ${theme.zIndex.dialog};
    overflow: overlay;
  `}
`;

export const Option = styled.li`
  ${({ theme }) => css`
    width: 100%;
    transition: 0.3s;

    & > button {
      ${theme.fonts.regular15};
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      padding: 0 16px;
      background-color: ${theme.colors.white};
      color: ${theme.colors.gray80};

      @media (hover: hover) {
        &:not(:disabled):hover {
          color: ${theme.colors.blue40};
        }
      }
    }

    &[aria-selected='true'] > button {
      color: ${theme.colors.blue40};
    }
  `}
`;

export const CheckOption = styled.li`
  ${({ theme }) => css`
    ${theme.fonts.regular15};
    width: 100%;

    & > div {
      width: 100%;
      height: 40px;

      & > label {
        width: 100%;
        padding: 0 16px;
        transition: 0.3s;

        @media (hover: hover) {
          &:not(:disabled):hover {
            color: ${theme.colors.blue40};
          }
        }
      }
    }
  `}
`;
