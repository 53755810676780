import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import * as S from './Search.styled';

interface SearchProps {
  searchRegister: UseFormRegisterReturn<'search'>;
}

function Search({ searchRegister }: SearchProps) {
  return (
    <S.RootStyle>
      <S.LabelBox>제목</S.LabelBox>
      <S.SearchForm>
        <S.SearchInput
          id="search"
          labelHidden
          errorHidden
          name="search"
          label="제목 검색"
          register={searchRegister}
          placeholder="검색어를 입력해주세요"
        />
      </S.SearchForm>
    </S.RootStyle>
  );
}

export default Search;
