import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { PATH } from 'constants/path';
import { Toast } from 'components/common';
import { useAuth } from 'context/AuthProvider';

import * as S from './AuthLayout.styled';

interface AuthLayoutProps {
  className?: string;
}

const AuthLayout = ({ className }: AuthLayoutProps) => {
  const { auth } = useAuth();

  return auth.isAuth ? (
    <Navigate to={PATH.root} />
  ) : (
    <S.Main className={className}>
      <Toast />
      <Outlet />
    </S.Main>
  );
};

export default AuthLayout;
