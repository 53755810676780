import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from 'styles';

export const TableContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const RootStyle = styled.table`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    border: 1px solid ${theme.colors.gray30};
    border-bottom: 0;
  `}
`;

export const Thead = styled.thead`
  ${({ theme }) => css`
    background-color: ${theme.colors.gray10};

    & > tr {
      border-bottom: 1px solid ${theme.colors.gray30};
      & > th {
        height: 40px;

        & > svg {
          margin-left: 4px;
          font-size: 8px;
          & > path {
            fill: ${theme.colors.gray40};
          }
        }
      }
    }
  `}
`;

export const Tr = styled.tr`
  position: relative;
  display: grid;
`;

export const mixCell = css`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 12px;
  color: ${colors.gray60};
  text-align: left;

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface ThProps {
  isClickable?: boolean;
  orderFilterClickCnt?: number;
}

export const Th = styled.th<ThProps>`
  ${({ theme, isClickable, orderFilterClickCnt }) => css`
    ${mixCell}
    ${theme.fonts.medium14};
    position: relative;
    pointer-events: ${isClickable ? '' : 'none'};
    cursor: ${isClickable ? 'pointer' : ''};

    & > svg {
      transform: ${orderFilterClickCnt === 2 ? 'rotate(180deg)' : ''};
    }
  `}
`;

export const Tbody = styled.tbody`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};

    & > tr {
      border-bottom: 1px solid ${theme.colors.gray30};

      :hover td {
        background-color: ${theme.colors.gray10};
      }

      & > td {
        min-height: 40px;
      }
    }
  `}
`;

export const Td = styled.td`
  ${({ theme }) => css`
    ${mixCell}
    ${theme.fonts.regular14};
    color: ${theme.colors.black};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `}
`;

export const DownloadBtn = styled.button`
  ${({ theme }) => css`
    ${theme.button.size.smd};
    ${theme.fonts.medium15};
    ${theme.button.variant.outline};
    position: absolute;
    bottom: 16px;
    left: 20px;
    border-radius: 0;

    && {
      color: ${theme.colors.gray60};
      border: 1px solid ${theme.colors.gray30};
    }
  `}
`;
