import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { TagVariant } from './Tag.component';

interface TagBtnProps {
  variant: TagVariant;
}

export const TagBtn = styled.button<TagBtnProps>`
  ${({ theme, variant }) => css`
    ${theme.fonts.medium14};
    display: flex;
    align-items: center;
    column-gap: 8px;
    height: 32px;
    border-radius: 1100px;
    padding: 0 12px;
    color: ${theme.colors.gray70};
    background-color: ${variant === 'base' ? theme.colors.gray40 : theme.colors.white};
    transition: 0.3s;

    & > svg {
      width: 12px;
      height: 12px;
      fill: ${theme.colors.gray50};
      transition: 0.3s;
    }

    @media (hover: hover) {
      &:not(:disabled):hover {
        color: ${variant === 'base' ? theme.colors.gray80 : theme.colors.black};
        background-color: ${variant === 'base' ? theme.colors.gray40 : theme.colors.white};

        & > svg {
          fill: ${variant === 'base' ? theme.colors.gray60 : theme.colors.gray80};
        }
      }
    }
  `}
`;
