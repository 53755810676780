import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;

    & > input[type='checkbox'] {
      position: absolute;
      width: 1px;
      height: 1px;
      border: 0;
      padding: 0;
      margin: -1px;
      clip: rect(0, 0, 0, 0);
      overflow: hidden;
    }

    & > label {
      ${theme.fonts.regular15};
      display: flex;
      align-items: center;
      min-height: 100%;
      color: ${theme.colors.gray80};
      cursor: pointer;
      user-select: none;

      &::before {
        content: '';
        width: 16px;
        height: 16px;
        margin-right: 12px;
        border: 1px solid ${theme.colors.gray40};
        transition: background-color 0.3s, border 0.3s;
      }

      @media (hover: hover) {
        &:hover::before {
          border-color: ${theme.colors.blue10};
        }
      }
    }
    & > input[type='checkbox']:checked + label {
      &::before {
        content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 7.32091L2.58174 6.23088L6.64065 10.3209L13.4087 3.50098L14.5 4.59101L6.64065 12.501L1.5 7.32091Z' fill='%23fff' /%3E%3C/svg%3E%0A");
        vertical-algin: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid ${theme.colors.blue20};
        font-size: 2.3rem;
        background-color: ${theme.colors.blue20};
      }
    }
  `}
`;
