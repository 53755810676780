import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ErrorContainer = styled.section`
  ${({ theme }) => css`
    ${theme.size.container};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: inherit;
    margin: 0 auto;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    ${theme.fonts.bold18};
    margin-bottom: 8px;
  `}
`;

export const TextWrapper = styled.div`
  margin-bottom: 42px;
`;

export const Text = styled.p`
  ${({ theme }) => css`
    ${theme.fonts.regular14};
    color: ${theme.colors.gray70};
    text-align: center;

    &:first-of-type {
      margin-bottom: 20px;
    }

    @media ${theme.breakPoint.device.desktop} {
      &:first-of-type {
        margin-bottom: 0;
      }
    }
  `}
`;

export const Br = styled.br`
  ${({ theme }) => css`
    @media ${theme.breakPoint.device.desktop} {
      display: none;
    }
  `}
`;

export const Anchor = styled.a`
  ${({ theme }) => css`
    ${theme.button.variant.secondary};
    ${theme.button.size.md};
  `}
`;
