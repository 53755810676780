import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { getOneDepthPath } from 'utils/helper/common';
import * as S from './NavItem.styled';

interface NavItem {
  item: {
    navigatePath: string;
    includePath: string[];
    content: string;
  };
}

const NavItem = ({ item }: NavItem) => {
  const { pathname } = useLocation();
  const { navigatePath, includePath, content } = item;

  const currentPage = includePath.includes(getOneDepthPath(pathname));

  return (
    <S.NavItem>
      <Link to={navigatePath} aria-current={currentPage ? 'page' : 'false'}>
        <span>{content}</span>
      </Link>
    </S.NavItem>
  );
};

export default NavItem;
