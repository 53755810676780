import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

interface StyledLabelProps {
  hidden: boolean;
}

export const Label = styled.label<StyledLabelProps>`
  ${({ theme, hidden = false }) => css`
    ${theme.fonts.medium15};
    position: relative;
    display: flex;
    align-items: center;
    min-width: 160px;
    margin-bottom: 4px;
    color: ${theme.colors.gray70};
    ${hidden && theme.a11y.visuallyHidden};
  `}
`;

export const Optional = styled.span`
  ${({ theme }) => css`
    ${theme.fonts.medium15};
    color: ${theme.colors.gray50};
    display: inline-block;
    margin-left: 2px;
  `}
`;

export const ValidText = styled.p`
  ${({ theme }) => css`
    ${theme.fonts.regular14};
    position: absolute;
    left: 0;
    bottom: -24px;
    margin-left: 160px;
    color: ${theme.colors.red20};
  `}
`;

export const Input = styled.input`
  ${({ theme }) => css`
    ${theme.input.primary};
    height: 44px;
    border-radius: 0;
  `}
`;

export const UnitInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const unitInput = css`
  padding-left: 40px;
`;

export const Unit = styled.span`
  ${({ theme }) => css`
    ${theme.fonts.medium15};
    position: absolute;
    right: 14px;
  `}
`;

export const PasswordContainer = styled.div`
  & > input {
    margin-bottom: 4px;
  }
`;

export const ValidCheckList = styled.ul`
  display: flex;
  column-gap: 12px;
`;

export const RadioList = styled.ul`
  display: flex;
  column-gap: 28px;
`;

export const RadioBtnlist = styled.ul`
  & > li:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

interface CheckListProps {
  direction: 'column' | 'row';
}

export const CheckList = styled.ul<CheckListProps>`
  ${({ direction }) => css`
    display: flex;
    flex-direction: ${direction};
    ${direction === 'row' ? 'column-gap: 28px' : 'row-gap: 12px'};
    padding-top: 4px;
  `}
`;

interface AllCheckProps {
  subText?: string;
}

export const AllCheck = styled.li<AllCheckProps>`
  ${({ theme, subText }) => css`
    display: flex;
    align-items: center;

    &::after {
      content: ${subText && `'${subText}'`};
      margin-left: 4px;
      color: ${theme.colors.gray50};
    }
    && label {
      font-weight: 600;
    }
  `}
`;

export const TagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 8px;
  margin-top: 8px;
`;
