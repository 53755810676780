import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Main = styled.main`
  ${({ theme }) => css`
    position: relative;
    min-width: ${theme.size.container.width};
    width: 100%;
    max-width: ${theme.size.container.width};
    min-height: calc(100vh - ${theme.size.footer.height_l});
    margin: 0 auto;
    padding: 100px 0 120px 0;
    background-color: ${theme.colors.white};

    @media ${theme.breakPoint.device.tablet} {
      min-height: calc(100vh - ${theme.size.footer.height_m});
    }

    @media ${theme.breakPoint.device.desktop} {
      min-height: calc(100vh - ${theme.size.footer.height_s});
    }
  `}
`;
