export const breakPoint = {
  value: {
    xs: 360,
    md: 767,
    lg: 1229,
  },
  device: {
    tablet: `(min-width:  767px)`,
    desktop: `(min-width: 1229px)`,
  },
} as const;

export type BreakPointTheme = typeof breakPoint;
