import { useState, useCallback, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { VALID_MSG } from 'constants/common';
import { transform24Hr } from 'utils/helper/time';
import { numericOnlyWithColons } from 'utils/formatter';

const useTimePicker = (
  selectedValue: Dayjs | undefined,
  callbackFn: () => (newDate: string) => void,
  hasTime?: boolean,
) => {
  const [time, setTime] = useState<string>('00:00');
  const [timeErr, setTimeErr] = useState<string>('');

  const getTimeAppliedDate = useCallback((date: Dayjs, time: string) => {
    const [hour, min] = time.split(':');
    const newDate = dayjs(date)
      .set('hour', +hour)
      .set('minute', +min);

    return newDate;
  }, []);

  const applyTime = useCallback(
    (date: Dayjs | undefined, time: string) => {
      const formattedTime = transform24Hr(time);

      if (!formattedTime?.valid) {
        setTimeErr(VALID_MSG.TIME);
        return;
      }

      if (date) {
        const newDate = getTimeAppliedDate(date, formattedTime.value).format('YYYY-MM-DD HH:mm');
        callbackFn()(newDate);
      }

      setTime(formattedTime.value);
      setTimeErr('');
    },
    [callbackFn, getTimeAppliedDate],
  );

  const handleChangeTime = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length > 5) return;
    setTime(numericOnlyWithColons(value));
  }, []);

  const handleBlurTime = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const value = e.target.value;

      applyTime(selectedValue, value);
    },
    [applyTime, selectedValue],
  );

  useEffect(() => {
    if (hasTime && selectedValue) {
      if (dayjs(selectedValue).format('YYYY-MM-DD HH:mm') === 'Invalid Date') {
        setTime('00:00');
      } else {
        setTime(dayjs(selectedValue).format('YYYY-MM-DD HH:mm')?.split(' ').slice(-1).join(' '));
      }
    }
  }, [hasTime, selectedValue]);

  return {
    time,
    timeErr,
    getTimeAppliedDate,
    applyTime,
    handleChangeTime,
    handleBlurTime,
  };
};

export default useTimePicker;
