import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: none;
    align-items: center;

    @media ${theme.breakPoint.device.desktop} {
      display: flex;
    }
  `}
`;

export const Dialog = styled.dialog`
  ${({ theme }) => css`
    position: absolute;
    border-radius: 10px;
    padding: 16px;
    background-color: ${theme.colors.white};
    box-shadow: ${theme.boxShadows.dialog};
    overflow: hidden;
    z-index: ${theme.zIndex.dialog};
  `}
`;
