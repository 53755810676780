import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const RadioBtnWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    input[type='radio'] {
      position: absolute;
      width: 1px;
      height: 1px;
      border: 0;
      padding: 0;
      margin: -1px;
      clip: rect(0, 0, 0, 0);
      overflow: hidden;
    }

    input[type='radio'] + label {
      ${theme.fonts.regular15};
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 52px;
      border: 1px solid ${theme.colors.gray40};
      border-radius: 10px;
      color: ${theme.colors.gray70};
      background-color: ${theme.colors.white};
      cursor: pointer;
      transition: 0.3s;

      @media (hover: hover) {
        &:not(:disabled):hover {
          border-color: ${theme.colors.blue10};
        }
      }
    }

    input[type='radio']:checked + label {
      border-color: ${theme.colors.blue20};
      color: ${theme.colors.blue20};
    }
  `}
`;
