/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';

import { Icon } from 'components/icon';
import { WEEK_ARR } from 'constants/common';

import { useDatePicker, useTimePicker } from './hooks/index';
import { MonthYearDialogOpener, Date, TimePicker } from './container/index';
import DefaultButton from '../button/defaultBtn/DefaultBtn.component';
import Switch from '../switch/Switch.component';

const MAX_GRID_CELL = 42;

interface DatePickerProps {
  isOpen?: boolean;
  hasTime?: boolean;
  disabled?: boolean;
  className?: string;
  disabledAfter?: any;
  disabledBefore?: any;
  timeDisabled?: boolean;
  hasTimeSwitch?: boolean;
  selectedValue: dayjs.Dayjs;
  handleClickActiveTime?: any;
  selectDateType?: 'START' | 'END';
  handleClose: () => void;
  callbackFn: () => (newDate: string, type?: 'START' | 'END') => void;
}

const DatePicker = forwardRef<HTMLDialogElement, DatePickerProps>(
  (
    {
      isOpen,
      hasTime,
      className,
      hasTimeSwitch,
      selectedValue,
      disabledAfter,
      selectDateType,
      disabledBefore,
      handleClickActiveTime,
      callbackFn,
      handleClose,
    }: DatePickerProps,
    ref,
  ) => {
    const [searchParams] = useSearchParams();

    const {
      monthYear,
      changeMonthYear,
      handleSelectDate,
      handleChangeYear,
      handleChangeMonth,
      changePrevMonthYear,
      changeNextMonthYear,
    } = useDatePicker(searchParams.get('date') ? dayjs(searchParams.get('date')) : dayjs());

    const { time, timeErr, getTimeAppliedDate, handleChangeTime, handleBlurTime } = useTimePicker(
      selectedValue,
      callbackFn,
      hasTime,
    );

    const handleClickDate = (date?: dayjs.Dayjs) => () => {
      const format = hasTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
      const newDate = timeErr
        ? dayjs(date).format(format)
        : getTimeAppliedDate(dayjs(date), time).format(format);
      typeof callbackFn === 'function' && callbackFn()(newDate, selectDateType);

      changeMonthYear(date);
      handleSelectDate(date);
    };

    const handleConfirmBtnClick = () => {
      handleClose();
    };

    return (
      <>
        {isOpen && (
          <DialogRoot className={className} aria-modal="true" open={isOpen} ref={ref}>
            <Header>
              <MonthYearDialogOpener
                monthYear={monthYear}
                handleChangeMonth={handleChangeMonth}
                handleChangeYear={handleChangeYear}
              />
              <Navigation>
                <button
                  type="button"
                  aria-label="change previous month"
                  onClick={changePrevMonthYear}
                >
                  <LeftArrowIcon name="dropdownIcon" />
                </button>
                <button type="button" aria-label="change next month" onClick={changeNextMonthYear}>
                  <RightArrowIcon name="dropdownIcon" />
                </button>
              </Navigation>
            </Header>
            <div>
              <Weeks>
                {WEEK_ARR.map((day) => (
                  <li key={day}>{day}</li>
                ))}
              </Weeks>
              <Dates>
                {[...Array(monthYear.firstDOW)].map((_, i) => (
                  <Date
                    key={i}
                    date={monthYear.firstWeekPrevMonthDate.add(i, 'd')}
                    disabled
                    handleSelectDate={handleClickDate}
                  />
                ))}
                {[...Array(monthYear.lastDate)].map((_, i) => {
                  const date = monthYear.startDate.add(i, 'd');

                  return (
                    <Date
                      key={i}
                      date={date}
                      selectedDate={selectedValue}
                      disabled={
                        (disabledBefore &&
                          date.isBefore(
                            dayjs(disabledBefore, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                          )) ||
                        (disabledAfter &&
                          date.isAfter(dayjs(disabledAfter, 'DD/MM/YYYY').format('YYYY-MM-DD')))
                      }
                      handleSelectDate={handleClickDate}
                    />
                  );
                })}
                {[...Array(MAX_GRID_CELL - (monthYear.firstDOW + monthYear.lastDate))].map(
                  (_, i) => (
                    <Date
                      key={i}
                      date={monthYear.nextMonthStartDate.add(i, 'd')}
                      disabled
                      handleSelectDate={handleClickDate}
                    />
                  ),
                )}
              </Dates>
            </div>
            {hasTime && (
              <CustomTimePicker
                time={time}
                err={timeErr}
                disabled={!hasTime}
                selectedDate={selectedValue}
                handleBlurTime={handleBlurTime}
                handleChangeTime={handleChangeTime}
              />
            )}
            {hasTimeSwitch && (
              <CustomSwitch handleClickActiveTime={handleClickActiveTime} isActive={hasTime}>
                마감시간 설정
              </CustomSwitch>
            )}

            <ConfirmButton
              label="선택완료"
              size="md"
              variant="primary"
              type="button"
              onClick={handleConfirmBtnClick}
            />
          </DialogRoot>
        )}
      </>
    );
  },
);

DatePicker.displayName = 'DatePicker';
export default DatePicker;

const DialogRoot = styled.dialog`
  ${({ theme }) => css`
    position: absolute;
    width: 328px;
    border: 1px solid ${theme.colors.gray30};
    padding: 24px 20px 22px;
    background-color: ${theme.colors.white};
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    z-index: ${theme.zIndex.dialog};
  `}
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Navigation = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    margin-bottom: 3px;

    & > button {
      width: 16px;
      height: 16px;

      & > svg {
        fill: ${theme.colors.gray60};
      }
    }
  `}
`;

const LeftArrowIcon = styled(Icon)`
  margin-right: 8px;
  transform: rotate(90deg);
`;

const RightArrowIcon = styled(Icon)`
  transform: rotate(-90deg);
`;

const Weeks = styled.ul`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    column-gap: 6px;
    margin-bottom: 10px;

    & > li {
      ${theme.fonts.semibold14};
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${theme.colors.gray50};
    }
  `}
`;

const Dates = styled.ul`
  display: grid;
  grid-template-columns: repeat(7, 32px);
  justify-content: space-between;
`;

const CustomTimePicker = styled(TimePicker)`
  margin-top: 14px;
`;

const CustomSwitch = styled(Switch)`
  margin-top: 14px;
`;

const ConfirmButton = styled(DefaultButton)`
  width: 100%;
  margin-top: 14px;
  border-radius: 0;
`;
