import { a11y } from './a11y';
import { boxShadows } from './boxShadows';
import { breakPoint } from './breakPoint';
import { button } from './button';
import { colors } from './colors';
import { fonts } from './fonts';
import { input } from './input';
import { tab } from './tab';
import { zIndex } from './zIndex';
import { dropdown } from './dropdown';
import { size } from './size';

export const theme = {
  a11y,
  boxShadows,
  breakPoint,
  button,
  colors,
  fonts,
  input,
  tab,
  zIndex,
  dropdown,
  size,
};
