interface RegionItem {
  regionId: number;
  name: string;
}

interface CityItem {
  cityId: number;
  regionId: number;
  name: string;
}

interface RegionCity {
  regionList: RegionItem[];
  cityList: CityItem[];
}

export default class Region {
  private region: RegionItem[];
  private city: CityItem[];

  constructor(regionCity: RegionCity) {
    this.region = regionCity.regionList;
    this.city = regionCity.cityList;
  }

  get regionList() {
    return this.region;
  }

  get cityList() {
    return this.city;
  }

  getRegionName(regionId: number) {
    const region = this.region.find((region) => region.regionId === regionId);

    return region ? region.name : '';
  }

  getCityName(regionId: number, cityId: number) {
    const city = this.city.find((city) => city.regionId === regionId && city.cityId === cityId);

    return city ? city.name : '';
  }

  getTotalName(regionId: number, cityId: number) {
    if (regionId === 1) {
      return this.getRegionName(regionId);
    }

    return `${this.getRegionName(regionId)} ${this.getCityName(regionId, cityId)}`;
  }
}
