import React from 'react';

import { NAV_LIST } from 'assets/static';
import NavItem from './navItem/NavItem.component';
import * as S from './Gnb.styled';

const Gnb = () => {
  return (
    <S.Gnb>
      <S.MenuList>
        {NAV_LIST.map((item) => (
          <NavItem key={item.navigatePath} item={item} />
        ))}
      </S.MenuList>
    </S.Gnb>
  );
};

export default Gnb;
