import styled from '@emotion/styled';

import { BaseBtn, LabelInput } from 'components';

export const Form = styled.form`
  width: 100%;
`;

export const InputWrapper = styled.div`
  margin-bottom: 24px;

  & > div:first-of-type {
    margin-bottom: 12px;
  }
`;

export const CustomLabelInput = styled(LabelInput)`
  & > input {
    border-radius: 0;
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const LoginBtn = styled(BaseBtn)`
  height: 52px;
  border-radius: 0;
  font-size: 16px;
`;
