/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, useCallback, useLayoutEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

type ReturnTypes = {
  checkList: string[];
  handleAllCheck: (arr: string[]) => (e: React.SyntheticEvent<Element, Event>) => void;
  handleCheck: (e: React.SyntheticEvent<Element, Event>) => void;
  handleDeleteCheck: () => void;
  handleResetCheck: () => void;
};

type CheckListType = string[];

const useCheckList = (): ReturnTypes => {
  const [searchParams] = useSearchParams();
  const [checkList, setCheckList] = useState<CheckListType>([]);

  const handleCheck = useCallback(
    (e: React.SyntheticEvent<Element, Event>) => {
      const { checked, value } = e.target as HTMLInputElement;
      const result = checked ? [...checkList, value] : checkList.filter((item) => item !== value);
      setCheckList(result);
    },
    [checkList],
  );

  const handleAllCheck = useCallback(
    (arr: CheckListType) => (e: React.SyntheticEvent<Element, Event>) => {
      const target = e.target as HTMLInputElement;
      const checked = target.checked;
      setCheckList(checked ? arr : []);
    },
    [],
  );

  const handleDeleteCheck = useCallback(() => {}, []);

  const handleResetCheck = () => {
    setCheckList([]);
  };

  useLayoutEffect(() => {
    handleResetCheck();
  }, [searchParams]);

  return {
    checkList,
    handleCheck,
    handleAllCheck,
    handleDeleteCheck,
    handleResetCheck,
  };
};

export default useCheckList;
