export const areaTypeOptions = {
  전체: '전체',
  행사네트워크: '행사·네트워크',
  창업교육: '창업교육',
  멘토링컨설팅: '멘토링·컨설팅',
  사업화: '사업화',
  판로해외진출: '판로·해외진출',
  정책자금: '정책자금',
  RND: 'R&D',
  시설공간보육: '시설·공간보육',
  융자: '융자',
} as const;

export const detailTypeOptions = {
  1: '동일 공고 생성',
  2: '공고 삭제',
} as const;

export const periodIdOptions = {
  '1': '1년 미만',
  '2': '2년 미만',
  '3': '3년 미만',
  '5': '5년 미만',
  '7': '7년 미만',
  '10': '10년 미만',
  '11': '10년 이상',
};

export const PeriodRadioList = [
  { value: '1', label: '마감일 설정' },
  { value: '2', label: '상시 지원' },
  { value: '3', label: '선착순 마감' },
  { value: '4', label: '예산 소진 시 마감' },
];

export const AmountDropdownList = {
  '1': '총',
  '2': '최대',
};

export const sgBreadcrumbs = [
  { label: '지원사업 관리', path: '' },
  { label: '진행중인 공고', path: 'processing' },
  { label: '마감된 공고', path: 'closed' },
  { label: '지원사업 상세', path: 'detail' },
  { label: '지원사업 생성', path: 'create' },
  { label: '지원사업 수정', path: 'update' },
];
