import React, { HTMLInputTypeAttribute } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';

import { Radio, Icon } from 'components';
import { numericOnly, parseBillionUnitStr } from 'utils/formatter';
import * as S from './FormInput.styled';

export interface FormInputProps {
  id: string;
  kind?: string;
  label: string;
  error?: string;
  value?: string;
  isFull?: boolean;
  required?: boolean;
  className?: string;
  maxLength?: number;
  placeholder?: string;
  labelHidden?: boolean;
  errorHidden?: boolean;
  maxLengthHidden?: boolean;
  type?: HTMLInputTypeAttribute;
  register: UseFormRegisterReturn<string>;
  addCheckBoxRender?: () => JSX.Element;
  addDropDownRender?: () => JSX.Element;
}

const FormInput = ({
  register,
  id,
  isFull,
  className,
  value,
  label,
  labelHidden = false,
  error,
  errorHidden = false,
  maxLength,
  maxLengthHidden = false,
  required,
  placeholder,
  type = 'text',
  kind = 'text',
  addCheckBoxRender,
  addDropDownRender,
}: FormInputProps) => {
  const isSgowTooltip = +numericOnly(value) >= 1e4;

  return (
    <S.Wrapper className={className}>
      <S.InputWrapper isCheckBoxRender={!!addCheckBoxRender}>
        <S.Label htmlFor={id} hidden={labelHidden}>
          {label}
        </S.Label>
        <S.CheckInputWrapper isFull={isFull}>
          {addCheckBoxRender && addCheckBoxRender()}
          {kind === 'text' && (
            <S.Input
              {...register}
              id={id}
              type={type}
              maxLength={maxLength}
              placeholder={placeholder}
              aria-invalid={error ? 'true' : 'false'}
              aria-required={required ? 'true' : 'false'}
            />
          )}
          {kind === 'price' && (
            <>
              <S.PriceInputWrapper>
                {addDropDownRender && addDropDownRender()}
                <S.Input
                  {...register}
                  id={id}
                  type={type}
                  maxLength={maxLength}
                  placeholder={placeholder}
                  aria-invalid={error ? 'true' : 'false'}
                  aria-required={required ? 'true' : 'false'}
                />
                <S.PriceInputPrefix>
                  <p>만원</p>
                </S.PriceInputPrefix>
              </S.PriceInputWrapper>
              {isSgowTooltip && (
                <S.Tooltip>
                  <Icon name="notify" />
                  <span>{parseBillionUnitStr(value as string)}원</span>
                </S.Tooltip>
              )}
            </>
          )}
          {!errorHidden && error && <S.ValidText id={`${id}-errMsg`}>{error}</S.ValidText>}
          {!maxLengthHidden && maxLength && (
            <S.MaxLengthArea>
              ({value ? value.length : 0}/{maxLength}자)
            </S.MaxLengthArea>
          )}
        </S.CheckInputWrapper>
      </S.InputWrapper>
    </S.Wrapper>
  );
};

export default FormInput;

interface RadioListProps {
  name: string;
  radios: Array<{ label: string; value: string }>;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

FormInput.RadioBox = function RadioBox({ name, radios, value, onChange }: RadioListProps) {
  return (
    <S.RadioList>
      {radios.map((radio) => (
        <li key={radio.value}>
          <Radio
            id={radio.value}
            name={name}
            label={radio.label}
            value={radio.value}
            checked={radio.value === value}
            onChange={onChange}
          />
        </li>
      ))}
    </S.RadioList>
  );
};
