import { css } from '@emotion/react';
import styled from '@emotion/styled';

import DefaultButton from 'components/common/button/defaultBtn/DefaultBtn.component';

export const RootStyle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;
`;

export const BreadCrumb = styled.div`
  display: inline-flex;
  column-gap: 24px;
`;

export const BreadCrumbItem = styled.span`
  ${({ theme }) => css`
    ${theme.fonts.regular13};
    position: relative;
    color: ${theme.colors.gray50};
    cursor: pointer;

    &:not(:last-of-type)::after {
      content: '>';
      position: absolute;
      top: 50%;
      right: -20px;
      width: 12px;
      transform: translateY(-50%);
    }

    &:last-of-type {
      color: ${theme.colors.black};
    }
  `}
`;

export const LocalHeader = styled.h2`
  ${({ theme }) => css`
    ${theme.fonts.bold24};
    color: ${theme.colors.black};
  `}
`;

export const AddBtn = styled(DefaultButton)`
  align-self: flex-end;
  padding: 0 16px;
  border-radius: 0;
  min-width: 104px;
`;

export const BtnGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 12px;
  width: 100%;
`;

export const UpdateBtn = styled(DefaultButton)`
  border-radius: 0;
`;

export const PopUpBtn = styled.button`
  ${({ theme }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: none;
    padding: 0 10px;
    border: 1px solid ${theme.colors.gray30};
    background-color: ${theme.colors.white};

    &[aria-expanded='true'] {
      background-color: #e6ecf1;
    }
  `}
`;

interface DropBoxProps {
  isOpen: boolean;
}

export const DropBox = styled.div<DropBoxProps>`
  ${({ theme, isOpen }) => css`
    position: absolute;
    top: 160px;
    display: ${isOpen ? 'grid' : 'none'};
    grid-template-rows: repeat(272px, 1fr);
    width: 130px;
    height: 100px;
    box-shadow: ${theme.boxShadows.dropdown};
    background-color: ${theme.colors.white};
    z-index: ${theme.zIndex.dialog};
    overflow: hidden;
  `}
`;

export const OptionList = styled.ul`
  width: 100%;
  height: 100%;
  padding: 10px 0;
  overflow: overlay;
`;
