/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, useMemo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

import { Icon } from 'components/icon';
import { DatePicker } from 'components/common/calendar';
import DialogBtn from 'components/common/button/dialogBtn/DialogBtn';

interface DateInputProps {
  error?: string;
  value?: string;
  hasTime?: boolean;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  disabledAfter?: Dayjs;
  disabledBefore?: Dayjs;
  timeDisabled?: boolean;
  hasTimeSwitch?: boolean;
  handleClickActiveTime?: any;
  selectDateType?: 'START' | 'END';
  onBlur?: () => void;
  onChange: () => (selectDate: string, type?: 'START' | 'END') => void;
}

const DateInput = (
  {
    value,
    error,
    hasTime,
    disabled,
    className,
    placeholder,
    timeDisabled,
    hasTimeSwitch,
    disabledAfter,
    selectDateType,
    disabledBefore,
    handleClickActiveTime,
    onBlur,
    onChange,
    ...restProps
  }: DateInputProps,
  ref: React.ForwardedRef<{}>,
) => {
  const uuid = 'dateInput';
  const format = useMemo(() => (hasTime ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD'), [hasTime]);

  const formatValue = useMemo(() => {
    if (value) {
      return hasTime ? dayjs(value).format('YYYY/MM/DD HH:mm') : dayjs(value).format('YYYY/MM/DD');
    } else {
      return '';
    }
  }, [hasTime, value]);

  return (
    <Root className={className}>
      <InputStyle
        ref={ref}
        onBlur={onBlur}
        disabled={disabled}
        value={formatValue}
        placeholder={placeholder}
        aria-invalid={error ? 'true' : 'false'}
        {...restProps}
      />
      <CustomDialogBtn
        disabled={disabled}
        popup={(isOpen, dialogRef, handleClose) => (
          <CustomDatePicker
            ref={dialogRef}
            isOpen={isOpen}
            hasTime={hasTime}
            callbackFn={onChange}
            handleClose={handleClose}
            timeDisabled={timeDisabled}
            hasTimeSwitch={hasTimeSwitch}
            selectDateType={selectDateType}
            disabledAfter={disabledAfter}
            disabledBefore={disabledBefore}
            selectedValue={dayjs(value, format)}
            handleClickActiveTime={handleClickActiveTime}
          />
        )}
      >
        <Icon name="calendarIcon" />
      </CustomDialogBtn>
      {error && <ValidText id={`${uuid}-errMsg`}>{error}</ValidText>}
    </Root>
  );
};

export default forwardRef(DateInput);

interface InputProps {
  id?: string;
  name?: string;
  value?: string;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  onBlur?: () => void;
}

const CustomInput = forwardRef(function CustomInput(
  { id, name, placeholder, value, onBlur, disabled, className, ...rest }: InputProps,
  ref: any,
) {
  return (
    <InputRoot
      ref={ref}
      readOnly
      id={id ?? undefined}
      name={name}
      value={value != null ? value : ''}
      onBlur={onBlur}
      disabled={disabled}
      className={className}
      placeholder={placeholder}
      autoComplete={name === 'password' ? 'new-password' : 'off'}
      {...rest}
    />
  );
});

const Root = styled.div`
  position: relative;
  width: 100%;
`;

const CustomDialogBtn = styled(DialogBtn)`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: 15px;

  & > svg {
    opacity: 0;
  }
`;

const InputStyle = styled(CustomInput)`
  padding-right: 44px;
`;

const CustomDatePicker = styled(DatePicker)`
  top: calc(100% + 4px);
`;

interface InputRootProps {
  disabled?: boolean;
}

export const InputRoot = styled.input<InputRootProps>`
  ${({ theme, disabled }) => css`
    ${theme.fonts.regular15};
    width: 100%;
    height: 44px;
    border: 1px solid ${theme.colors.gray30};
    padding: 0 12px;
    color: ${disabled ? theme.colors.gray80 : theme.colors.black};
    background-color: ${disabled ? theme.colors.gray10 : theme.colors.white};
    opacity: ${disabled ? 0.4 : 1};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};

    &[aria-invalid='true'] {
      border-color: ${theme.colors.red10};
    }

    &:focus {
      border-color: ${theme.colors.blue40};
    }

    &::placeholder {
      color: ${theme.colors.gray50};
    }
  `}
`;

export const ValidText = styled.p`
  ${({ theme }) => css`
    ${theme.fonts.regular14};
    position: absolute;
    left: 0;
    bottom: -24px;
    color: ${theme.colors.red20};
  `}
`;
