import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Footer = styled.footer`
  ${({ theme }) => css`
    display: flex;
    flex-flow: column nowrap;
    width: 100vw;
    height: ${theme.size.footer.height_l};
    padding: 28px;
    background-color: ${theme.colors.gray10};

    @media ${theme.breakPoint.device.tablet} {
      height: ${theme.size.footer.height_m};
      padding: 28px 100px;
    }

    @media ${theme.breakPoint.device.desktop} {
      height: ${theme.size.footer.height_s};
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    @media ${theme.breakPoint.device.tablet} {
      max-width: 507px;
    }

    @media ${theme.breakPoint.device.desktop} {
      max-width: ${theme.size.container.width};
      margin: 0 auto;
    }
  `}
`;

export const Logo = styled.div`
  ${({ theme }) => css`
    width: 144px;
    height: 32px;
    margin-bottom: 16px;

    & > svg {
      fill: ${theme.colors.black};
    }

    @media ${theme.breakPoint.device.desktop} {
      margin-bottom: 32px;
    }
  `}
`;

export const Info = styled.div`
  ${({ theme }) => css`
    ${theme.fonts.regular14};
    margin-bottom: 26px;
    color: ${theme.colors.gray60};
  `}
`;

export const Address = styled.address`
  ${({ theme }) => css`
    margin-bottom: 12px;
    font-style: normal;

    @media ${theme.breakPoint.device.desktop} {
      margin-bottom: 8px;
    }
  `}
`;

export const DescList = styled.dl`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 2px;

    & > div {
      display: flex;

      &:first-of-type > dt {
        ${theme.a11y.visuallyHidden};
      }

      & > dt::after {
        content: ' : ';
        margin-right: 4px;
      }
    }

    @media ${theme.breakPoint.device.tablet} {
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 25px;

      & > div {
        position: relative;

        &:not(:last-of-type)::after {
          content: '';
          position: absolute;
          top: 50%;
          right: -12px;
          width: 1px;
          height: 12px;
          background-color: ${theme.colors.gray30};
          transform: translateY(-50%);
        }

        &:nth-of-type(2)::after {
          content: none;
        }
      }
    }

    @media ${theme.breakPoint.device.desktop} {
      flex-wrap: nowrap;
      row-gap: 0;

      & > div:nth-of-type(2)::after {
        content: '';
      }
    }
  `}
`;

export const Outbounds = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    & > li {
      ${theme.fonts.medium14};
      color: ${theme.colors.gray60};

      & > a {
        display: block;
        width: max-content;
        padding: 6px 0;
      }
    }

    @media ${theme.breakPoint.device.tablet} {
      flex-direction: row;
      column-gap: 32px;
    }
  `}
`;
