import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { KeyOf } from 'types/common';

const useFilterParams = <T>(...params: KeyOf<T>[]) => {
  const [searchParams] = useSearchParams();
  const queries = Object.fromEntries(searchParams);

  const filters = useMemo<T>(
    () =>
      params.reduce(
        (acc, p) => ({
          ...acc,
          ...(queries[p as string] && {
            [p]: queries[p as string],
          }),
        }),
        {} as T,
      ),
    [params, queries],
  );

  return { filters };
};

export default useFilterParams;
