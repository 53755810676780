import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { PATH } from 'constants/path';
import { Icon } from 'components/icon';
import { Dropdown } from 'components/common';
import { useKeyTrap, useOnClickOutside } from 'hooks';

import * as S from './Breadcrumb.styled';
import { sgBreadcrumbs } from 'assets/static';

interface BreadcrumbProps {
  title: string;
  addBtnRender?: () => JSX.Element;
  updateBtnRender?: () => JSX.Element;
}

interface BreadCrumbsAddButtonProps {
  label: string;
  className?: string;
  handleClick?: () => void;
}

interface BreadCrumbUpdateButtonProps {
  id?: string;
  name?: string;
  error?: boolean;
  readOnly?: boolean;
  required?: boolean;
  selectedOption: string;
  options: { [key: string]: string };
  openerRef?: React.ForwardedRef<HTMLButtonElement>;
  handleClickUpdate: () => void;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Breadcrumb = ({ title, addBtnRender, updateBtnRender }: BreadcrumbProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [nowPath, setNowPath] = useState<string>('진행중인 공고');

  const utilFunc = useCallback((path: string) => {
    return sgBreadcrumbs.filter((item) => item.path === path)[0].label;
  }, []);

  const handleRouteRootPage = useCallback(() => {
    if (location.pathname !== '/') {
      navigate(PATH.root);
    } else {
      return;
    }
  }, [navigate, location.pathname]);

  useLayoutEffect(() => {
    const { pathname } = location;

    if (pathname.split('/')[1] === '' || pathname.split('/')[1] === 'support') {
      if (searchParams.get('id')) {
        if (pathname.includes('detail')) {
          setNowPath(utilFunc('detail'));
        } else if (pathname.includes('update')) {
          setNowPath(utilFunc('update'));
        } else {
          setNowPath(utilFunc('create'));
        }
      } else if (pathname.includes('create')) {
        setNowPath(utilFunc('create'));
      } else if (searchParams.get('type')) {
        if (searchParams.get('type') === 'closed') {
          setNowPath(utilFunc('closed'));
        } else {
          setNowPath(utilFunc('processing'));
        }
      }
    } else {
      setNowPath('회원 관리');
    }
  }, [location, searchParams, title, utilFunc]);

  return (
    <S.RootStyle>
      <S.MainSection>
        {!location.pathname.includes('user') && (
          <S.BreadCrumb>
            <S.BreadCrumbItem onClick={handleRouteRootPage}>{title}</S.BreadCrumbItem>
            <S.BreadCrumbItem>{nowPath}</S.BreadCrumbItem>
          </S.BreadCrumb>
        )}
        <S.LocalHeader>{nowPath}</S.LocalHeader>
      </S.MainSection>
      {addBtnRender && addBtnRender()}
      {updateBtnRender && updateBtnRender()}
    </S.RootStyle>
  );
};

export default Breadcrumb;

Breadcrumb.AddBtn = function AddBtn({ label, handleClick, className }: BreadCrumbsAddButtonProps) {
  return (
    <S.AddBtn
      size="smd"
      label={label}
      variant="primary"
      onClick={handleClick}
      className={className}
    />
  );
};

Breadcrumb.UpdateBtn = function UpdateBtn({
  handleClickUpdate,
  name,
  readOnly,
  required,
  error,
  id,
  options,
  onClick,
}: BreadCrumbUpdateButtonProps) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  useKeyTrap(dropdownRef, handleClose);
  useOnClickOutside(containerRef, handleClose);

  function handleToggleDropdown() {
    isOpen ? handleClose() : handleOpen();
  }

  function handleOpen() {
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClick(e);
    setIsOpen(false);
  };

  const uuid = `popup-${id}`;
  const tipId = 'tip-option';

  return (
    <S.BtnGroup ref={containerRef}>
      <S.UpdateBtn label="수정" size="smd" variant="primary" onClick={handleClickUpdate} />
      <S.PopUpBtn
        type="button"
        name={name}
        disabled={readOnly}
        aria-invalid={error}
        aria-controls={uuid}
        aria-expanded={isOpen}
        aria-describedby={tipId}
        aria-required={required ? 'true' : 'false'}
        onClick={handleToggleDropdown}
      >
        <Icon name="moreButtonIcon" />
      </S.PopUpBtn>
      <S.DropBox ref={dropdownRef} id={uuid} isOpen={isOpen}>
        <S.OptionList>
          {Object.entries(options).map(([value, label]) => (
            <Dropdown.Option key={label} label={label} value={value} onClick={handleClick} />
          ))}
        </S.OptionList>
      </S.DropBox>
    </S.BtnGroup>
  );
};
