import { css } from '@emotion/react';
import styled from '@emotion/styled';
import DetailSection from '../DetailSection.component';

export const RootStyle = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, auto);
    width: 100%;
    border: 1px solid ${theme.colors.gray30};
  `}
`;

export const CustomDescList = styled(DetailSection.DescList)`
  ${({ theme }) => css`
    &:first-of-type {
      border-bottom: 1px solid ${theme.colors.gray30} !important;
    }

    &:nth-of-type(2) {
      border-bottom: 1px solid ${theme.colors.gray30} !important;
    }
  `}
`;
